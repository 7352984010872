import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import { StatusDot } from "./ServicesSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'

const CheckoutSection = ({
  children,
  completed=false,
  containerClassName="my-4",
  data={},
  handleSave=null,
  hidePending=false,
  saveButtonDisabled=false,
  savedRecently=false,
  submitCheckoutAttempted=false,
  showHide=false,
  title,
}) => {
  const [show, setShow] = useState(true);
  const errored = submitCheckoutAttempted && !completed;
  return (
    <div
      className={`p-4 ${containerClassName}`}
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "16px",
      }}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <h4 className="h4-20-semi-bold poppins semibold my-auto">{title}</h4>
          {errored ? (
            <FontAwesomeIcon
              color="#CB2531"
              className="ms-2 my-auto pending"
              icon={faCircleExclamation}
            />
          ) : completed ? (
            <FontAwesomeIcon
              color="#048154"
              className="ms-2 my-auto"
              icon={faCircleCheck}
            />
          ) : (
            !hidePending && (
              <StatusDot
                className="ms-2 my-auto"
                status="pending"
              />
            )
          )}
          {savedRecently && (
            <div className="d-flex animate__animated animate__fadeIn">
              <FontAwesomeIcon
                color="#048154"
                className="ms-2 my-auto"
                icon={faCheck}
              />
              <span
                className="ms-2 my-auto"
                style={{color: "#048154"}}
              >
                Saved
              </span>
            </div>
          )}
        </div>
        {showHide && (
          <Button
            className="my-auto"
            onClick={()=> setShow(!show)}
            variant="link"
          >
            {show ? "Hide" : "Show"}
          </Button>
        )}
      </div>
      <Collapse in={show}>
        <div>
          <div className="my-3">
            {children}
          </div>
          {handleSave && (
            <div className="d-flex justify-content-end mt-3">
              <Button
                disabled={saveButtonDisabled}
                onClick={() => handleSave(data)}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default CheckoutSection;