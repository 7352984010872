import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { register } from 'react-for-rails';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://7d6ccee4e2cd446eb6b78787c00e7a42@o401947.ingest.sentry.io/5399969',
});
const csrfTokenTag = document.getElementsByName('csrf-token')[0];
if (csrfTokenTag) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] =
    csrfTokenTag.attributes.content.value;
}

import Registration from '../bundles/Registration/components/Registration';
import Insurance from '../bundles/Insurance/components/Insurance';
import InsuranceV2 from '../bundles/InsuranceV2/Insurance';
import CheckoutPage from '../bundles/CheckoutPage/components/CheckoutPage';
import CheckoutPageV2 from '../bundles/CheckoutPage/components/CheckoutPageV2';
import MembersPortal from '../bundles/MembersPortal/components/MembersPortal';
import CaseReports from '../bundles/CaseReports/components/CaseReports';
import ResultsRouter from '../bundles/Results/components/ResultsRouter';
import Instruction from '../bundles/Instruction/components/Instruction';
import TestGroupIndex from '../bundles/TestGroups/Index';
import DailyOperations from '../bundles/TestGroups/DailyOperations';
import FormSelect from '../bundles/common/components/FormSelect';
import MultiSelect from '../bundles/common/components/MultiSelect';
import AsyncMultiSelect from '../bundles/common/components/AsyncMultiSelect';
import AsyncSelect from '../bundles/common/components/AsyncSelect';
import {
  NewAppointmentSlots,
  GroupedAppointmentSlots,
  FilterBar as AppointmentSlotsFilterBar,
} from '../bundles/AppointmentSlots';
import {
  NewSharedCapacities,
  GroupedSharedCapacities,
  FilterBar as SharedCapacitiesFilterBar,
} from '../bundles/SharedCapacities';
import Participants from '../bundles/Participants/components/Participants';
import NewParticipant from '../bundles/Participants/components/NewParticipant';
import StudentProfiles from '../bundles/School/components/StudentProfiles';
import StudentImmunizationStatusFilters from '../bundles/School/components/StudentImmunizationStatusFilters';
import Badges from '../bundles/Participants/components/participants/Badges';
import { NewAppointment, Reschedule } from '../bundles/Appointments';
import OtcFlow from '../bundles/Otc/components/OtcFlow';
import {
  Metrics as CapacityPlanningMetrics,
  FilterBar as CapacityPlanningFilterBar,
  GroupStats as CapacityPlanningGroupStats,
} from '../bundles/CapacityPlannings';
import AccessCodeSearch from '../bundles/common/components/AccessCodeSearch';
import TagsTable from '../bundles/Tags/components/TagsTable';
import MergeTagsModal from '../bundles/Tags/components/MergeTagsModal';
import TestGroupUsers from '../bundles/TestGroupUsers/components/TestGroupUsers';
import InactivityBanner from '../bundles/common/components/InactivityBanner';
import ActionCableSubscriber from '../bundles/common/components/ActionCableSubscriber';
import DoseManagement, {
  DoseManagementRemote,
} from '../bundles/DoseManagement';
import HelpText from '../bundles/common/components/HelpText';
import SurveyPreview from '../bundles/common/components/SurveyPreview';
import GlobalSearchFilters from '../bundles/common/components/GlobalSearchFilters';
import StickyMessage from '../bundles/Participants/components/participants/StickyMessage';
import {
  Scheduler as CustomMessageScheduler,
  RandomAmountField as CustomMessagesRandomAmountField,
  TextAreaWithVariables as CustomMessagesTextArea,
  MessagePreview as CustomMessagesPreview,
  AppointmentStartTimeField as CustomMessagesTimeField,
} from '../bundles/CustomMessages';
import DateRangeField from '../bundles/common/components/DateRangeField';
import LocationSelector from '../bundles/LocationSelector';
import PickHouseholdMember from '../bundles/TestGroupMemberRegistration/components/PickHouseholdMember';
import CSVImporter from '../bundles/CSVImporter/App';
import VaccineCardUpload from '../bundles/VaccineCardUpload/App';
import TestGroupHeader from '../bundles/Registration/components/Header';
import AppointmentRecovery from '../bundles/AppointmentRecovery/AppointmentRecovery';
import PublicTestGroups from '../bundles/PublicTestGroups/App';
import PublicTestGroupDemoLandingPage from '../bundles/PublicTestGroups/Demo/DemoLandingPage';
import PublicTestGroupInstructions from '../bundles/PublicTestGroups/Demo/Instructions/Instructions';
import PublicTestGroupLanguageSelection from '../bundles/PublicTestGroups/Demo/LanguageSelection';
import FloatingLabelInput from '../bundles/common/components/FloatingLabelInput';
import { MergeConnect } from '../bundles/DataImports';
import CancelAppointment from '../bundles/Instruction/components/instructions/CancelAppointment';
import ConsentFormsUsers from '../bundles/Consents/ConsentFormsUsers';
import BulkResultModal from '../bundles/CaseReports/components/BulkResultModal';
import SearchFiltersModal from '../bundles/common/components/SearchFilters/SearchFiltersModal';
import SearchFilters from '../bundles/common/components/SearchFilters/SearchFilters';
import YesNoCheckbox from '../bundles/common/components/YesNoCheckbox';

import SearchTestGroupIndex from '../bundles/Search/SearchTestGroupIndex';
import {
  QuickAppointmentCreateScan,
  PrintQrCodeModal,
} from '../bundles/TestGroupUsers';
import FinishRegistration from '../bundles/FinishRegistration';
import { PrimarySearchBar } from '../bundles/PrimaryDesignSystem/SearchBar';
import { PrimaryCodeBlock } from '../bundles/PrimaryDesignSystem/CodeBlock';
import KitFlow from '../bundles/KitFlow/KitFlow';
import TestStripsEdit from '../bundles/TestStripsEdit/TestStripsEdit';
import RegistrationFlyer from '../bundles/RegistrationFlyer/RegistrationFlyer';
import ContactVerificationPage from '../bundles/ContactVerification/Show';
import SearchableTable from '../bundles/common/components/SearchableTable';
import PrimaryVersionsTable from '../bundles/PrimaryVersions/Table';
import ShowImmunizations from '../bundles/Immunizations/components/ShowImmunizations';
import StudentDocumentReview from '../bundles/Immunizations/StudentDocumentReview';
import ContactSupport from '../bundles/common/components/ContactSupport';
import KitLandingPage from '../bundles/KitFlow/KitLandingPage';
import ImmunizationStatus, {
  ContactVerification as ImmunizationContactVerification,
} from '../bundles/ImmunizationStatus';
import { SearchBar } from '../bundles/TestGroups/components/Toolbar';
import { LocalizedNestedDropdown } from '../bundles/common/components/NestedDropdown';
import PrintImmunizationFormsButton from '../bundles/Immunizations/components/widgets/PrintImmunizationFormsButton';
import StudentsImporter from '../bundles/StudentsImporter/StudentsImporter';
import StudentsTable from '../bundles/Students';
import FamilyRegistration from '../bundles/FamilyRegistration';
import CaseManagerIndex from '../bundles/CaseManager';
import CodeVerify from '../bundles/AdminLogin/CodeVerify';
import Messages from '../bundles/Messages/components/Messages';
import NewMessage from '../bundles/Messages/components/NewMessage';
import MessageTemplateForm from '../bundles/Messages/components/MessageTemplateForm';
import MessageTemplates from '../bundles/Messages/components/MessageTemplates';
import TestGroupTestConfigurationsContainer from '../bundles/TestGroupTestConfigurations/components/TestGroupTestConfigurationsContainer';
import TestConfigurationsContainer from '../bundles/TestConfigurations/components/TestConfigurationsContainer';
import TestGroupOverview from '../bundles/TestGroups/TestGroupOverview';
import TestGroupTemplatesIndex from '../bundles/TestGroups/TestGroupTemplatesIndex';
import VaccineLots from '../bundles/TestGroups/VaccineLots';
import EnumLabel from '../bundles/common/components/EnumLabel';
import StatusBadge from '../bundles/common/components/StatusBadge';

register({
  Registration,
  Insurance,
  InsuranceV2,
  CheckoutPage,
  CheckoutPageV2,
  MembersPortal,
  CaseReports,
  ResultsRouter,
  Instruction,
  TestGroupIndex,
  DailyOperations,
  FormSelect,
  MultiSelect,
  AsyncMultiSelect,
  AsyncSelect,
  NewAppointmentSlots,
  GroupedAppointmentSlots,
  AppointmentSlotsFilterBar,
  NewSharedCapacities,
  GroupedSharedCapacities,
  SharedCapacitiesFilterBar,
  Participants,
  CapacityPlanningMetrics,
  CapacityPlanningFilterBar,
  CapacityPlanningGroupStats,
  AccessCodeSearch,
  DoseManagement,
  DoseManagementRemote,
  NewParticipant,
  Badges,
  NewAppointment,
  Reschedule,
  TagsTable,
  MergeTagsModal,
  TestGroupUsers,
  InactivityBanner,
  ActionCableSubscriber,
  HelpText,
  SurveyPreview,
  OtcFlow,
  GlobalSearchFilters,
  StickyMessage,
  CustomMessageScheduler,
  CustomMessagesRandomAmountField,
  CustomMessagesTextArea,
  CustomMessagesPreview,
  CustomMessagesTimeField,
  DateRangeField,
  FamilyRegistration,
  LocationSelector,
  PickHouseholdMember,
  CSVImporter,
  VaccineCardUpload,
  TestGroupHeader,
  AppointmentRecovery,
  PublicTestGroups,
  FloatingLabelInput,
  MergeConnect,
  CancelAppointment,
  ConsentFormsUsers,
  BulkResultModal,
  SearchFiltersModal,
  SearchFilters,
  YesNoCheckbox,
  QuickAppointmentCreateScan,
  PrintQrCodeModal,
  PublicTestGroupDemoLandingPage,
  PublicTestGroupInstructions,
  PublicTestGroupLanguageSelection,
  SearchTestGroupIndex,
  FinishRegistration,
  PrimarySearchBar,
  PrimaryCodeBlock,
  KitFlow,
  TestStripsEdit,
  RegistrationFlyer,
  ContactVerificationPage,
  StudentProfiles,
  StudentImmunizationStatusFilters,
  SearchableTable,
  PrimaryVersionsTable,
  ShowImmunizations,
  ContactSupport,
  StudentDocumentReview,
  ImmunizationStatus,
  KitLandingPage,
  ImmunizationContactVerification,
  SearchBar,
  LocalizedNestedDropdown,
  PrintImmunizationFormsButton,
  StudentsImporter,
  StudentsTable,
  CaseManagerIndex,
  CodeVerify,
  Messages,
  NewMessage,
  MessageTemplates,
  MessageTemplateForm,
  TestGroupTestConfigurationsContainer,
  TestConfigurationsContainer,
  TestGroupOverview,
  TestGroupTemplatesIndex,
  VaccineLots,
  EnumLabel,
  StatusBadge,
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});
