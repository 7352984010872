export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Месяц",
    "day": "День",
    "year": "Год",
    "january": "Январь",
    "february": "Февраль",
    "march": "Март",
    "april": "Апрель",
    "may": "Май",
    "june": "Июнь",
    "july": "Июль",
    "august": "Август",
    "september": "Сентябрь",
    "october": "Октябрь",
    "november": "Ноябрь",
    "december": "Декабрь"
  },
  "user": {
    "send_appointment_confirmation_message": "Ваше назначение подтверждено для {{name}}.",
    "landline_appointment_reminder_message": "Здравствуйте, {{full_name}}. Это письмо-напоминание о вашем приёме у {{name}}. Ваша приём назначен на {{date}} в {{time}} по адресу {{address}}.",
    "send_at_home_visit_confirmation_message": "Ваш прием у врача подтвержден на {{date}}   после {{time}}.",
    "send_mail_order_confirmation_message": "Ваш почтовый заказ подтвержден",
    "send_waitlist_message": "Вы были добавлены в список ожидания к {{name}}. Пожалуйста, не приходите на приём пока мы не подтвердим вашу запись.",
    "verify_contact": {
      "phone": "Пожалуйста, подтвердите ваш номер телефона, нажав на эту ссылку: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Вы можете продолжить процесс записи на приём здесь: {{url}}",
    "on_demand_confirmation_message": "Вы успешно зарегистрировались для %{имя}.",
    "appointment_reminder": "Перед Вашим приёмом в {{start_time}}, пожалуйста, нажмите на ссылку, чтобы завершить все незаконченные действия и чтобы увидеть подтверждение записи. Пожалуйста, приезжайте не ранее, чем за 15 минут до приёма.",
    "appointment_reminder_base": "{{text}} НАЖМИТЕ СЮДА: {{link}}",
    "test_result_notifier": {
      "text_message": "Портал пациента для {{first_name_with_last_initial}} обновлен. Перейдите на {{url}} и используйте код: {{access_code}}",
      "email_subject": "Новые обновления в вашем портале пациента",
      "email_intro": "Здравствуйте {{first_name_with_last_initial}}",
      "email_p1": "Для вас доступно новое сообщение или обновление пациента.",
      "email_p2": "Нажмите кнопку ниже или используйте ссылку, чтобы просмотреть портал для пациентов и использовать код",
      "email_p3": "«Просмотреть портал пациента по адресу:»",
      "email_button": "Просмотреть портал"
    },
    "landline_appointment_reminder_message_without_time": "Здравствуйте, {{full_name}}. Это письмо-напоминание о вашем приёме у {{name}}. Ваш приём состоится {{date}} по адресу {{address}}.",
    "appointment_reminder_on_demand": "Перед Вашим приёмом {{date}}, пожалуйста, нажмите на ссылку, чтобы завершить все незаконченные действия и чтобы увидеть подтверждение записи.",
    "new_test_result_notifier": {
      "email_intro": "Ваш портал пациента обновлен.",
      "email_text_1": "Посетите ваш портал истории болезни ниже, чтобы просмотреть новые записи пациентов или лабораторные отчеты.",
      "email_button": "История болезни",
      "email_text_2": "Вы также можете скопировать и вставить этот URL-адрес в свой браузер:"
    },
    "mailers": {
      "email_contact_us": "У вас есть вопрос? Свяжитесь с нами по адресу"
    }
  },
  "errors": {
    "generic": {
      "message": "Что-то пошло не так, пожалуйста, попробуйте снова"
    },
    "messages": {
      "blank": "не может быть пустым"
    },
    "incorrect_credentials": "Ваши учетные данные были неверны, пожалуйста, повторите попытку.",
    "error_activating_test_kit": "Произошла ошибка при активации тестового набора."
  },
  "type": "Тип",
  "payment": {
    "continue_label": "Перейти к оплате",
    "policy_text": "Если вы отмените вашу запись или не придёте на приём, вы вернём вам 80% от оплаченной суммы, в течение 7 дней после дня вашего приёма. Вы можете ознакомиться с полными [условиями обслуживания] здесь ({{link}})",
    "card_number": "Номер карты",
    "expiry_date": "Срок",
    "pay_button": "Оплатить",
    "no_credit_card": "Оплата на месте",
    "cash_payment_helper": "Пожалуйста, не забудьте принести **{{payment}}** на приём",
    "invoice": "Накладная",
    "pay_by_card": "Оплатить картой",
    "cost": "Сумма",
    "total": "Итоговая сумма",
    "pay_cash_at_appointment": "Я хочу оплатить наличными во время приёма у {{appointment}}",
    "view_invoice": "Просмотреть/Распечатать накладную",
    "refund_policy": "В случае отмены записи, мы вернем Вам сумму платежа {{amount}} через 7 дней после даты Вашей записи. Подробнее об условиях [Условия Использования]({{link}})",
    "refund_window_passed": "Деньги за Вашу запись не подлежат возврату, так как время Вашей записи прошло. Пожалуйста, обратитесь к координаторам сайта напрямую для решения проблемы.",
    "amount_paid": "Оплачено",
    "balance": "Баланс"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Загрузите форму согласия от предъявления претензий или поставьте вашу подпись в окошке внизу",
    "stop": "Необходимо Ваше согласие, чтобы завершить процесс регистрации",
    "parent_or_guardian_required_html": "Чтобы завершить процесс регистрации, один из ваших родителей, опекун или уполномоченный орган, который может принимать решения, должен предоставить соглашение в форме внизу и указать его имя. Вы <b>НЕ МОЖЕТЕ</b> сами подписать эту форму соглашения. ",
    "send_parent_or_guardian": "Вы можете отправить вашим родителю или опекуну эту ссылку или попросить их подписаться внизу на этой странице. ",
    "consent_guardian_name_signature": "Имя",
    "consent_for_minor": "Подписать форму согласия от имени несовершеннолетнего",
    "guardian_signature": "Подпись родителя, опекуна или уполномоченного лица, принимающего медицинские решения",
    "after_you_schedule": "Участники младше {{age}} должны попросить одного из их родителей или опекунов подписать форму согласия за них. Пожалуйста, перейтите к завершению регистрации, чтобы переслать это своему опекуну или подпишите форму от лица своего иждивенца.",
    "consent_guardian_last_name_signature": "Фамилия",
    "consent_first_and_last_name": "Имя и Фамилия",
    "phone_number": "Телефон опекуна",
    "email": "Email опекуна",
    "consent_registrar_with_guardian_name_signature": "Имя законного представителя",
    "consent_registrar_with_guardian_last_name_signature": "Фамилия законного представителя",
    "consent_registrar_first_name_signature": "Записать имя",
    "consent_registrar_last_name_signature": "Записать фамилию",
    "consent_on_behalf": "Я подписываю от имени лица {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "{{first_name}} {{last_name}} дали свое устное согласие, чтобы я мог(ла) оставить подпись от их имени.",
    "consent_on_behalf_registrar_with_guardian": "Законный представитель лица {{first_name}} {{last_name}} дали свое устное согласие, чтобы я мог(ла) оставить подпись от их имени.",
    "registrar_phone": "Номер телефона регистратора",
    "registrar_email": "Адрес электронной почты регистратора",
    "consented_by_parent_guardian": "Родитель / Опекун",
    "consented_by_decision_maker": "Уполномоченный орган, принимающий медицинские решения",
    "consented_by_guardian_verbal": "Регистратор с опекуном (получено устное согласие)",
    "consented_by_registrar": "Регистратор (получено устное согласие)",
    "full_name": "Полное имя",
    "print": "Print"
  },
  "registration": {
    "contact_information": "Имя и адрес",
    "contact_information_additional": "Контактные данные родителя или опекуна для связи относительно записи на приём и результатов",
    "personal_information": "Демографическая информация",
    "address_placeholder": "Пожалуйста, укажите ваш домашний адрес",
    "first_name_label": "Имя",
    "last_name_label": "Фамилия",
    "errors": {
      "state": "Штат должен состоять из двух действительных символов кода штата",
      "phone_number_invalid": "Недействительный номер телефона",
      "required": "Эти данные необходимы",
      "email_domain": "Введённые данные недействительны; пожалуйста, проверьте их ещё раз. Регистрация ограничена для участников, которых выбрала спонсирующая организация. Пожалуйста, попробуйте использовать свой рабочий электронный адрес или адрес электронной почты сообщества. Если вы считаете, что имеете право на участие и здесь произошла ошибка, пожалуйста, обратитесь в службу поддержки.",
      "age_requirement": "Дата рождения не соответствует возрастным требованиям",
      "signature_required": "Необходима подпись",
      "regex_invalid": "Это значение кажется недействительным.",
      "date_invalid": "Эта дата не существует",
      "invalid_entry": "Пожалуйста, не продолжайте процесс регистрации. Следуйте инструкциям на экране или обратитесь к своему контактному лицу.",
      "city": "Это должен быть действительный город",
      "survey_unanswered": "Пожалуйста, убедитесь, что все необходимые вопросы со звёздочкой (*) были отвечены. ",
      "postal_code": "Индекс должен быть действительным и состоять из 5 цифр",
      "field_required": "{{field}} должен иметь значение",
      "option_required": "Вы должны выбрать один из вариантов",
      "year_length_invalid": "В дате рождения укажите год полностью (гггг)",
      "invalid_day": "День должен быть между 1-31",
      "reached_max_chars": "Достигнут максимум ограничения по символам.",
      "chars_max": "Ограничение символов",
      "minimum_length": "Длина входных данных должна составлять не менее {{length}} символов."
    },
    "insurance_status": {
      "question": "У вас имеется медицинская страховка?",
      "have_health_insurance": "Да, у меня есть медицинская страховка",
      "do_not_have_health_insurance": "Нет, у меня нет медицинской страховки"
    },
    "insurance_policy_holder": {
      "question": "Кто является страхователем?",
      "i_am": "Я",
      "my_spouse": "Мой супруг или партнёр",
      "my_parents": "Мои родители",
      "someone_else": "Кто-то другой",
      "policy_first_name": "Имя Страхователя",
      "policy_last_name": "Фамилия Страхователя",
      "policy_dob": "Дата Рождения Страхователя",
      "name": "Policy holder's name"
    },
    "insurance_information": {
      "title": "Информация о Медицинском Страховании",
      "company_name": "Название Компании Медицинского Страхования",
      "id_number": "Членский Идентификационный Номер",
      "group_number": "Членский Групповой Номер ",
      "secondary_insurance_label": "У меня имеется вторичное страхование",
      "take_photo": "Please take a photo of your insurance card. Taking a photo will auto fill some of the information.",
      "front_of_card": "Front of card",
      "card_information": "Insurance card information",
      "back_of_card": "Back of card"
    },
    "employment_information": {
      "label": "Трудоустройство",
      "address_1": "Адрес Организации",
      "address_2": "Например: Номер 200",
      "phone_number": "Номер Телефона Организации",
      "company": "Название Организации",
      "postal_code": "Почтовый Индекс Работодателя",
      "employed": "Трудоустроен",
      "sole_prorietor": "ИП (Индивидуальный Предприниматель)",
      "not_employed": "Нетрудоустроен",
      "reporting_supervisor": "Руководитель отчетности",
      "reporting_department": "Отдел отчетности",
      "supervisor_name": "Имя руководителя",
      "supervisor_email": "Электронная почта руководителя"
    },
    "location_availability": "В настоящее время тестирование является приоритетным для членов сообщества, которые проживают в данных районах",
    "custom_survey": "Вопросник",
    "confirmation": "Обзор",
    "waitlisted": "Список Ожидания",
    "schedule_your_appointment": "Записаться на Приём",
    "information": "Информация",
    "consent": "Согласие",
    "location": "Расположение",
    "symptoms": "Симптомы",
    "address_required": "Пожалуйста, введите ваш адрес",
    "consent_required": "Пожалуйста, подтвердите ваше согласие, чтобы продолжить",
    "required_field": "указывает обязательные поля",
    "phone_number": "Номер Телефона",
    "email": "Электронный Адрес",
    "date_of_birth": "Дата Рождения",
    "minimum_age": "Минимальный возвраст участников - {{year}} лет.",
    "no_minimum_age": "Для тестирования возрастных ограничений нет",
    "continue_button": "Продолжить",
    "email_required": "Это значение должно иметь действующий электронный адрес.",
    "done": "Готово",
    "signature": "Подпись",
    "clear_button": "Очистить",
    "back_button": "Назад",
    "choose_location": "Выбрать Расположение",
    "no_slots": "На запись в {{location}} больше нет свободных мест.",
    "choose_appointment": "Выберите время приёма в {{location}}",
    "appointment_required": "Пожалуйста, выберите время приёма",
    "phone_number_required": "Номер телефона недействителен",
    "phone_number_label": "Пожалуйста, введите номер телефона, на который можно получать текстовые сообщения, для более быстрого получения результатов теста",
    "symptoms_experiencing": "Выберите симптомы, которые у вас имееются на данный момент. Если у вас нет симптомов, пожалуйста, перейдите дальше.",
    "household": "Члены Семьи",
    "household_p1": "При желании, запишите на приём ваших членов семьи, которым также необходимо пройти тестирование.",
    "add_dependent": "Добавить Члена Семьи",
    "remove_dependent": "Удалить Идживенца",
    "dependent_consents": "Согласие",
    "submit": "Завершить",
    "add_waitlist": "Добавить в Список Ожидания",
    "address": "Домашний Адрес",
    "address_1": "Адресная Строка №1",
    "address_2": "Квартира №",
    "address_city": "Город",
    "address_state": "Штат",
    "postal_code": "Почтовый Индекс",
    "race_ethnicity": "Нация",
    "gender": "Половая Принадлежность",
    "self_described_gender": "Самоопределение Половой Принадлежности",
    "interpreter": "Вам необходим переводчик? Если да, на какой язык?",
    "consent_to_terms": "Я согласен с данными условиями.",
    "reg_not_open": "Регистрация ещё не открыта",
    "no_more_avail_spots": "Время, которое вы выбрали для записи, больше недоступно. Пожалуйста, попытайтесь ещё раз.",
    "consent_helper": "Задержите и проведите мышкой или пальцем через окно внизу, чтобы оставить подпись",
    "phone_number_unreachable_label": "Стационарный телефон?",
    "select": "Выберите ",
    "test_surveys": "Вопросы относительно приёма",
    "edit": "Редактировать",
    "continue_to_registration": "Продолжить Регистрацию",
    "accounts": {
      "already_have_an_account": "У вас уже имеется личный кабинет?",
      "log_in": "Логин",
      "sign_up": "Зарегистрироваться",
      "sign_up_description": "Пожалуйста, введите вашу информацию, чтобы создать личный кабинет и сэкономить время на будущих регистрациях.",
      "log_in_description": "Пожалуйста, введите ваш электронный адрес и пароль, или зарегистрируйтесь через Google или Outlook ниже",
      "sign_in_with_label": "Зарегистрироваться через {{provider}}",
      "password_must_match": "Пароли должны совпадать",
      "password_uppercase": "Ваш пароль должен содержать не менее (%s) заглавных букв.",
      "password_lowercase": "Ваш пароль должен содержать как минимум (%s) строчных букв.",
      "password_number": "Ваш пароль должен содержать не менее (%s) цифр.",
      "password_special": "Ваш пароль должен содержать не менее (%s) символов."
    },
    "password": "Пароль",
    "password_confirmation": "Подтверждение Пароля",
    "consent_for": "Согласия для {{name}}",
    "book_one_time_appointment": "Записаться на Единичный Приём",
    "duplicate_users": {
      "exists": "Вы уже зарегистрированы",
      "overlapping_email_and_phone_p1": "Мы отправили сообщение на ваш мобильный номер и электронный адрес",
      "p2": "Используя ваше сообщение, вы можете изменить вашу запись или добавить результаты тестирования",
      "overlapping_phone_and_email_p3": "Пожалуйста, проверьте вашу электронную почту и мобильные сообщения, чтобы узнать детали.",
      "overlapping_email_p3": "Пожалуйста, проверьте вашу электронную почту, чтобы узнать детали.",
      "overlapping_phone_p3": "Пожалуйста, проверьте ваши мобильные сообщения, чтобы узнать детали.",
      "overlapping_phone_p1": "Мы отправили сообщение на ваш мобильный номер.",
      "overlapping_email_p1": "Мы отправили сообщение на вашу электронную почту.",
      "p4": "Если вы думаете, что это ошибка, пожалуйста, свяжитесь с нами по адресу support@primary.health",
      "overlapping_email_and_phone_p3": "Пожалуйста, проверьте вашу электронную почту и мобильные сообщения, чтобы узнать детали."
    },
    "duplicate_waitlist": {
      "exists": "Вы уже находитесь в списке ожидания.",
      "overlapping_email_and_phone_p1": "Мы отправили ещё одно подтверждение на ваш мобильный номер и электронный адрес.",
      "p2": "Используя ваше подтверждение записи, вы можете предоставить дополнительную информацию или удалить себя из списка ожидания.",
      "overlapping_phone_and_email_p3": "Пожалуйста, проверьте Ваш электронный адрес или телефон, чтобы узнать детали.",
      "overlapping_email_p3": "Пожалуйста, проверьте Ваш электронный адрес, чтобы узнать детали.",
      "overlapping_phone_p3": "Пожалуйста, проверьте Ваш телефон, чтобы узнать детали.",
      "overlapping_email_p1": "Мы отправили ещё одно подтверждение на ваш электронный адрес."
    },
    "insist_guardian_consent": "Я даю согласие от имени",
    "confirmation_section": {
      "title": "Просмотреть и подтвердить",
      "p1": "Пожалуйста, проверьте свою информацию перед завершением регистрации.",
      "edit_information": "Вернуться назад и редактировать"
    },
    "iemodal": {
      "title": "Неподдерживаемый Браузер",
      "body": "В настоящий момент, регистрация возможна в данных браузерах"
    },
    "show_other_locations": "Нажмите, чтобы показать другие местоположения",
    "non_us_address": "Это международный адрес",
    "test_group_user_survey": "Отбор Участников",
    "self_consent": "Я даю согласие за себя",
    "address_country": "Страна",
    "appointment": "Приём",
    "employee_id": "ID сотрудника",
    "appointment_time": "Время записи",
    "appointment_location": "Местонахождение",
    "phone_or_email": "Номер телефона или электронная почта",
    "no_self_consent": "Я отказываюсь от согласия для себя",
    "no_insist_guardian_consent": "Я отказываюсь от согласия от имени {{full_name}}",
    "additional_consents_helpertext": "Дополнительные согласия могут быть запрошены позже",
    "minimum_age_with_months": "Минимальный возраст для участия {{year}} лет и {{month}} месяцев.",
    "assistive_technology": "Вспомогательные Технологии",
    "covid_vaccine_y_n": "Желаете ли Вы получить вакцину от COVID-19 с этим заказом?",
    "received_covid_vaccine_y_n": "Вы ранее вакционировались от COVID-19?",
    "covid_vaccinations": "Прививки от Covid-19",
    "select_vaccine_dose": "Пожалуйста, выберите дозу желаемой вакцины",
    "demographic_info": "Демографическая информация",
    "additional_info": "Дополнительная информация",
    "self_described_race": "Самоопределение расовой принадлежности",
    "verify_contact_information": "Проверка",
    "verify_contact": {
      "we_sent_you_a_code": "Пожалуйста, введите код, который мы отправили {{contact}}",
      "confirmation_code": "Код подтверждения",
      "code_resent": {
        "email": "Еще один код был отправлен на вашу электронную почту",
        "phone_number": "На ваш телефон был отправлен еще один код"
      },
      "did_not_receive_code": "Не получили код подтверждения?",
      "verify_by": {
        "email": "Вместо этого подтвердите по электронной почте",
        "phone_number": "Вместо этого подтвердите номер телефона"
      }
    },
    "preferred_method_of_communication": {
      "question": "Предпочтительный способ связи",
      "helper_text": "Мы свяжемся с вами, чтобы сообщить о назначенной встрече",
      "phone_number": "Телефон (могут применяться только SMS-сообщения и тарифы на передачу данных)",
      "landline": "В настоящее время мы не можем поддерживать стационарные телефоны. Пожалуйста, указывайте адрес электронной почты для важных сообщений.",
      "verification_method": "Как вы хотите получить свой аутентификационный код?",
      "verification_helper_text": "Вам будет отправлен код для подтверждения вашей личности перед входом в систему."
    },
    "skip_for_now": "Пропусти пока",
    "default_information": {
      "title": "Хорошо, мы воспользуемся этим адресом",
      "title_v2": "Хорошо, мы будем использовать эту контактную информацию"
    },
    "middle_name_label": "Второе имя",
    "confirm_appointment": "Подтвердите встречу",
    "dob": "дата рождения",
    "different_appointment": "Другое назначение",
    "select_appointment_time": "Выберите время встречи",
    "decline_address": "Я отказываюсь указывать адрес. Пожалуйста, используйте вместо этого адрес программы.",
    "patient_information": "Информация о пациенте",
    "scan_license": "Отсканируйте водительские права",
    "how_to_contact": "Как нам следует связаться с вами?",
    "decline_email_or_phone": "Я отказываюсь предоставлять адрес электронной почты или номер телефона. Я разрешаю администраторам программы получать мои результаты.",
    "medical_screening": "Медицинский скрининг",
    "show_service_at_this_location": "Show service at this location",
    "verbal_consent_provided": "In accordance with the Americans with Disabilities Act, participant or their legally authorized decision maker provided verbal consent. Written consent shall be obtained from participant or their legally authorized decision maker within 24 hours.",
    "unexpired_written_consent_present": "Testing site has unexpired written consent on file.",
    "select_to_proceed": "Please select an option above to proceed.",
    "guardians_information": "Guardians information",
    "registrars_information": "Registrar's information",
    "optional": "необязательно",
    "vfc_eligibility": "Vaccine for Children (VFC) eligibility",
    "vfc_eligibility_subtext": "Your child may be able to get no-cost vaccines through CDC’s Vaccines for Children (VFC) Program at healthcare providers offices, pharmacies and health clinics that are enrolled in the VFC Program."
  },
  "gender_key": {
    "male": "Мужской",
    "female": "Женский",
    "cisgender": "Цисгендер",
    "non_binary": "Небинарная Гендерная Идентичность",
    "other": "Другое",
    "prefer_to_self_describe": "Гендерная Принадлежность Не Указана",
    "prefer_not_to_disclose": "Отказываюсь отвечать на этот вопрос",
    "transgender_male": "Транс-мужчина",
    "transgender_female": "Транс-женщина",
    "unknown": "Неизвестно",
    "non_binary_only": "Небинарн",
    "intersex": "Интерсекс",
    "transgender": "Трансгендер",
    "gender_fluid": "Гендерфлюид",
    "not_applicable": "Не применимо"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Американские индеецы или коренные жители Аляски",
    "american_indian_central_america": "Американские индеецы из Южной или Центральной Америки",
    "asian": "Азиаты",
    "black": "Черные или афроамериканцы",
    "latinx": "Латиноамериканцы, латины, испанофоны",
    "middle_eastern_north_african": "Средневосточные народы или северноафриканцы",
    "native_hawaiian_pacific_islander": "Коренные Гавайцы или жители островов Тихого Океана",
    "white_european": "Белые или Кавказцы",
    "unknown": "Неизвестно",
    "prefer_not_to_disclose": "Предпочитаю не разглашать",
    "asian_indian": "Восточноиндийцы",
    "filipino": "Филиппинцы",
    "japanese": "Японцы",
    "korean": "Корейцы",
    "vietnamese": "Вьеты",
    "other_asian": "Другие азиаты",
    "native_hawaiian": "Коренные Гавайцы",
    "guamanian_or_chamorro": "Чаморро",
    "samoan": "Самоанцы",
    "other_pacific_islander": "Другие жители островов Тихого Океана",
    "chinese": "Китайцы",
    "help": "Под нацией понимается набор физических характеристик, используемых для различения групп людей. Нация может совпадать или не совпадать с национальностью или страной, гражданином(кой) которой вы являетесь.",
    "subtitle": "Согласно правилам, нам необходимо собрать всю следующую информацию.",
    "laotian": "Лао",
    "cambodian": "Кхмеры",
    "other": "Другие",
    "bangladeshi": "Бангладешец",
    "hmong": "Хмонг",
    "indonesian": "Индонезиец",
    "malaysian": "Малайзиец",
    "pakistani": "Пакистанец",
    "sri_lankan": "Шри-ланкиец",
    "thai": "Таец",
    "taiwanese": "Тайванец",
    "fijian": "Фиджиец",
    "guamanian": "Гуамец",
    "tongan": "Тонганец"
  },
  "languages": {
    "en": "Английский",
    "fr": "Французский",
    "es": "Испанский",
    "so": "Сомалийский",
    "hmn": "Хмонг",
    "asl": "Американский Жестовый Язык (амслен)",
    "kar": "Каренский",
    "am": "Амхарский",
    "ru": "Русский",
    "om": "Оромо (Галла)"
  },
  "symptoms": {
    "fever": "Температура или озноб",
    "cough": "Кашель",
    "muscle_aches": "Боль в мышцах",
    "severe_fatigue": "Сильная усталость (больше, чем обычно)",
    "trouble_breathing": "Затруднённое дыхание",
    "diarrhea": "Диарея",
    "loss_of_smell": "Потеря запаха",
    "loss_of_taste": "Потеря вкуса",
    "shortness_of_breath": "Одышка или затруднённое дыхание",
    "headache": "Головная боль",
    "sore_throat": "Боль в горле",
    "congestion": "Заложенность носа или насморк",
    "nausea": "Тошнота или рвота",
    "close_contact": "Тесный контакт с инфицированным человеком*",
    "helper_text": {
      "close_contact": "Были ли вы в тесном контакте (на расстоянии 1,8м на протяжении как минимум 15 минут) с кем-либо, у кого был подтверждён COVID-19?"
    },
    "suspected_exposure": "Возможный контакт",
    "none": "Отсутствие Симптомов"
  },
  "instructions": {
    "title": "Вы записаны, но прежде чем вы уйдёте...",
    "p1": "На этой странице находится информация о вашей записи, а также штрих-код для вашей записи.",
    "appointment_scheduled": "Ваша запись была назначена.",
    "verify_info": {
      "title": "Проверьте Ваши Данные",
      "p1": "Важно проверить свою контактную информацию, чтобы вы могли быстро получить доступ к порталу для пациентов.",
      "p2": "Подтверждение было отправлено на ваш электронный адрес. Пожалуйста, перейдите по предоставленной ссылке.",
      "p3": "Если вам необходимо изменить контактную информацию, пожалуйста, нажмите на кнопку \"Помошь\"."
    },
    "verified": "Проверено",
    "not_verified": "Не Проверено",
    "resend_text": "Не получили ссылку?",
    "resend": "Отправить снова",
    "skip": "Пропустить проверку и показать подтверждение",
    "name": "Имя",
    "appointment_details": "Запись на приём",
    "date_and_time": "Дата и Время",
    "add_to_calendar": "Добавить в Календарь",
    "instructions": "Основные Инструкции",
    "successfully_flash": "Успешно созданная запись!",
    "success_flash_email": "Успешно подтверждённый электронный адрес",
    "success_flash_phone": "Успешно подтверждённый номер телефона",
    "mail_order": "Вы получите Ваш набор для тестирования в течение недели по почте.",
    "at_home": "Врач придёт к вам домой примерно в это время",
    "at_home_instructions": "Инструкция \"Дома\"",
    "mail_order_instructions": "Инструкция \"Почтовый заказ\"",
    "request_additional_appointment": "Записаться на повторный приём",
    "book_additional_appointment": "Записаться на другой приём",
    "confirmation_code": "Код подтверждения",
    "completed": "Завершено",
    "appointment_barcode": "Штрих-код записи",
    "dependent_links": "Ссылки на \"Приём Дома\"",
    "on_demand_title": "Вы записаны, но прежде чем вы уйдёте...",
    "save_link": "Сохраните эту ссылку",
    "verify_phone": "Проверьте Ваш номер телефона",
    "verify_phone_description": "Важно проверить свою контактную информацию, чтобы вы могли быстро получить доступ к своим записям. На ваш номер было отправлено проверочное текстовое сообщение.",
    "verify_email_description": "Важно проверить свою контактную информацию, чтобы вы могли быстро получить доступ к своим записям. На ваш электронный адрес было отправлено проверочное сообщение.",
    "information": "Информация о Регистрации",
    "follow_up_appointment": "Повторная Запись",
    "get_directions": "Получить Маршрутные Указания ",
    "cancel": "Отменить Запись",
    "reschedule_appointment": "Перенести Запись",
    "reschedule_linked_appointments": "Перенести Записи",
    "no_slots": "Больше нет доступных окошек для записи",
    "check_results": "Проверить Портал Пациентов",
    "follow_up_modal_header": "Пожалуйста, выберите дату и время для второй прививки",
    "are_you_sure_you_want_to_cancel": "Вы уверены, что хотите отменить эту запись?",
    "please_choose_cancellation_reason": "Пожалуйста, выберите причину отмены записи ниже",
    "additional_details": "Пожалуйста, добавьте любую дополнительную инфомацию ниже",
    "errors": {
      "missing_cancellation_reason": "Пожалуйста, выберите причину отмены записи"
    },
    "verify_email": "Подтвердите Ваш электронный адрес",
    "redcap_url": "Нажмите, чтобы завершить опрос",
    "verify_contact_information": "Проверьте Ваши Контактные Данные",
    "please_also": "Пожалуйста, укажите",
    "new_title": "Подтверждение для %{имя}",
    "contact_method": "Способ контакта",
    "next_steps": {
      "title": "Следующие шаги",
      "p1": "У вас есть тестовый набор для активации. Когда вы будете готовы пройти тест, перейдите по ссылке, отправленной на ваш электронный адрес, чтобы вернуться на эту страницу и активировать свой набор ниже.",
      "administered": "Мы свяжемся с вами, когда ваш набор поступит в лабораторию и когда ваши результаты будут готовы. Результаты обычно поступают в лабораторию через 3-5 дней. Вы можете просмотреть результаты на своей странице [История болезни]({{link}}), когда они будут готовы.",
      "resulted": "Результаты доступны на вашей странице [История болезни]({{link}}).",
      "shipping_information": "Информация о доставке"
    },
    "save_this_page": {
      "title": "Сохраните эту страницу",
      "p1": "Используйте эту страницу, чтобы сообщить о самотестировании или просмотреть результаты в истории болезни.",
      "p2": "Чтобы получить доступ к этой странице в будущем, нажмите на ссылку в вашем электронном письме с подтверждением или SMS-сообщении.",
      "p3": "Вы можете сохранить эту страницу, добавив ее в закладки, на главный экран или скопировав ссылку в безопасное место."
    },
    "show_my_barcode": "Покажите мой штрих-код",
    "my_account": "Мой аккаунт",
    "register_another": "Зарегистрируйте другого участника",
    "update_vaccine": "Обновлять информацию о вакцинах",
    "medical_history": "История болезни",
    "upcoming_appointments": "Upcoming appointments",
    "reschedule": "Reschedule",
    "resend_confirmation": "Resend confirmation",
    "appointment_details_v2": "Appointment details",
    "confirm_cancellation": "Confirm cancellation",
    "confirm_cancellation_question": "Are you sure you want to cancel this appointment?",
    "select_new_appointment_time_below": "Select a new appointment time below. If you need to modify the services or appointment location, please cancel this appointment and schedule a new one.",
    "no_take_me_back": "No, take me back",
    "yes_cancel_appointment": "Yes, cancel appointment",
    "update_appointment": "Update appointment",
    "select_new_appointment_time": "Select new appointment time",
    "clinic": "Clinic",
    "services": "Services",
    "appointment_missed": "Appointment missed",
    "appointment_canceled": "Appointment canceled"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Неверный Код Доступа"
    },
    "landing": {
      "p1": "Чтобы просмотреть ваши результаты, пожалуйста введите:",
      "access_code_label": "Код Доступа",
      "submit_button": "Отправить",
      "recent_results": "Ваши недавние результаты"
    },
    "view_results": {
      "headline": "Результаты Теста для {{name}}",
      "reregister": "Зарегистрироваться заново",
      "status": {
        "likely_positive": "Неясные Результаты",
        "test_not_performed": "Необходимо Повторное Тестирование",
        "results_ready": "Результаты Готовы",
        "processing": "В Обработке",
        "test_status": "Статус Тестирования",
        "test_result": "Результат Тестирования",
        "administered": "Получен",
        "results_pending": "Ожидаются результаты",
        "test_results": "Результаты тестирования"
      },
      "at": "в {{address}}"
    },
    "result_label": "Результат",
    "result": {
      "flu_a": {
        "result_label": "Результат теста на грипп А"
      },
      "flu_b": {
        "result_label": "Результат теста на грипп В"
      },
      "covid19": {
        "result_label": "Результат теста на COVID-19"
      },
      "covid": {
        "result_label": "Результат теста на COVID-19"
      },
      "sars": {
        "result_label": "Результат SARS"
      },
      "control": {
        "result_label": "Контроль"
      },
      "rsv": {
        "result_label": "Результат теста на РСВ"
      },
      "result": {
        "result_label": "Результат"
      },
      "hba1c": {
        "normal": "Обычный",
        "warning": "Преддиабет",
        "danger": "Диабет"
      },
      "lead_venous": {
        "danger": "Ненормальный",
        "normal": "Обычный"
      },
      "tc": {
        "result_label": "Результат TC"
      },
      "hdl": {
        "result_label": "Результат по ЛПВП"
      },
      "trg": {
        "result_label": "Результат TRG"
      },
      "ldl": {
        "result_label": "Результат по уровню ЛПНП"
      },
      "non_hdl": {
        "result_label": "Результат, не связанный с ЛПВП"
      },
      "tc_hdl_ratio": {
        "result_label": "Соотношение ТС/ЛПВП"
      },
      "glu": {
        "result_label": "Результат GLU"
      },
      "alere_cholestech_ldx": {
        "danger": "В зоне риска",
        "warning": "Ненормальный",
        "normal": "Обычный"
      },
      "lead": {
        "result_label": "Вести"
      },
      "zinc": {
        "result_label": "Цинк"
      },
      "lead_standard_profile": {
        "danger": "Ненормальный",
        "normal": "Обычный"
      },
      "creatinine": {
        "danger": "Ненормальный",
        "normal": "Обычный"
      },
      "igg": {
        "result_label": "IgG result"
      },
      "igm": {
        "result_label": "IgM result"
      },
      "blood_glucose_fasted": {
        "normal": "Нормальный",
        "warning": "Повышенный",
        "danger": "Высокий",
        "hypoglycemia": "Hypoglycemia",
        "prediabetes": "Преддиабет",
        "diabetes": "Диабет"
      },
      "total_cholesterol_fasted": {
        "normal": "Нормальный",
        "elevated": "Повышенный",
        "high": "Высокий",
        "low": "Низкий"
      },
      "total_cholesterol_unfasted": {
        "normal": "Нормальный",
        "elevated": "Повышенный",
        "high": "Высокий"
      },
      "a1c_now": {
        "normal": "Нормальный",
        "warning": "Предиабет",
        "danger": "Диабет"
      },
      "blood_glucose": {
        "warning": "Низкий",
        "normal": "Нормальный",
        "prediabetes": "Преддиабет",
        "danger": "Диабет",
        "hypoglycemia": "Hypoglycemia",
        "medical_emergency": "Медицинская чрезвычайная ситуация",
        "inconclusive_1": "Неопределённый 1",
        "inconclusive_2": "Неопределённый 2",
        "possible_diabetes": "Возможный диабет"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      }
    },
    "documents": "Документы",
    "self_administered": "Самостоятельный тест был забран по адресу {{location}}",
    "patient": "Терпеливый",
    "medical_history": "История болезни",
    "overview_title": "Выберите тест или службу, чтобы просмотреть более подробную информацию и любые необходимые дополнительные действия. Обратите внимание, что некоторые результаты тестов могут быть еще недоступны или станут доступны только после разговора с поставщиком услуг.",
    "insurance_information": "Заполните информацию о страховании",
    "contact_support": "Если вам нужна помощь или вы хотите изменить свою контактную информацию, пожалуйста, обратитесь в службу поддержки.",
    "show": "Покажите",
    "hide": "Скрыть",
    "lab_report": "Лабораторный отчет",
    "contact_provider": {
      "header": "У вас есть положительные результаты",
      "description": "Вы хотите поговорить с лечащим врачом, чтобы обсудить ваши результаты и лечение?",
      "yes_option_text": "Да, я хочу поговорить с провайдером",
      "no_option_text": "Нет, я прочитал и понял свои результаты и не хочу разговаривать с провайдером",
      "confirm_phone_number_header": "Подтвердите свой номер телефона",
      "confirm_phone_number_description": "Пожалуйста, подтвердите наилучший номер телефона для вашей консультации.",
      "confirm_button_text": "Подтверждать",
      "consultation_confirmed_header": "Консультация подтверждена",
      "consultation_confirmed_description": "Провайдер перезвонит вам по номеру {{phone_number}} в течение 2-3 рабочих дней.",
      "acknowledgement_option_helper_text": "Что-то, что объясняет важность лечения и позволяет пользователю знать, как запланировать звонок, если он передумает.",
      "acknowledgement_confirmed_header": "Подтверждение подтверждено",
      "acknowledgement_confirmed_description": "Что-то о важности получения лечения с помощью связанных ресурсов. Напоминаем, что консультация абсолютно бесплатна, и лечащий врач может назначить лекарство или что-то еще, что поможет им справиться с инфекцией.",
      "acknowledgement_confirmed_change_mind_text": "Если вы передумаете, просто нажмите “Я хотел бы получить консультацию” ниже.",
      "request_consultation_button_text": "Я бы хотел получить консультацию"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Ваш адрес не соответствует требованиям местоположения данной программы."
    }
  },
  "member": {
    "medical_history": "Журнал истории",
    "view_instructions": "Просмотреть Подтверждение",
    "next_appointment": "Следующая Запись",
    "over_18": "Я подтверждаю, что мне 18 (или больше) лет",
    "choose_test_configurations_title": "Пожалуйста, выберите услуги, которые вы хотели бы получить для этой записи",
    "member_taken_error": "Пользователь уже имеет аккаунт",
    "choose_test_configurations_subtitle": "Услуги будут предоставлены всем членам семьи",
    "service": "Обслуживание",
    "group_or_location": "Группа/место",
    "details": "Подробности",
    "see_all": "Посмотреть все",
    "no_history": "В данный момент история недоступна",
    "medical_history_title_with_name": "{{name}}: журнал истории",
    "no_dashboard": "В данный момент нет доступных историй",
    "product": "Товар",
    "price": "Цена",
    "quantity": "Количество ",
    "total_services_selected": "Итоговый список услуг",
    "total_price": "Итоговая цена"
  },
  "or": "или",
  "account": {
    "errors": {
      "must_be_13": "Для регистрации Вам должно быть 13 лет"
    }
  },
  "self_administration": {
    "title": "Самостоятельное Управление",
    "self_administer_action": "Самостоятельный Пользователь",
    "skip_to_link": "Перейти к Самостоятельному Управлению",
    "select_person": "Выберите человека для проверки",
    "adult": "Взрослый",
    "child": "Ребёнок",
    "checked_out": "ПРОВЕРЕНО",
    "go_back": "Вернуться назад",
    "switch_to_scanner": "Переключиться на сканер",
    "enter_barcode": "Ввести штрих-код",
    "scan_barcode": "Отсканировать штрих-код",
    "cancel": "Отменить",
    "barcode_for": "Штрих-код для",
    "enter_barcode_manually": "Ввести штрих-код вручную",
    "instructions": "Отсканируйте или введите штрих-код, расположенный ниже на вашей пробирке.",
    "regex_prefix": "Штрих-код должен состоять из",
    "all_completed": "Завершено: все члены семьи выполнили этот шаг",
    "errors": {
      "no_appointment": "Проблема с поиском записи на приём",
      "barcode_in_use": "Этот штрих-код уже использовался ранее. Если вы ранее не использовали этот комплект самостоятельно, поговорите с сотрудниками того учреждения, где вам был предоставлен данный комплект, и попросите новый комплект. В противном случае, нажмите кнопку справки ниже.",
      "test_error_general": "Проблема при создании теста",
      "different_barcodes": "Введенные вами штрих-коды не совпадают."
    },
    "confirm_barcode_input": "Введите штрих-код еще раз для подтверждения",
    "click_to_self_test": "Нажмите здесь для проведения самостоятельного теста"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Лист Ожидания",
      "unavailable": "Нет доступных окошек для записи"
    },
    "labels": {
      "address": "Адрес",
      "next_appointment": "Следующая доступная запись",
      "services": "Доступные услуги"
    }
  },
  "waiting_room": {
    "header": "Вы находитесь в комнате ожидания; пожалуйста, подождите пока мы не соединим вам с нашей системой бронирования записей.",
    "p1": "Пожалуйста, потерпите. Вместе мы положим конец этой пандемии.",
    "signature": "С наилучшими пожеланиями,",
    "p2": "Мы знаем, что ситуация с COVID-19 всех очень расстраивает. Пожалуйста, оставайтесь здесь, пока мы стараемся работать как можно быстрее, чтобы направить вас на сайт, где вы сможете записаться на прием."
  },
  "users": {
    "send_appointment_confirmation_message": "Здравствуйте, {{full_name}}. Это небольшое напоминание о записи к {{name}}. Ваш приём назначен на {{date}} в {{time}} по адресу {{address}}.",
    "send_registration_link": "Здравствуйте, {{full_name}}. Перейдите по этой ссылке, чтобы зарегистрироваться к вашему {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "Первая Доза",
    "previous_appointment": "Предыдущая Запись",
    "booked_appointment": "Ваша Запись",
    "second_dose": "Вторая Доза",
    "choose_second_location": "Выберите Местоположение для Второй Записи"
  },
  "cancellation_types": {
    "cant_get_to_location": "У меня произошла накладка во времени",
    "timing_conflict": "Я заболел COVID-19",
    "became_ill_with_covid19": "Я получил данную услугу в другом месте",
    "received_service_elsewhere": "Другое",
    "other": "Введите название вместо",
    "duplicate_appointment": "Эта запись являлась дубликатом"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Введите название",
      "type_name": "Вместо этого введите подпись"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Ваш код доступа - ",
      "please_confirm_your_email": "Пожалуйста, подтвердите ваш электронный адрес, нажав на кнопку внизу",
      "hello": "Здравствуйте",
      "click_to_reschedule": "Нажмите, чтобы показать инструкции/перенести",
      "click_to_reschedule_on_demand": "Нажмите, чтобы показать инструкции"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Ваша встреча в {{time}} в {{location}} была отменена. Если это ошибка, пожалуйста, напишите нам по электронной почте support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Имя Участника",
    "date": "Дата",
    "signature": "Подпись Участника",
    "and_or": "И/ИЛИ",
    "guardian_name": "Имя Родителя/Опекуна",
    "guardian_signature": "Подпись Родителя/Опекуна"
  },
  "employer_testing": {
    "hello_name": "Здравствуйте {{name}}",
    "results": {
      "negative": "Отрицательный",
      "positive": "Положительный",
      "did_not_result": "Неизвестно",
      "unknown": "Неизвестен",
      "invalid": "Недействительный",
      "pending": "На рассмотрении"
    },
    "code_reader": {
      "scan_your_code": "Отсканируйте код на вашей тест-карте",
      "find_in_top_right_hand_corner": "Скан-код вашего теста уникален, и его можно найти в верхнем правом углу вашего теста",
      "center_code": "Поместите код здесь",
      "already_used_error": "Этот тестовый штрих-код уже использовался. За поддержкой вы можете обратиться к поставщику тестового набора.",
      "click_to_scan": "Нажмите, чтобы отсканировать штрих-код",
      "scan_new_test_card": "Нажмите здесь, чтобы отсканировать новую тест-карту",
      "not_working": "Не работает?",
      "try_again": "Нажмите здесь, чтобы попытаться ещё раз."
    },
    "continue": "Продолжить",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Пожалуйста, подтвердите дату рождения участника, чтобы продолжить",
      "contact_administrator": "Если отображаемая информация неверна, пожалуйста, свяжитесь с администратором для помощи",
      "error": "Введённая дата рождения некорректна - пожалуйста, попытайтесь снова или свяжитесь с местным администратором, чтобы её обновить."
    },
    "result_entry": {
      "code_is_registered": "Ваш код был зарегистрирован.",
      "take_the_test": "Пройти тест.",
      "follow_the_instructions": "Следуйте инструкциям, прилагаемым к тесту, и запустите 15-минутный таймер после того, как вы поместите мазок из носа во флакон на тест-карте.",
      "cant_find_instructions": "Не можете найти инструкции?",
      "view_instructions": "Просмотреть Инструкции Binax Now",
      "start_timer": "Запустить 15-минутный таймер (по вашему усмотрению)",
      "submit_within_15": "Подсказка: отправьте Ваши результаты в течении 15-ти минут",
      "enter_test_results": "Введите Результаты Теста",
      "choose_a_result": "Выберите вариант, который описывает то, что вы видите на вашей тест-карте",
      "submit": "Отправить",
      "must_log_result_and_photo": "Вы должны занести результат и сфотографировать свой тест, чтобы продолжить",
      "submit_within_15_v2": "Подсказка: отправьте Ваши результаты в течении 15-ти минут",
      "click_to_take_photo": "Нажмите, чтобы сделать фото",
      "results_may_be_invalid": "Результаты тестирования могут быть недействительными"
    },
    "summary": {
      "title": "Сводка Результатов",
      "negative_subtitle": "У Вас нет COVID-19",
      "negative_message": "Мы отправили вашему работодателю уведомление о том, что вы можете вернуться на работу.",
      "positive_subtitle": "Сожалеем, у вас COVID-19",
      "positive_message": "Мы отправили вашему работодателю уведомление о том, что у вас COVID-19.",
      "what_should_you_do": "Что Вам делать:",
      "employer_name_resources": "Информация о Работодателе",
      "follow_cdc_guidelines": "Следуйте рекомендациям CDC"
    },
    "clarifying_results": {
      "title": "Разъяснение Ваших Результатов",
      "which_did_your_result_most_look_like": "На что больше всего были похожи Ваши результаты?",
      "no_lines": "Не видно никаких линий",
      "blue_control_line_only": "Только синяя контрольная линия",
      "pink_sample_line_only": "Только розовая пробоотборная линия",
      "blue_control_line_and_pink_sample_line": "Синяя контрольная линия И розовая/фиолетовая пробоотборная линия",
      "still_not_sure": "Всё ещё не уверен(а)",
      "submit_results": "Отправить Результаты",
      "thank_you": "Спасибо!",
      "clarification_received_message": "Разъяснение Ваших результатов было получено.",
      "only_pink_line": "Только розовая линия",
      "no_lines_v2": "Нет линий"
    },
    "return_to_confirmation": "Вернуться на страницу подтверждения"
  },
  "preferred_language": {
    "title": "Предпочитаемый язык",
    "subtitle": "Выбрать язык"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Серия первой вакцины",
    "one_dose": "Записаться на приём для определённой дозы. В частности, это моя",
    "title": "Выбор Дозы",
    "first_dose": "Первая доза",
    "second_dose": "Вторая доза",
    "single_dose_title": "Единичная Доза",
    "additional": "Дополнительный",
    "booster": "Повторная вакцинация",
    "supplemental": "Добавочный",
    "third_dose": "Только третья доза",
    "no_vaccine": "Ничего из перечисленного"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "должен иметь формат {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "должен иметь формат {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "тест не найден"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "пользователь существует и не имеет права это обновлять"
            },
            "date_of_birth": {
              "invalid_date": "должен иметь формат {{datetime_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} не существует, выберите из следующих допустимых значений: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} не существуют, выберите из следующих допустимых значений: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "пол не существует, выберите одно из следующих допустимых значений: {{genders}}"
            },
            "phone_number": {
              "invalid": "неверный номер"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} не существует, выберите одно из следующих допустимых значений: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "невозможно заполнить, если пол - «предпочитает_само_описание»"
            },
            "sex": {
              "unknown_sex": "пол не существует, выберите одно из следующих допустимых значений: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sex_orientation не существует, выберите одно из следующих допустимых значений: {{sexy_orientations}}"
            },
            "status": {
              "unknown_status": "статус недействителен, выберите одно из следующих допустимых значений: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} не существует, выберите одно из следующих допустимых значений: {{races}}",
                "other": "{{unknown_races}} не существуют, выберите одно из следующих допустимых значений: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "недостаточно прав, чтобы установить роль"
            },
            "user_id": {
              "unknown_user": "пользователь не найден"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "неверный JSON"
            },
            "user_id": {
              "unknown_user": "пользователь не найден"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "местонахождение записи не найдено"
            },
            "date_of_birth": {
              "invalid_date": "должен иметь формат {{date_format}}"
            },
            "email": {
              "duplicated": "уже используется"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} не существует, выберите одно из следующих допустимых значений: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} не существуют, выберите одно из следующих допустимых значений: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "пол не существует, выберите одно из следующих допустимых значений: {{genders}}"
            },
            "phone_number": {
              "invalid": "неверный номер"
            },
            "population": {
              "unknown_population": "{{unknown_population}} не существует, выберите одно из следующих допустимых значений: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "невозможно заполнить, если пол - «предпочитает_само_описание»"
            },
            "sex": {
              "unknown_sex": "пол не существует, выберите одно из следующих допустимых значений: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sex_orientation не существует, выберите одно из следующих допустимых значений: {{sexy_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} не существует, выберите одно из следующих допустимых значений: {{races}}",
                "other": "{{unknown_races}} не существуют, выберите одно из следующих допустимых значений: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "и адрес уже используются в другом существующем месте"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Латиноамериканцы или Хиспанофонцы",
    "not_hispanic": "Не Латиноамериканцы или Хиспанофонцы",
    "hispanic_expanded": {
      "other": "Другие Испано-, Латиноамериканцы и представители испаноязычного происхождения",
      "mexican": "Мексиканцы, Мексикамериканцы и Чикано",
      "puerto_rican": "Пуэрториканец",
      "cuban": "Кубинцы"
    },
    "subtitle": "Согласно правилам, мы должны собрать следующую информацию",
    "help": "Этническая принадлежность - это набор общих черт, таких как язык, культурные обычаи, религия и других характеристик, используемые для различения групп людей. Это может совпадать, а может и не совпадать с расовой принадлежностью.",
    "unknown": "Неизвестно",
    "title": "Этническая принадлежность"
  },
  "sex_at_birth": {
    "question": "Пол",
    "female": "Женский",
    "male": "Мужской",
    "help": "Под полом понимается то, что указано в вашем свидетельстве о рождении.",
    "non_binary": "Небинарная Гендерная Идентичность",
    "subtitle": "Согласно правилам, нам необходимо собрать всю следующую информацию.",
    "decline": "Отказываюсь отвечать",
    "unknown": "Неизвестно"
  },
  "gender": {
    "help": "Гендерная идентичность относится к тому, как вы сами себя идентифицируете. Это может совпадать или не совпадать с полом, который указан при рождении.",
    "subtitle": "Если вы хотели бы добавить информацию о своей гендерной идентичности, то, пожалуйста, добавьте её."
  },
  "sexual_orientation": {
    "title": "Сексуальная Ориентация",
    "subtitle": "Если вы хотели бы добавить информацию о своей сексуальной ориентации, то, пожалуйста, добавьте её.",
    "gay": "Гей, лесбиянка или гомосексуал",
    "heterosexual": "Гетеросексуал",
    "bisexual": "Бисексуал",
    "questioning": "Сомневаюсь/ не уверен/ не знаю",
    "prefer_not_to_disclose": "Предпочитаю не раскрывать",
    "unknown": "Неизвестно",
    "orientation_not_listed": "Ориентация не указана",
    "not_applicable": "Не применимо",
    "pansexual": "Пансексуал",
    "queer": "Квир"
  },
  "pronouns": {
    "title": "Предпочитаемые Местоимения",
    "he": "Он/его",
    "she": "Она/её",
    "they": "Они/их",
    "choose_not_to_disclose": "Предпочитаю не раскрывать",
    "prefer_to_self_describe": "Предпочитаю самоописание"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Номер водительского удостоверения или Государственного идентификационного документа",
    "driver_license": "Водительское Удостоверение",
    "use_ssn_instead": "Использовать SSN",
    "social_security_number": "Номер Социального Страхования",
    "state": "Штат",
    "upload_front_of_driver_license": "Загрузите лицевую сторону водительских прав или идентификационный код",
    "choose_file": "Выберите файл",
    "no_file_chosen": "Файл не выбран",
    "no_identification": "У меня нет удостоверения личности",
    "insurance_card_has_a_back": "У моей страховой карты есть оборотная сторона",
    "upload_front_of_insurance_card": "Загрузите лицевую сторону вашей страховой карты.",
    "front_of_insurance_card_uploaded": "Загружена лицевая сторона вашей страховой карты.",
    "insurance_card": "Страховая карта",
    "insurance_card_back": "Оборотная сторона страховой карты",
    "back_of_insurance_card_uploaded": "Загружена оборотная сторона вашей страховой карты."
  },
  "quidel": {
    "certify_text": "Я подтверждаю, что я вношу результаты этого теста только один раз.",
    "entering_results": "Введение Ваших Результатов",
    "review_instructions_1": "Внимательно Изучите Инструкции",
    "review_instructions_2": "Вы можете изучить инструкции, которые были приложены к Вашему тесту, прочитать пошаговое объяснение или посмотреть разъяснительное видео ниже:",
    "read_instructions": "Прочитать Инструкции",
    "watch_video": "Посмотреть Видео",
    "timer": "Запустите таймер, помешав мазок в пробирке. Таймер остановится после 1-ой минуты (чтобы вы могли извлечь мазок из пробирки). Пожалуйста, заметьте, что вы должны будете возобновить таймер, чтобы пошёл отсчёт 10-ти минут для проявления результатов Вашего теста.",
    "start_timer": "Запустить Таймер",
    "restart_timer": "Остановить и Начать Заново",
    "resume": "Возобновить",
    "one_minute_countdown": "1 минута прошла! Достаньте Ваш мазок из пробирки и возобновите таймер",
    "take_photo": "Сделайте Фото Полоски на Вашем Тесте",
    "photo_warning": "Предоставление фото необходимо для того, чтобы продолжить.",
    "enter_results": "Введите Результаты Теста",
    "choose_result": "Выберите вариант, который больше всего похож на полоску на вашем тесте. Нужна помощь? Нажмите здесь, чтобы увидеть примеры результатов.",
    "positive": "Положительный",
    "negative": "Отрицательный",
    "unknown": "Неизвестно",
    "clarify": "Разъяснение Ваших Результатов",
    "strip_question": "На что больше всего была похожа полоска на Вашем тесте?",
    "no_lines": "Нет Линий",
    "pink_line": "Только розовая линия",
    "not_sure": "Всё ещё не уверен(а)"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Фото Результата",
    "retake_photo": "Переснять Фото",
    "capture_photo": "Сделать Фото",
    "confirm_information_correct": "Я подтверждаю, что выше указанная информация верна.",
    "submit": "Отправить",
    "title": "Ввести результат для {{name}}",
    "subtitle": "Вам не нужно скачивать или использовать приложение iHealth COVID-19.",
    "instruction_title": "Просмотреть инструкции iHealth",
    "instruction_guide": "Читать инструкции",
    "video_guide": "Смотреть видео",
    "add_photo": "Добавить фото",
    "confirm_result": "Подтвердить результат",
    "result_warning": "Выберите результат, чтобы его отправить",
    "confirm_warning": "Подтвердите результат, чтобы его отправить",
    "administered_at_label": "Когда Вы сделали данный тест?",
    "instruction_subtitle": "Прочитайте инструкции, которые были приложены к тесту или просмотрите их здесь."
  },
  "public_test_groups": {
    "title": "Наборы для тестирования COVID-19",
    "appointment_recoveries_title": "Оформляете Возврат",
    "appointment_recoveries_button_text": "Найти мою ссылку",
    "search_test_group_title": "Вы здесь впервые?",
    "search_test_group_button_text": "Зарегистироваться сейчас",
    "title_2": "Простая регистрация и отчетность",
    "step_one": "Регистрация учетной записи",
    "step_two": "Соберите образец слюны для отправки обратно",
    "step_three": "Посмотреть результаты онлайн",
    "title_3": "Зарегистрируйтесь, чтобы активировать свой набор",
    "population_title": "Регистрация",
    "population_button": "Активировать",
    "faq_subtitle": "Узнать больше",
    "faq_title": "Часто задаваемые вопросы",
    "faq_1": "Как вернуть набор для тестирования?",
    "faq_1_footer": "Посмотреть инструкцию по использованию воронки Saliva Direct",
    "faq_2": "Я уже зарегистрирован(а), но я забыл(а) данные моего аккаунта.",
    "faq_3": "Как активировать мой набор?",
    "faq_2_subtitle": "Как вернувшийся пользователь, вы можете подтвердить свой отчет с помощью кнопки '**Найти мою ссылку**' расположенной выше.",
    "faq_3_subtitle": "Пожалуйста, пройдите процедуру оформления учетной записи в Primary.Health, используя приведенные ниже опции:",
    "footer_text_1": "Этот продукт не был разрешен или одобрен FDA, но был разрешен FDA для экстренных случаях в соответствии с EUA;",
    "footer_text_2": "Данный продукт был разрешен только для сбора и хранения образцов слюны в качестве вспомогательного средства для обнаружения нуклеиновой кислоты SARS-CoV-2, но не для любых других вирусов или патогенов;",
    "footer_text_3": "Экстренное использование данного продукта разрешено только на время действия заявления о существующих обстоятельствах, оправдывающих разрешение на экстренное использование медицинских изделий в соответствии с разделом 564(b)(1) Федерального закона о продуктах питания, лекарствах и кос- метике, 21 U.S.C. § 360bbb-3(b)(1), если только действие заявления не будет прекращено или разрешение не будет отозвано раньше.",
    "description": "Для вашего удобства вы можете активировать свой тестовый набор, используя кнопки ниже:",
    "subtitle": "Впервые на Primary?",
    "subtitle_1": "О ваших тест-наборах COVID-19",
    "faq_1_1": "Поместите закрытую пробирку с образцом в пакет для биопроб",
    "faq_1_2": "Поместите пакет с биообразцами в коробку с маркировкой SalivaDirect, в которой вы его получили. Поместите коробку с этикеткой SalivaDirect в пакет FedEx UN 3373 Pak.",
    "faq_1_3": "Снимите клейкий вкладыш с упаковки FedEx UN 3373 и плотно прижмите, чтобы плотно запечатать упаковку.",
    "faq_1_4": "Пожалуйста, сдайте образец в тот же день, когда вы его получили, и до последнего времени сбора. Не доставляйте вашу пробу в ящик для сбора проб в выходные дни.",
    "faq_1_5": "Посетите веб-сайт FedEx по адресу **[fedex.com/labreturns](https://www.fedex.com/labreturns)** для просмотра мест расположения ящиков и их расписания приема проб.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "Сообщить о результате",
    "report_result_subtitle": "Если ваша организация предлагает самотестирование, и вы готовы сообщить о результате, пожалуйста, нажмите кнопку ниже.",
    "need_help": "Нужна помощь?",
    "assistance": "Если вам нужна помощь или вы хотите изменить свою контактную информацию, обратитесь в Службу поддержки.",
    "contact_support": "Связаться со службой поддержки",
    "save_link": "Сохраните эту ссылку, чтобы сообщить о результатах позже.",
    "send_via_email": "Отправить ссылку по электронной почте",
    "send_via_text": "Отправить ссылку по SMS",
    "copy_link": "Скопировать ссылку",
    "resend_email": "Повторная отправка верификации электронной почты",
    "phone_verified": "Телефон проверен",
    "add_vaccination_status": "Добавить/обновить статус вакцинации COVID-19",
    "terms_of_service": "Условия обслуживания",
    "verify_contact_information": "Проверьте свою контактную информацию, чтобы вы могли получать ваши результаты и сообщения. Если вам нужно изменить контактную информацию, обратитесь в нашу Службу поддержки.",
    "resend_text": "Повторная отправка SMS с текстовой верификацией",
    "loading": "Загрузка",
    "add_to_account": "Добавить в аккаунт",
    "hi": "Привет",
    "email_verified": "Электронная почта проверена",
    "go_to_my_account": "Перейти в мой аккаунт",
    "activate_kit": "Активировать тест-набор",
    "report_custom_result": "Сообщите результат: {{test}}",
    "activate_custom_test": "Активировать тест: {{test}}",
    "update_vaccination_status": "Обновить статус вакцинации",
    "order_a_test_kit": "Заказать тест-набор"
  },
  "enter_information": {
    "contact_info": "Введите вашу контактную информацию",
    "label": "Телефон или электронная почта",
    "invalid": "Это недействительный адрес электронной почты или номер телефона.",
    "send": "Мы отправим код на ваш телефон или электронную почту.",
    "legal": "Вводя свой номер телефона или электронную почту, вы соглашаетесь с **[Условиями предоставления услуг](https://primary.health/terms-of-service/)** и **[Политикой конфиденциальности](https://primary.health/privacy-policy/)** компании Primary Health.",
    "subtitle": "We will send you a text or email to help you find your records or link to report a test result!",
    "find_link": "Вы уже зарегистрированы? Найдите Вашу ссылку",
    "not_registered": "Not Registered?",
    "search_by_keyword": "Ищите по имени, ключевым словам, городу, почтовому индексу или коду организации!",
    "search": "Поиск",
    "register_here": "Register here"
  },
  "contact_support": {
    "invalid": "Неверная информация",
    "invalid_explanation": "Вы недавно запросили ссылку для входа в Primary.Health. Однако,",
    "never_registered": "Вы никогда не регистрировались раньше? Обратитесь к провайдером тестирования, чтобы получить ссылку регистрации, или найдите свой сайт ниже.",
    "get_help": "Вы можете получить помощь в сопоставлении вашей записи с контактной информацией по адресу",
    "get_help_calling": "или позвоните по телефону",
    "invalid_explanation_bold": "предоставленная вами информация недействительна.",
    "get_help_label": "Помощь",
    "mobile": {
      "invalid_explanation": "Не найдено ни одного пользователя, которого можно было бы добавить в вашу учетную запись.",
      "get_help": "Если у вас возникнут дополнительные вопросы, обращайтесь",
      "reason": "Не все пользователи Primary.Health имеют право на нашу функцию «Новые учетные записи». Если вы соответствуете требованиям, вы получите приглашение от Primary.Health или от администратора вашей группы."
    },
    "zen_name": "Контактное лицо",
    "ticket_type": "Что вас интересует?",
    "appointment_access_code": "Код подтверждения (если известно)",
    "zen_desc": "Сообщение",
    "contact_us": "Свяжитесь с нами",
    "need_assistance": "Если вам нужна помощь или вы хотите изменить свою контактную информацию, пожалуйста, обратитесь в службу поддержки."
  },
  "show_appointments": {
    "welcome": "Find your profile",
    "welcome_subtitle_1": "Showing results below for",
    "added_to_account": "Добавлен(о) к учетной записи",
    "error": "Это пользователь может уже иметь учетную запись",
    "welcome_subtitle_2": "Not seeing the name below?",
    "welcome_not_logged_in": "Finding a site link",
    "welcome_logged_in": "Select your Group",
    "welcome_subtitle_1_logged_in": "To finish adding a member, select the user and group. Members are assigned to one account.",
    "welcome_subtitle_2_logged_in": "If you are not seeing your member listed above, you can",
    "welcome_subtitle_1_not_logged_in": "Select who needs to locate their site or add a new member. Member not found?",
    "welcome_subtitle_3_logged_in": "try a different contact method",
    "welcome_subtitle_4_logged_in": "or contact us at",
    "welcome_subtitle_2_not_logged_in": "Update search.",
    "welcome_subtitle_3": "Update your search"
  },
  "enter_code": {
    "code": "Укажите код",
    "enter_code": "Введите код, отправленный на",
    "incorrect_code": "Вы ввели неправильный код.",
    "verify": "Подтвердите учетную запись",
    "verified": "Подтверждено",
    "verify_account_information": "Verify your account"
  },
  "general_self_checkout": {
    "choose_test": "Какой тест вы сделали?",
    "photo_required": "Требуется фотография результата",
    "find_your_test": "Выберите свой тест"
  },
  "login": {
    "create_account": "Создайте учетную запись",
    "create_account_help": "Создание учетной записи — это не то же самое, что регистрация для тестирования или вакцинации. Как только вы зарегистрируетесь",
    "create_account_help_schedule": "Записываться на прием",
    "create_account_help_record": "Регистрировать тесты сделанные в домашних условиях",
    "create_account_help_match": "Управлять записями о прививках и анализах.",
    "continue": "Продолжить в качестве гостя",
    "already": "У вас уже есть учетная запись? Для входа нажмите",
    "login": "здесь.",
    "or_sign_in": "Или войдите с помощью:",
    "no_account": "У вас еще нет учетной записи? Нажмите",
    "signup": "для регистрации.",
    "here": "здесь",
    "email_address": "Адрес электронной почты:",
    "password": "Пароль:",
    "complete_registration": "Завершить регистрацию",
    "last_covid_test": "Последний результат теста на COVID-19",
    "no_record": "Нет данных",
    "viewing_information": "Просмотр информации о {{first_name}}",
    "download": "Скачать",
    "not_registered": "Похоже, что вы не зарегистрированы ни в одной группе.",
    "please_follow_link": "Пожалуйста, перейдите по ссылке приглашения из вашего электронного письма, чтобы подписаться и получить доступ к вашей информации в Primary.Health.",
    "log_results": "Зарегистрировать результаты тестов",
    "book_appointment": "Записаться на прием",
    "no_new_appointments": "В настоящее время новые записи на прием не принимаются ",
    "upload_vaccinations": "Добавить прививки",
    "vaccination": "вакцинация",
    "new_appointment": "Новый прием",
    "log_otc_results": "Регистрация результатов безрецептурного теста",
    "no_household_members": "У вас пока нет членов семьи.",
    "no_upcoming": "Нет предстоящего приема",
    "update_vaccination": "Обновить прививки",
    "upload_vaccination": "Добавить прививку",
    "title": "Добро пожаловать в Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "Создав учетную запись, вы сможете:",
    "p2": "Планировать предстоящие записи на прием",
    "p3": "Записываться на домашние тесты на COVID-19",
    "p4": "Управлять записями о вакцинации и тестировании иждивенцев",
    "p5": "Простой процесс входа в систему с сайта my.primary.health",
    "no_vaccination_record": "Вакцинация еще не добавлена",
    "vaccination_title_card": "Статус вакцинации на Covid-19",
    "account_and_settings": "Учетная запись и настройки",
    "delete_account": "Удалить учетную запись",
    "language": "Язык",
    "language_current": "Установленный язык: {{language}}",
    "log_out": "Выйти",
    "delete_account_title": "Вы действительно хотите удалить свою учетную запись?",
    "new_encounter": "Новый визит {{first_name}}'s",
    "new_encounter_subtitle": "Для какой группы запланировать новый визит?",
    "no_programs": "Похоже, вы не зарегистрированы ни в одной программе",
    "edit_members": "Редактировать участников",
    "edit_members_subtitle": "Выберите панель инструментов, к которой вы хотите перейти, или добавьте другого участника в свою учетную запись.",
    "add_members": "Добавить участника",
    "delete_member": "Удалить учетную запись пользователя {{full_name}}'s?",
    "delete_member_subtitle": "Удаление пользователя приведет к удалению всей информации, связанной с учетной записью.",
    "select_member": "Выбор члена",
    "edit_appointment": "Изменить назначение",
    "route": {
      "me": "Панель управления",
      "history": "История",
      "programs": "Добавить тест",
      "contact_us": "Помощь",
      "choose_member": "Участник",
      "settings": "Настройки",
      "help": "Помощь"
    },
    "user_dashboard": "Панель управления {{first_name}}'s",
    "view_full_results": "Просмотр страницы результатов",
    "required": "Требуется, если вы используете приложение 'Primary'.",
    "no_email": "Нет электронной почты"
  },
  "vaccination_status": {
    "not_vaccinated": "Не вакцинирован/а",
    "fully_vaccinated": "Полностью вакцинирован/а",
    "partially_vaccinated": "Частично вакцинирован/а",
    "vaccination_records": "Записи о прививках",
    "title": "Какой у вас статус прививки от COVID-19?",
    "definition_title": "Определения статуса вакцинации",
    "definition_cdc": "Определение статуса вакцинации организацией CDC (Центры Контроля и предупреждения заболеваний)",
    "definition_osha": "Определение статуса вакцинации организацией OSHA (охране труда и промышленной гигиене)",
    "definition": {
      "fully_vaccinated": "Через 2 недели после либо последней дозы из 2 серий вакцин Pfizer-BioNTech или Moderna, либо 1 дозы вакцины Johnson & Johnson Janssen.",
      "partially_vaccinated": "Получил только 1 из 2 доз в первичной серии или менее чем через 2 недели после получения последней дозы в 2 сериях доз или 1 дозы Johnson & Johnson's Janssen.",
      "not_vaccinated": "Не вакцинирован от COVID-19",
      "fully_vaccinated_with_one_booster": "Ревакцинировался Pfizer-BioNTech или Moderna после полной вакцинации или 1 дозы Johnson & Johnson's Janssen.",
      "fully_vaccinated_with_two_boosters": "Получили вторую бустерную дозу вакцин Pfizer-BioNTech или Moderna после полной серии или дозы вакцины Johnson & Johnson Janssen и бустерной дозы."
    },
    "label": {
      "fully_vaccinated": "Полная вакцинация",
      "partially_vaccinated": "Частичная вакцинация",
      "not_vaccinated": "Не вакцинирован",
      "fully_vaccinated_with_one_booster": "Полная вакцинация + бустер",
      "fully_vaccinated_with_two_boosters": "Полная вакцинация + 2 бустера",
      "prefer_not_to_answer": "Предпочитаю не отвечать"
    },
    "progress_bar_title": "История вакцинации от COVID-19",
    "upload_record_card": "Загрузите карту прививок от COVID-19",
    "show_example": "Показать пример",
    "hide_example": "Закрыть пример",
    "take_photo": "Нажмите, чтобы сделать фото",
    "or": "ИЛИ",
    "upload_image_or_pdf_instead": "Загрузить изображение/pdf",
    "select_doses_received": "Выберите все прививки от COVID-19, которые вы получили",
    "first_dose": "Первая прививка",
    "second_dose": "Вторая прививка",
    "first_booster_dose": "Первый бустер",
    "second_booster_dose": "Второй бустер",
    "additional_dose": "Дополнительная прививка",
    "first_dose_manufacturer": "Укажите производителя вакцины против COVID-19 вашей первой дозы прививки",
    "first_dose_date": "Укажите дату первой дозы прививки",
    "second_dose_manufacturer": "Укажите производителя вакцины против COVID-19 вашей второй дозы прививки",
    "second_dose_date": "Укажите дату второй дозы прививки",
    "first_booster_dose_manufacturer": "Укажите производителя вакцины против COVID-19 вашей первой бустерной дозы прививки",
    "first_booster_dose_date": "Укажите дату первой бустерной дозы прививки",
    "second_booster_dose_manufacturer": "Укажите производителя вакцины против COVID-19 вашей второй бустерной дозы прививки",
    "second_booster_dose_date": "Укажите дату второй бустерной дозы прививки",
    "additional_dose_manufacturer": "Укажите производителя вакцины против COVID-19 вашей дополнительной прививки",
    "additional_dose_date": "Укажите дату дополнительной прививки",
    "completed_one": "Завершено",
    "completed_two": "Спасибо за информацию о вашем прививочном статусе!",
    "progress_bar_complete": "Завершить",
    "upload_image": "Загрузить изображение",
    "retake_photo": "Переснять фото",
    "other": "Другое",
    "remove_first_dose": "Удалять информацию о первой дозы прививки",
    "remove_second_dose": "Удалять информацию о второй дозы прививки",
    "remove_first_booster_dose": "Удалять информацию о первой бустерной дозы прививки",
    "remove_second_booster_dose": "Удалять информацию о второй бустерной дозы прививки",
    "remove_additional_dose": "Удалять информацию о дополнительной прививки",
    "exemption": "У вас есть отвод?",
    "exempt": "Да, у меня есть освобождение от вакцинации",
    "not_exempt": "Нет, у меня нету освобождение от вакцинации",
    "enter_exemption": "Ввести освобождение от вакцинации",
    "upload_exemption_documentation": "Приложить подтверждающие документы о освобождение от вакцинации",
    "remove_dose": "Удалить дозу",
    "continue": "Продолжить",
    "enter_credentials": "Введите данные регистрации",
    "incorrect_credentials": "Данные неверны, попробуйте ещё раз",
    "add_photo": "Добавить фото"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Здравствуйте {{name}}, Мы заметили что Вы не завершили запись на {{group_name}} прием с {{org_name}}. Чтобы сохранить Вашу запись, Вам необходимо пройти этап оплаты. Пожалуйста, нажмите на эту ссылку {{complete_url}}, чтобы завершить процесс. Если Вы хотите отказаться от записи, пожалуйста, нажмите на эту ссылку {{cancel_url}}. Спасибо"
    }
  },
  "otc": {
    "record_results_for_abbott": "Сделайте тест BinaxNOW и запишите Ваши результаты",
    "record_results": "Сделайте тест и запишите свои результаты",
    "activate_a_kit": "Активировать набор",
    "activate_a_kit_for_test": "Активировать набор для {{test}}",
    "todays_test_process": "Сегодняшний тестовый процесс:",
    "date_of_birth_question": "Дата рождения {{first_name}}?",
    "confirm_dob_to_continue": "Пожалуйста, подтвердите дату рождения участника, чтобы продолжить:",
    "incorrect_dob": "Неверная дата рождения",
    "file_too_large": "Убедитесь, что размер загружаемого файла меньше чем {{size}}",
    "enter_valid_date_in_last_x_days": "Введите действительную дату за последние {{n}} дн",
    "barcode_format_not_valid": "Формат штрих-кода неправильный",
    "complete_button": "Заполнено",
    "next_button": "Далее",
    "back_button": "Назад",
    "report_a_test": "Сообщить о тесте:",
    "x_%_completed": "Заполненo на {{n}}%",
    "answer_survey": "Ответьте на вопросы анкеты",
    "steps": {
      "answer_survey": "Заполните анкету",
      "review_instructions": "Ознакомьтесь с инструкциями",
      "scan_code": "Отсканируйте код",
      "enter_time": "Укажите время",
      "enter_result": "Укажите результат",
      "take_photo": "Сфотографируйте",
      "confirm_result": "Подтвердите результат",
      "selest_test_kit": "Выберите тип теста",
      "enter_date": "Введите дату",
      "enter_date_and_time": "Введите дату и время"
    },
    "review_instructions": "Ознакомьтесь с инструкциями по тестированию",
    "follow_instructions_or_below": "Следуйте инструкциям в приложении к тесту или ознакомьтесь с инструкциями ниже.",
    "watch_video": "Посмотреть видео",
    "view_instructions": "Посмотреть инструкции",
    "follow_instructions": "Следуйте инструкциям в приложении к Вашему тесту.",
    "scan_code": "Отсканируйте QR-код теста",
    "qr_code_description": "QR-код уникален для Вашего теста и находится в правом верхнем углу теста",
    "show_example": "Показать пример",
    "hide_example": "Скрыть пример",
    "barcode_scanned": "Штрих-код успешно отсканирован",
    "enter_barcode": "Введите штрих-код",
    "scan_qr_code": "Отсканируйте QR-код",
    "which_test_did_you_take": "Какой тест Вы сдавали?",
    "when_did_you_take_test": "Когда Вы сделали тест?",
    "what_day_did_you_take_test": "В какой день Вы сдавали этот тест?",
    "what_time_did_you_take_test": "Во сколько Вы сдали тест?",
    "time": {
      "today": "Сегодня",
      "yesterday": "Вчера",
      "two_days_ago": "2 дня назад"
    },
    "enter_current_time": "Введите текущее время",
    "enter_result": "Укажите результат теста",
    "choose_result_option": "Выберите вариант, который описывает результат Вашей карточки тестирования:",
    "clarify_results": "Уточните свои результаты",
    "take_result_photo": "Сфотографируйте результаты",
    "take_result_photo_description": "Сфотографируйте заполненную карточку результатов, чтобы записать свои результаты.",
    "my_result": "Мой результат",
    "switch_camera": "Переключите камеру",
    "confirm_result": "Подтвердить результат",
    "name": "Имя:",
    "date": "Дата:",
    "result": "Результат:",
    "results": "Результаты",
    "test_submitted": "Спасибо, {{first_name}}! Ваш {{test}} успешно сдан.",
    "dob": "Дата рождения:",
    "administered": "Управляется:",
    "or": "ИЛИ",
    "upload": "Загрузить",
    "change_uploaded_file": "Заменить загруженный файл",
    "take_photo": "Сфотографируйте",
    "confirm_information": "Подтвердить информацию",
    "barcode": "Штрих-код:",
    "scan_test_barcode": "Отсканируйте штрих-код теста",
    "barcode_description": "Штрих-код на Вашем тесте уникален и Вы можете найти его на тесте",
    "enter_date_in_last_30_days": "Введите дату за последние 30 дней и приблизительное время прохождения теста",
    "add_image": "Добавить изображение",
    "change_image": "Заменить изображение",
    "skip_barcode_reader": "Не удалось отсканировать код? Нажмите «Далее», чтобы все равно сообщить о результатах теста",
    "enter_date_and_time": "Введите дату и время теста",
    "enter_date": "Введите дату теста",
    "did_you_take_test_today": "Вы сделали тест сегодня?",
    "record_results_for_generic": "Протестируйте и запишите свои результаты для теста {{name}}",
    "choose_result_option_custom": "Выберите параметр, описывающий ваш результат {{test_name}}:"
  },
  "yes": "Да",
  "no": "Нет",
  "event_token": {
    "title": "Пожалуйста, введите приведенный ниже код в торговый автомат, чтобы получить тест-набор",
    "loading_text": "Загрузка, подождите немного"
  },
  "appointment_recovery": {
    "complete_title": "Готово!",
    "subtitle_1": "Если вы используете iPhone, нажмите **Готово** в верхнем левом углу экрана, чтобы вернуться на панель управления.",
    "subtitle_2": "Если вы используете Android, нажмите **X** в верхнем левом углу экрана",
    "subtitle_0": "Для продолжения, выйдите из браузера."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Согласованные отношения",
  "finish_registration": {
    "title": "Завершить регистрацию",
    "subtitle": "Пожалуйста заполните следующую информацию перед вашим визитом:",
    "button": "Завершить регистрацию"
  },
  "arab_ethnicity": {
    "arab": "Араб",
    "non_arab": "Не Араб",
    "prefer_not_to_answer": "Предпочитаю не отвечать",
    "question": "Арабская этническая принадлежность",
    "subtitle": "Мы обязаны собирать эту информацию по закону.",
    "help": "Этническая принадлежность охватывает такие общие черты как язык, культурные традиции, религия, и другие отличительные характеристики. Она может как совпадать, так и не совпадать с расовой."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "В зависимости от вашего устройства перейдите по ссылке ниже, чтобы загрузить первичный медицинский талон."
    }
  },
  "stepper": {
    "next": "Следующие",
    "back": "Назад",
    "complete": "Завершить"
  },
  "registration_direcory": {
    "keyword_search_label": "Keyword, city, zip code, organization name",
    "keyword_search_label_mobile": "City, zip code, organization name",
    "zero_results": {
      "title": "We didn’t find any providers with that search term.",
      "sub_title": "Try searching for your provider by:",
      "p1": "Zip code",
      "p2": "City or town name",
      "p3": "County name",
      "p4": "Provider or organization name"
    }
  },
  "registration_flyer": {
    "open_camera": "Откройте приложение камеры на своем устройстве",
    "point_to_qr_code": "Наведите на QR-код и нажмите на ссылку",
    "complete_registration": "Завершите регистрацию",
    "need_help": "Нужна помощь? Свяжитесь с нами по"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Подтверждение информации о вакцинах",
    "select_checkbox": "Пожалуйста, ознакомьтесь со следующим и установите соответствующий флажок ниже, прежде чем продолжить.",
    "opportunity_to_read": "У меня есть или мне была предоставлена возможность ознакомиться, или мне зачитали информационный бюллетень о вакцинах (“VIS”) или разрешение на экстренное применение (“EUA”), предоставленный для введения вакцины (ов):",
    "vaccine_info_sheet": "информационный листок о вакцине",
    "vaccine_fact_sheet": "информационный бюллетень о вакцинах информационный бюллетень",
    "vaccine_info_statement": "информационное заявление о вакцине"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "Polio",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "Varicella",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "о вакцинах этот набор включает тесты на хламидиоз и гонорею.",
    "B": "этот набор включает в себя тесты на хламидиоз и гонорею.",
    "C": "этот набор включает в себя тесты на сифилис.",
    "D": "этот набор включает в себя тесты на ВИЧ, сифилис, хламидиоз и гонорею.",
    "E": "этот набор включает в себя тесты на ВИЧ и сифилис.",
    "box_type": "Поле %{тип}",
    "administered_text": "Мы свяжемся с вами, когда ваш набор поступит в лабораторию и когда ваши результаты будут готовы.",
    "activate_kit": "Активируйте мой тестовый набор",
    "register_another_test": "Зарегистрируйте еще один тест",
    "credentials": {
      "title": "Давайте начнем",
      "label": "Контакт",
      "p1": "Мы начнем с некоторой информации о вас.",
      "location_step": {
        "p1": "Кто предоставил вам набор для тестирования?",
        "load": "Загружайте больше"
      }
    },
    "checklist": {
      "not_urinated": "Вы не мочились в течение последнего часа.",
      "bathroom": "У вас есть доступ в ванную комнату для сбора мочи.",
      "anal_swab": "У вас есть доступ в уединенное место для проведения анального мазка.",
      "blood_extraction": "У вас есть доступ к чистой среде для взятия крови.",
      "washed_hands": "Вы вымыли руки с мылом и теплой водой в течение 30 секунд.",
      "title": "Вы готовы приступить к сбору образцов?",
      "no_alcohol": "Не употребляйте алкоголь в день сбора.",
      "menstruation": "Не берите вагинальный мазок во время менструации или в течение 24 часов после полового акта."
    },
    "confirm": {
      "title": "Подтвердите вашу информацию",
      "p1": "Верна ли эта информация?"
    },
    "display_name": {
      "A": "Хламидиоз и гонорея (на одном участке)",
      "B": "Хламидиоз и гонорея (3 сайта)",
      "C": "Сифилис",
      "shortened": {
        "A": "Хламидиоз и гонорея",
        "B": "Хламидиоз и гонорея",
        "C": "Сифилис"
      },
      "CC": "Скрининг на колоректальный рак (FIT)",
      "CE": "Скрининг целиакии",
      "CR": "Тест на креатинин",
      "D": "4 Панельных теста - ВИЧ, сифилис, хламидиоз и гонорея (на одном сайте)",
      "DD": "Скрининг диабета (HbA1c)",
      "E": "ВИЧ и сифилис",
      "H": "ВПЧ",
      "P": "Гепатит С",
      "HS": "Простой герпес",
      "I": "ВИЧ",
      "TT": "Уровень тестостерона",
      "VD": "витамин D",
      "VS": "Вирусный надзор",
      "AP": "Chlamydia & Gonorrhea"
    },
    "rescan": "Повторное сканирование",
    "scanned": "Вы отсканировали поле %{тип}",
    "not_yet": "ещё нет",
    "success_registration": "Регистрация прошла успешно!",
    "no_box": "У вас нет коробки?",
    "faq_link": "Посетите раздел Часто задаваемые вопросы",
    "welcome": "Добро пожаловать!",
    "scan": {
      "title": "Вы на шаг приблизились к тому, чтобы стать здоровее.",
      "p1": "Отсканируйте или введите штрих-код ** комплекта ** на боковой стороне вашей коробки."
    },
    "appointments": {
      "title": "Выберите пациента и поставщика медицинских услуг",
      "p1": "Мы нашли более одного профиля, связанного с вашей контактной информацией. Выберите правильный профиль:",
      "new": "Новый пациент или поставщик медицинских услуг"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "Мы нашли историю болезни вашего пациента."
    },
    "section_1": "Подтвердите содержимое набора",
    "section_2": "Набор тестов",
    "section_3": "Упаковка",
    "section_4": "Доставка",
    "box_a": "Вставка А",
    "box_b": "Вставка В",
    "box_c": "Вставка C",
    "kit_flow": {
      "button_1": "Продолжайте упаковывать",
      "button_2": "Я готов",
      "button_3": "Мой набор завершен и упакован",
      "button_4": "Я закончил",
      "button_5": "Следующий тест",
      "button_6": "Пропустить инструкции"
    },
    "contents": {
      "header": "Отлично! Давайте убедимся, что ваш набор укомплектован",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Карточка для сбора крови",
      "blood_collection_card_subtitle": "(1 или 2 в зависимости от тестового набора)",
      "alchohol_pads": "2 спиртовые прокладки",
      "lancets": "Одноразовые ланцеты",
      "up_to_other": "(До 4 в зависимости от тестового набора)",
      "adhesive_bandages": "Клейкие повязки",
      "pipette": "Пипетка",
      "urine_tube": "Трубка для сбора мочи",
      "urine_cup": "Чашка для сбора мочи",
      "oral_swab": "Тампон для полости рта",
      "oral_tube": "Трубка для сбора орального содержимого",
      "anal_swab": "Анальный тампон",
      "anal_tube": "Анальная коллекторная трубка",
      "vaginal_tube": "1 вагинальная пробирка для сбора",
      "vaginal_swab": "1 вагинальный тампон",
      "biodegradable_paper": "Биоразлагаемая бумага для сбора мусора",
      "bottle_and_probe": "Бутылка для отбора проб и зонд",
      "prepaid_envelope": "Конверт для доставки с предоплатой",
      "biohazard_subtitle": "(с впитывающей прокладкой)",
      "biohazard_bag": "1 пакет для образцов",
      "biohazard_bag_plural": "({{count}}) Биологически опасные пакеты",
      "alcohol_pads_plural": "{{count}} спиртовых прокладок",
      "sterile_gauze_pad": "1 стерильный марлевый тампон"
    },
    "packaging": {
      "title": "Контрольный список упаковки",
      "subtitle": "Перед отправкой вашего комплекта убедитесь, что:"
    },
    "packaging_box_a": {
      "step_1": "Вы упаковали все содержимое тестового набора",
      "step_2": "Вы использовали все коллекционные материалы, содержащиеся в наборе, для сбора необходимых образцов",
      "step_3": "Ваша **дата рождения** указана на всех пробирках для сбора образцов в формате ММ/ДД/ГГГГ",
      "step_4": "Дата сбора ** указана на всех пробирках для сбора образцов в формате ММ/ДД/ГГГГ",
      "step_5": "Пробирка для сбора образцов запечатана в пакет для биологической защиты",
      "step_6": "Все образцы помещаются в коробку",
      "step_7": "Коробка помещается в предварительно оплаченный почтовый конверт",
      "step_8": "Конверт для обратной отправки полностью запечатан"
    },
    "packaging_box_b": {
      "step_1": "Вы упаковали все содержимое тестового набора, даже если пропустили тест(ы)",
      "step_2": "Вы использовали все коллекционные материалы, содержащиеся в наборе, для сбора необходимых образцов",
      "step_3": "Ваша **дата рождения** указана на всех пробирках для сбора образцов в формате ММ/ДД/ГГГГ",
      "step_4": "Дата сбора ** указана на всех пробирках для сбора образцов в формате ММ/ДД/ГГГГ",
      "step_5": "Каждая пробирка для сбора образцов запечатана в отдельный биологически опасный пакет (только ** один ** образец в пакете).",
      "step_6": "Все образцы помещаются в коробку",
      "step_7": "Коробка помещается в предварительно оплаченный почтовый конверт",
      "step_8": "Конверт для обратной отправки полностью запечатан"
    },
    "packaging_box_c": {
      "step_1": "Вы упаковали все содержимое набора для тестирования, включая использованные ланцеты.",
      "step_2": "Ваша **дата рождения** указана в карточке для взятия крови в формате ММ/ДД/ГГГГ",
      "step_3": "Дата **взятия крови** указана в карточке для взятия крови в формате ММ/ДД/ГГГГ",
      "step_4": "Карточка для взятия крови и все использованные ланцеты помещаются в пакет для биологической защиты",
      "step_5": "Пакет для биологической защиты помещается в коробку",
      "step_6": "Коробка помещается в предварительно оплаченный почтовый конверт",
      "step_7": "Конверт для обратной отправки полностью запечатан"
    },
    "success": {
      "header_1": "Отлично!",
      "text_1": "Вы готовы взять свой первый образец.",
      "header_2": "Ты сделал это!",
      "text_2": "** Далее: ** упакуйте свой набор для отправки обратно.",
      "header_3": "Отличная работа!",
      "text_3": "Вы на шаг приблизились к улучшению здоровья.",
      "header_4": "Отличная работа!",
      "text_4": "Еще раз, и все готово!",
      "text_5": "Вы готовы взять свой первый образец"
    },
    "instructions": {
      "title": "Выберите тест, чтобы просмотреть инструкции:",
      "subtitle": "Тест",
      "button": "Инструкции",
      "pdf": "Инструкции в формате PDF"
    },
    "instructions_box_a": {
      "title": "Сбор мочи",
      "subtitle": "Для этого теста вам понадобятся следующие материалы:",
      "step_1": "Не мочитесь по крайней мере за 1 час до сбора. Лучше всего использовать первую утреннюю мочу.",
      "step_2": "Мочитесь непосредственно в пищевод или чашу для сбора мочи, наполняя ее на 1/3- 1/2.",
      "step_3": "Налейте мочу из графина или используйте пипетку для переливания из стакана для сбора в пробирки для сбора, помеченные оранжевой этикеткой <span style=\"color: #FF5000\">**\"МОЧА\"**</span>.",
      "step_4": "Наполняйте сборную трубку (трубки) до тех пор, пока смешанная жидкость не окажется между указанными линиями заполнения.",
      "step_4_sublabel": "Не заполняйте пробирку выше линии максимального заполнения, иначе образец будет отбракован.",
      "step_5": "Наденьте крышку на пробирку для сбора и убедитесь, что она закрыта равномерно и плотно.",
      "step_6": "Напишите дату своего рождения и дату взятия образца (сегодняшнюю дату) в формате ММ/ДД/ГГГГ на пробирке в специально отведенном месте.",
      "step_7": "Поместите образец в пустой биологически опасный пакет с впитывающей прокладкой и убедитесь, что уплотнение полностью закрыто."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Сбор мочи",
        "subtitle": "Для этого теста вам понадобятся следующие материалы:",
        "step_1": "Не мочитесь по крайней мере за 1 час до сбора. Лучше всего использовать первую утреннюю мочу.",
        "step_2": "Мочитесь непосредственно в пищевод или чашу для сбора мочи, наполняя ее на 1/3- 1/2.",
        "step_3": "Налейте мочу из графина или используйте пипетку для переливания из стакана для сбора в пробирки для сбора, помеченные оранжевой этикеткой <span style=\"color: #FF5000\">**\"МОЧА\"**</span>.",
        "step_4": "Наполняйте сборную трубку (трубки) до тех пор, пока смешанная жидкость не окажется между указанными линиями заполнения.",
        "step_4_subtitle": "Не заполняйте пробирку выше линии максимального заполнения, иначе образец будет отбракован.",
        "step_5": "Наденьте крышку на пробирку для сбора и убедитесь, что она закрыта равномерно и плотно.",
        "step_6": "Напишите дату своего рождения и дату взятия образца (сегодняшнюю дату) в формате ММ/ДД/ГГГГ на пробирке в специально отведенном месте.",
        "step_7": "Поместите образец в пустой биологически опасный пакет с впитывающей прокладкой и убедитесь, что уплотнение полностью закрыто."
      },
      "test_2": {
        "title": "Тампон для полости рта",
        "subtitle": "Для этого теста вам понадобятся следующие материалы:",
        "step_1": "Держите тампон между едва заметной линией надреза (если таковой имеется) и ватной частью тампона.",
        "step_2": "Вставьте тампон в рот и потрите кончиком тампона заднюю стенку горла в течение 10 секунд.",
        "step_3": "Осторожно извлеките тампон и поместите в пробирку для сбора, помеченную синей этикеткой <span style=\"color: #001F70\">**\"ОРАЛЬНЫЙ\"**</span>. Разломите тампон по линии надреза, прижав его к трубке для сбора.",
        "step_4": "Наденьте крышку на пробирку для сбора и убедитесь, что она закрыта равномерно и плотно.",
        "step_5": "Напишите дату своего рождения и дату взятия образца (сегодняшнюю дату) в формате ММ/ДД/ГГГГ на пробирке в специально отведенном месте.",
        "step_6": "Поместите образец в пустой биологически опасный пакет с впитывающей прокладкой и убедитесь, что уплотнение полностью закрыто."
      },
      "test_3": {
        "title": "Анальный тампон",
        "subtitle": "Для этого теста вам понадобятся следующие материалы:",
        "step_1": "Держите тампон между едва заметной линией надреза (если таковой имеется) и ватной частью тампона.",
        "step_2": "Вставьте тампон на 3-5 см (1-2 дюйма) в анальный канал. Осторожно поворачивайте тампон в течение 5-10 секунд, чтобы собрать все возможные микроорганизмы.",
        "step_3": "Осторожно извлеките тампон и поместите в пробирку для сбора, помеченную зеленой этикеткой <span style=\"color: #00C08C\">**“АНАЛЬНЫЙ”**</span>. Разломите тампон по линии надреза, прижав его к трубке для сбора.",
        "step_4": "Наденьте крышку на пробирку для сбора и убедитесь, что она закрыта равномерно и плотно.",
        "step_5": "Напишите дату своего рождения и дату взятия образца (сегодняшнюю дату) в формате ММ/ДД/ГГГГ на пробирке в специально отведенном месте.",
        "step_6": "Поместите образец в пустой биологически опасный пакет с впитывающей прокладкой и убедитесь, что уплотнение полностью закрыто."
      }
    },
    "instructions_box_c": {
      "title": "Забор крови",
      "subtitle": "Для этого теста вам понадобятся следующие материалы:",
      "step_1": "Напишите свое имя, дату рождения и дату получения в специально отведенных полях. Используйте формат ММ/ДД/ГГГГ.",
      "step_2": "Откройте клапан карточки крови, чтобы были видны круги на бумаге для сбора крови. Не прикасайтесь к бумаге для сбора крови.",
      "step_3": "Мойте руки теплой водой не менее 30 секунд, затем энергично встряхивайте руки в течение 15 секунд, чтобы стимулировать приток крови к пальцам.",
      "step_4": "Протрите кончик пальца спиртовой подушечкой. Лучше всего использовать средний или безымянный палец вашей недоминирующей руки.",
      "step_5": "Возьмите ланцет и открутите колпачок. Плотно вдавите маленький наконечник в кончик пальца, пока игла не выйдет со щелчком. Держа руку ниже сердца во время сбора, массируйте палец от основания до кончика, чтобы стимулировать приток крови.",
      "step_5_subtitle": "Ланцеты предназначены для одноразового использования. Все ланцеты необходимо вернуть вместе с вашим образцом в лабораторию для утилизации.",
      "step_6": "Начиная с центра, нанесите 3-6 капель, чтобы заполнить круг, и пропитайте бумагу для сбора. Не прикасайтесь к бумаге пальцем, так как это ограничит приток крови. Как только круг заполнится, переходите к следующему кругу. Это нормально, если кровь выходит за пределы линий, но не позволяйте пятнам крови растекаться друг по другу.",
      "step_6_subtitle": "Не добавляйте дополнительную кровь в круг после завершения или высыхания. “Наслоение” крови приведет к аннулированию сбора.",
      "step_7": "Не закрывая карту крови, положите ее на ровную поверхность и дайте бумаге для сбора крови высохнуть на воздухе при комнатной температуре не менее 30 минут. Не нагревайте, не сушите феном и не подвергайте бумагу для сбора крови воздействию прямых солнечных лучей. Нагрев может повредить образец.",
      "step_7_subtitle": "Проверьте обратную сторону бумаги для сбора крови. Кровь должна пропитать все до конца и заполнить каждый кружок бумаги для сбора.",
      "step_8": "Когда бумага для сбора крови высохнет, закройте карту крови, подвернув клапан. Положите карту крови и ланцеты в пакет для биологической защиты вместе с упаковкой осушителя. Убедитесь, что пакет для биологической защиты должным образом запечатан."
    },
    "test_tips": {
      "title": "Советы по правильному сбору крови",
      "subtitle": "Для достижения наилучших результатов:",
      "step_1": "Убедитесь, что вы не обезвожены во время сбора. Увлажнение способствует притоку крови.",
      "step_2": "Не проводите сбор сразу после курения.",
      "step_3": "Мытье и согревание рук под теплой водой поможет улучшить кровообращение в ваших руках.",
      "step_4": "Энергично встряхните руками в направлении пола, чтобы стимулировать приток крови к пальцам.",
      "step_5": "Держите устройство для сбора крови и руки ниже сердца во время сбора для лучшего притока крови.",
      "step_6": "Возможно, вам понадобится более одного укола пальцем. Повторяйте эти действия между каждым уколом пальца."
    },
    "shipping": {
      "header": "Ваш набор готов к отправке!",
      "text": "** Поздравляю!** Вы закончили обследование на сексуальное здоровье."
    },
    "pick_up": "Получите рекомендованный вами набор для тестирования у сотрудников сайта и зарегистрируйтесь ниже",
    "short_display_name": {
      "A": "Хламидиоз и гонорея",
      "B": "Хламидиоз и гонорея",
      "C": "Сифилис",
      "CC": "Колоректальный рак",
      "CE": "Целиакия",
      "CR": "Креатинин",
      "D": "ВИЧ, сифилис, хламидиоз и гонорея",
      "DD": "Диабет",
      "E": "ВИЧ и сифилис",
      "H": "ВПЧ",
      "P": "Гепатит С",
      "HS": "Простой герпес",
      "I": "ВИЧ",
      "TT": "Тестостерон",
      "VD": "витамин D",
      "VS": "Вирусный надзор"
    },
    "test_kit": "Набор для тестирования",
    "view_results": "Просмотр результатов",
    "recommended_kit_title": "Рекомендуемые наборы для тестирования",
    "instructions_box_h": {
      "title": "Сбор вагинального мазка",
      "subtitle": "Пробирки для сбора могут содержать жидкий консервант. <span class=\"text-danger\">**Не выливайте жидкий консервант из пробирок для сбора, если он имеется.**</span>",
      "step_1": "Держите тампон между едва заметной линией надреза (если таковой имеется) и частью тампона с ватным наконечником в одной руке и отделите половые губы (складки кожи вокруг влагалищного отверстия).",
      "step_2": "Вставьте тампон на 5 см (2 дюйма) во влагалищное отверстие. Осторожно поворачивайте тампон в течение 30 секунд, одновременно втирая тампон в стенки влагалища.",
      "step_3": "Осторожно извлеките тампон и поместите в пробирку для сбора, помеченную <span class=\"text-danger\">** КРАСНОЙ НАДПИСЬЮ “ВАГИНАЛЬНЫЙ”**</div>. Сломайте тампон, прижав его к трубке для сбора.",
      "step_4": "Наденьте крышку на пробирку для сбора и убедитесь, что она закрыта равномерно и плотно.",
      "step_5": "Напишите свою дату рождения в формате ММ/ДД/ГГГГ на пробирке в специально отведенном месте (DOB).",
      "step_6": "Напишите дату взятия образца (сегодняшнюю дату) в формате ММ/ДД/ГГГГ на пробирке в специально отведенном месте (колл. Дата).",
      "step_7": "Поместите образец в пустой биологически опасный пакет с впитывающей прокладкой и убедитесь, что уплотнение полностью закрыто."
    },
    "packaging_box_h": {
      "step_3": "Ваша **дата рождения** указана на пробирке для сбора образцов в формате ММ/ДД/ГГГГ",
      "step_4": "Ваша **дата взятия** указана на пробирке для сбора образцов в формате ММ/ДД/ГГГГ",
      "step_6": "Пакет для биологической защиты помещается в коробку"
    },
    "instructions_box_cc": {
      "title": "Коллекция табуретов",
      "step_1": "Напишите дату своего рождения и дату взятия образца (сегодняшнюю дату) в формате ММ/ДД/ГГГГ на пробирке в специально отведенном месте. Откройте зеленую крышку, повернув и подняв ее.",
      "step_2": "Положите прилагаемую бумагу для сбора мусора в унитаз поверх воды.",
      "step_3": "Положите образец кала поверх бумаги для сбора проб.",
      "step_4": "Соберите пробу со стула до того, как бумага впитается, а образец стула коснется воды.",
      "step_5": "Соскребите поверхность образца кала с помощью пробоотборника.",
      "step_6": "Полностью покройте рифленую часть пробоотборника образцом кала.",
      "step_7": "Закройте флакон для отбора проб, вставив пробоотборник и плотно защелкнув зеленую крышку. Не открывайте снова.",
      "step_8": "Промывать. Бумага для сбора отходов поддается биологическому разложению и не наносит вреда септическим системам.",
      "step_9": "Заверните флакон для отбора проб во впитывающую прокладку и вставьте в пакет для биологической защиты."
    },
    "contact_support": {
      "title": "Давайте исправим это",
      "text_1": "Нам жаль слышать, что что-то не так!",
      "text_2": "Пожалуйста, свяжитесь с нами, чтобы сообщить, в чем проблема, и мы поможем заменить ваш комплект."
    },
    "contact_support_success": {
      "title": "Мы получили ваше сообщение",
      "text_1": "Спасибо, что обратились к нам.",
      "text_2": "Сотрудник нашей службы поддержки скоро свяжется с вами.",
      "button_text": "Вернуться домой"
    },
    "kit_in_transit_to_patient": "Ваш заказ уже в пути! Большинство заказов доставляются\nв течение 2-5 рабочих дней. Как только вы получите свой набор, мы вышлем вам\nдополнительную информацию о том, как его активировать, и отправим обратно.\n\nСсылка для отслеживания: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "Лаборатория получила ваш набор! Когда результаты будут готовы, вы получите уведомление о необходимости проверить свой портал для пациентов",
    "registration_confirmation": "теперь ваша регистрация завершена! Посетите страницу подтверждения регистрации, чтобы активировать свой тестовый набор:",
    "kit_ordered_online": "Мы получили ваш заказ на комплект и вышлем обновленную информацию, как только он будет отправлен! Номер заказа: {{order_number}} Дата заказа: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "Мы получили ваш заказ!",
      "p1": "Мы получили ваш заказ! Как только ваш заказ будет отправлен, мы вышлем вам еще одно обновление.",
      "order_number": "Заказ #",
      "order_date": "Дата заказа"
    },
    "completed": "В комплект",
    "I": "этого набора входят тесты на ВИЧ.",
    "CC": "этот набор включает в себя тесты на колоректальный рак.",
    "CE": "этот набор включает в себя тесты на целиакию.",
    "CR": "этот набор включает в себя тесты на креатинин.",
    "DD": "этот набор включает в себя тесты на диабет.",
    "H": "этот набор включает в себя тесты на ВПЧ.",
    "P": "этот набор включает в себя тесты на гепатит С.",
    "HH": "в этот набор входят тесты на уровень гемоглобина.",
    "HS": "этот набор включает в себя тесты на ВПГ-2.",
    "TT": "этот набор включает в себя тесты на тестостерон.",
    "VD": "этот набор включает в себя тесты на витамин D."
  },
  "copy_link": "Скопировать ссылку на страницу",
  "copied_link": "Скопированная ссылка!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Защита от распространенных вирус",
      "vaccine_covid": "Защита от вирусов COVID-19",
      "tdap": "Вакцина Tdap может предотвратить столбняк",
      "polio": "Защита от вируса полиомиелита. Требуется для детей перед поступлением в школу.",
      "varicella": "Вакцина, защищающая от ветряной оспы",
      "shingles": "Вакцина, защищающая от опоясывающего лишая. Минимальный возраст - 18 лет.",
      "human_papillomavirus": "Вакцина, защищающая от ВПЧ. Рекомендуется для детей в возрасте 11 лет.",
      "meningococcal": "Вакцина защищает от четырех типов менингококковых бактерий."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Найдите клинику",
    "select_pin": "Выберите пин-код, чтобы просмотреть подробную информацию",
    "miles_shorten": "{{miles}} миль",
    "next_available": "Следующий доступный",
    "appointment_type": "Тип назначения",
    "individual_appointment": "Индивидуальная встреча",
    "family_appointment": "Семейная встреча",
    "vaccines": "Вакцины",
    "what_kind_appointment": "На какую встречу вы хотите записаться?",
    "select_family_or_individual": "Выберите семейную встречу, если вы планируете встречу сразу для нескольких членов семьи.",
    "vaccines_selected": "Отобранные вакцины",
    "where_care": "Где вы хотите получить медицинскую помощь?",
    "select_vaccines_for_individual": "Выберите вакцины для вашего назначения (необязательно).",
    "select_vaccines_for_family": "Выберите вакцины для вашего семейного приема (необязательно).",
    "schedule_services": "Запланируйте услуги",
    "add_family": "Add family members and select services below.",
    "family_member": "Член семьи {{number}}",
    "morning": "Утро",
    "afternoon": "Вторая половина дня",
    "slot_available": "{{number}} свободных",
    "within_x_miles": "В пределах {{number}} миль от",
    "any_distance": "На любом расстоянии от",
    "partial_results": "Частичные результаты",
    "partial_matches": "Следующие клиники предлагают некоторые, но не все вакцины, которые вы ищете",
    "no_matches": "Этот поиск не нашел никаких совпадений. Попробуйте изменить свои фильтры для получения лучших результатов.",
    "no_clinics_found": "Здесь нет ни одной клиники, которая соответствовала бы вашему запросу.",
    "broaden_filters": "Попробуйте расширить свои фильтры для получения лучших результатов.",
    "unavailable_vaccines": "Недоступные вакцины:",
    "available_vaccines": "Доступные вакцины:",
    "select_date": "Выберите дату",
    "available_appointments": "ДОСТУПНЫЕ ВСТРЕЧИ",
    "appointment_scheduling_info": "На следующем шаге вы сможете назначить время встречи конкретным членам семьи. Вы можете назначить встречи на разные дни, если захотите.",
    "hold_selected_appointments": "Мы отложим выбранные вами встречи на 15 минут.",
    "appointments_selected": "ВЫБРАННЫЕ ВСТРЕЧИ",
    "no_appointments_selected": "прием  не назначен",
    "vaccines_needed": "Необходимые вакцины",
    "select_x_appointments": "Выберите {{number}} прием",
    "more": "More",
    "less": "Less",
    "register_next_family_member": "Register next family member",
    "successfully_registered_x_of_y_family_members": "You have successfully registered {{x}} of {{y}} family members",
    "next_family_member": "Next family member",
    "appointments_abbreviated": "appts",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "Давайте проверим вашу электронную почту!",
    "hi_full_name": "Здравствуйте {{full_name}},",
    "verify_email_button": "Подтвердите адрес электронной почты",
    "please_verify_email": "Пожалуйста, воспользуйтесь кнопкой ниже, чтобы подтвердить свой адрес электронной почты."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "Гепатит А",
    "hepatitis_b": "Гепатит В",
    "hepatitis_a_b": "Гепатит А и В",
    "hib": "Привет",
    "mmr": "Корь, эпидемический паротит, краснуха",
    "meningococcal": "Менингококковый",
    "mpox": "Mpox",
    "pneumococcal": "Пневмококковый",
    "polio": "Полиомиелит",
    "rsv": "Респираторно-синцитиальный вирус",
    "rotovirus": "Ротавирус",
    "zoster": "Опоясывающий лишай (опоясывающий лишай)",
    "tetanus_diptheria": "Столбняк и дифтерия",
    "tdap": "Tdap",
    "typhoid": "Брюшной тиф",
    "varicella": "Ветряная оспа (Varicella)",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (взрослый)",
    "covid-19_adult_description": "Для людей от 12 лет и старше.",
    "covid-19_children": "COVID-19 (ребенок)",
    "covid-19_children_description": "Для детей в возрасте от 4 до 11 лет.",
    "covid-19_infants": "COVID-19 (младенческий)",
    "covid-19_infants_description": "Для детей в возрасте от 6 месяцев до 3 лет.",
    "influenza": "Грипп",
    "mmrv": "Measles, Mumps, Rubella and Varicella"
  },
  "deep_archived": {
    "admin": "For data security purposes, the data in the group {{test_group_name}} ({{slug}}) is archived and is no longer viewable or changeable from this page. Please reach out to your account manager or support@primary.health if you need assistance.",
    "participant": "This page is no longer available. Please reach out to your contacts from the health campaign: {{test_group_name}}. If you are having trouble viewing an old record, please contact support@primary.health for assistance."
  }
}