export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Month",
    "day": "Day",
    "year": "Year",
    "january": "January",
    "february": "February",
    "march": "March",
    "april": "April",
    "may": "May",
    "june": "June",
    "july": "July",
    "august": "August",
    "september": "September",
    "october": "October",
    "november": "November",
    "december": "December"
  },
  "user": {
    "send_appointment_confirmation_message": "Your appointment is confirmed for {{name}}.",
    "landline_appointment_reminder_message": "Hello, {{full_name}}. This is a reminder for your appointment at {{name}}. Your appointment is on {{date}} at {{time}} at {{address}}.",
    "send_at_home_visit_confirmation_message": "Your appointment is confirmed on {{date}} after {{time}}.",
    "send_mail_order_confirmation_message": "Your mail order is confirmed",
    "send_waitlist_message": "You have been waitlisted for {{name}}. Please do not show up until we confirm an appointment time.",
    "verify_contact": {
      "phone": "Please confirm your phone number by clicking: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Continue registering for an appointment here: {{url}}",
    "on_demand_confirmation_message": "You have successfully registered for {{name}}.",
    "appointment_reminder": "Prior to your appointment at {{start_time}}, please click the link to complete any outstanding action items and view your confirmation. Please do not arrive more than 15 minutes before your appointment.",
    "appointment_reminder_base": "{{text}} {{appt_text}} CLICK HERE: {{link}}",
    "test_result_notifier": {
      "text_message": "Patient portal for {{first_name_with_last_initial}} has been updated. Go to {{url}} and use code: {{access_code}}",
      "email_subject": "New updates in your patient portal",
      "email_intro": "Hello {{first_name_with_last_initial}}",
      "email_p1": " A new message or patient update is available for you.",
      "email_p2": "Click the button below or use the link to view your patient portal and use code",
      "email_p3": "View patient portal at:",
      "email_button": " View portal"
    },
    "landline_appointment_reminder_message_without_time": "Hello, {{full_name}}. This is a reminder for your appointment at {{name}}. Your appointment is on {{date}} at {{address}}.",
    "appointment_reminder_on_demand": "Prior to your appointment on {{date}} please click the link to complete any outstanding action items and view your confirmation.",
    "new_test_result_notifier": {
      "email_intro": "Your patient portal has been updated.",
      "email_text_1": "Visit your Medical History portal below to see any new patient records or lab reports.",
      "email_button": "Medical History",
      "email_text_2": "You can also copy and paste this URL into your browser:"
    },
    "mailers": {
      "email_contact_us": "Have a question? Contact us at"
    }
  },
  "errors": {
    "generic": {
      "message": "Something went wrong, please try again"
    },
    "messages": {
      "blank": "can't be blank"
    },
    "incorrect_credentials": "Your credentials were incorrect, please try again.",
    "error_activating_test_kit": "There was an error activating the test kit."
  },
  "type": "Type",
  "payment": {
    "continue_label": "Continue to payment",
    "policy_text": "If you cancel or do not show up for your appointment, we will refund 80% of your payment, 7 days after your appointment date. You may view the full [Terms of Service]({{link}})",
    "card_number": "Card number",
    "expiry_date": "Expiry date",
    "pay_button": "Pay",
    "no_credit_card": "I will pay on site",
    "cash_payment_helper": "Please make sure to bring **{{payment}}** to your appointment",
    "invoice": "Invoice",
    "pay_by_card": "Pay by card",
    "cost": "Cost",
    "total": "Total",
    "amount_paid": "Amount Paid",
    "balance": "Balance",
    "pay_cash_at_appointment": "I'll pay with cash during my appointment at {{appointment}}",
    "view_invoice": "View/print invoice",
    "refund_policy": "If you cancel your appointment, we will refund {{amount}} of your payment, 7 days after your appointment date. You may view the full [Terms of Service]({{link}})",
    "refund_window_passed": "Your appointment is not eligible for a refund since the appointment time has past. Please reach out to the site coordinators directly for any resolutions."
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Upload a consent waiver or sign the signature box below",
    "stop": "Consent required to complete registration",
    "parent_or_guardian_required_html": "In order to finalize your registration, your parent, guardian, or authorized decision maker must provide consent on the form below and sign their name. You <b>CANNOT</b> consent to this service on your own.",
    "send_parent_or_guardian": "You can send your parent or guardian this link or have them sign below on this page.",
    "consent_guardian_name_signature": "First name",
    "consent_for_minor": "Sign consent on behalf of minor",
    "guardian_signature": "Signature of parent, guardian, or authorized medical decisionmaker",
    "after_you_schedule": "Participants under the age of {{age}} must have consent signed on their behalf by a parent or guardian. Please continue to the end of registration in order to send to your guardian or sign for your dependent.",
    "consent_guardian_last_name_signature": "Last name",
    "consent_first_and_last_name": "First & last name",
    "phone_number": "Phone number",
    "email": "Guardian email",
    "consent_registrar_with_guardian_name_signature": "Guardian first name",
    "consent_registrar_with_guardian_last_name_signature": "Guardian last name",
    "consent_registrar_first_name_signature": "Registrar first name",
    "consent_registrar_last_name_signature": "Registrar last name",
    "consent_on_behalf": "I am signing on behalf of {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "I have been verbally authorized by {{first_name}} {{last_name}} to sign on their behalf.",
    "consent_on_behalf_registrar_with_guardian": "I have been verbally authorized by the guardian of {{first_name}} {{last_name}} to sign on their behalf.",
    "registrar_phone": "Registrar's phone number",
    "registrar_email": "Registrar's email",
    "consented_by_parent_guardian": "Parent / Guardian",
    "consented_by_decision_maker": "Authorized Medical Decision-maker",
    "consented_by_guardian_verbal": "Registrar with Guardian (verbal consent obtained)",
    "consented_by_registrar": "Registrar (verbal consent obtained)",
    "full_name": "Full name",
    "print": "Print"
  },
  "registration": {
    "contact_information": "Name & Address",
    "contact_information_additional": "Parent or guardian contact information for communications & results communication & results",
    "personal_information": "Demographic information",
    "address_placeholder": "Please enter your home address",
    "first_name_label": "First name",
    "last_name_label": "Last name",
    "errors": {
      "state": "State must be a valid 2 character state code",
      "phone_number_invalid": "Phone number invalid",
      "required": "This value is required",
      "field_required": "{{field}} must have a value",
      "email_domain": "Your entry is invalid; please double-check. Registration is limited to participants selected by the sponsoring organization. Please try using your work or community email. If you feel you are eligible and there is an error, please contact support.",
      "age_requirement": "Date of birth does not meet age requirement",
      "signature_required": "Signature is required",
      "regex_invalid": "This value seems to be invalid.",
      "date_invalid": "This date does not exist",
      "invalid_entry": "Please do not continue with registration. Follow the instructions on screen or reach out to your point of contact.",
      "city": "Must be a valid city",
      "survey_unanswered": "Please check that all required questions marked with * have been answered.",
      "postal_code": "Must be a valid 5 digit postal code",
      "option_required": "You must choose one of the options",
      "year_length_invalid": "Enter date of birth with a four digit year (yyyy)",
      "invalid_day": "Day must be between 1 - 31",
      "reached_max_chars": "You have reached the maximum number of characters.",
      "chars_max": "Character max",
      "minimum_length": "Input should be at least {{length}} characters long."
    },
    "insurance_status": {
      "question": "Do you have health insurance?",
      "have_health_insurance": "Yes, I have health insurance",
      "do_not_have_health_insurance": "No, I do not have health insurance"
    },
    "insurance_policy_holder": {
      "question": "Who is the policy holder?",
      "i_am": "I am",
      "my_spouse": "My spouse or partner",
      "my_parents": "My parents",
      "someone_else": "Someone else",
      "policy_first_name": "Policy holder's first name",
      "policy_last_name": "Policy holder's last name",
      "policy_dob": "Policy holder's date of birth",
      "name": "Policy holder's name"
    },
    "insurance_information": {
      "title": "Insurance information",
      "company_name": "Insurance company name",
      "id_number": "Member identification number",
      "group_number": "Member group number",
      "secondary_insurance_label": "I have secondary insurance",
      "take_photo": "Please take a photo of your insurance card. Taking a photo will auto fill some of the information.",
      "front_of_card": "Front of card",
      "card_information": "Insurance card information",
      "back_of_card": "Back of card"
    },
    "employment_information": {
      "label": "Employment",
      "address_1": "Company address",
      "address_2": "Ex: Suite 200",
      "phone_number": "Company phone number",
      "company": "Company name",
      "postal_code": "Employer postal code",
      "employed": "Employed",
      "sole_prorietor": "Sole proprietor",
      "not_employed": "Not employed",
      "reporting_supervisor": "Reporting supervisor",
      "reporting_department": "Reporting department",
      "supervisor_name": "Supervisor name",
      "supervisor_email": "Supervisor email"
    },
    "location_availability": "Testing is currently prioritized for community members living in these areas",
    "custom_survey": "Questionnaire",
    "confirmation": "Review",
    "waitlisted": "Waitlisted",
    "schedule_your_appointment": "Schedule appointment",
    "information": "Information",
    "consent": "Consent",
    "location": "Location",
    "symptoms": "Symptoms",
    "address_required": "Please enter your address",
    "consent_required": "Please consent to continue",
    "required_field": "Indicates required field.",
    "phone_number": "Mobile phone number",
    "email": "Email",
    "date_of_birth": "Date of birth",
    "minimum_age": "The minimum age for participation is {{year}} years old.",
    "no_minimum_age": "There is no minimum age for registration",
    "continue_button": "Continue",
    "email_required": "This value should be a valid email.",
    "done": "Done",
    "signature": "Signature",
    "clear_button": "Clear",
    "back_button": "Back",
    "choose_location": "Choose a location",
    "no_slots": "No more slots available at {{location}}",
    "choose_appointment": "Choose an appointment time at {{location}}",
    "appointment_required": "Please select an appointment time",
    "phone_number_required": "Phone number is invalid",
    "phone_number_label": "Please enter a number that can receive text messages for faster test results",
    "symptoms_experiencing": "Please click symptoms you are currently experiencing. If you have no symptoms, please continue.",
    "household": "Household members",
    "household_p1": "Optionally, create an appointment for household members who also need to get tested.",
    "add_dependent": "Add household member",
    "remove_dependent": "Remove dependent",
    "dependent_consents": "Consents",
    "submit": "Complete",
    "add_waitlist": "Add to wait list",
    "address": "Home address",
    "address_1": "Address line 1",
    "address_2": "Apt/suite #",
    "address_city": "City",
    "address_state": "State",
    "postal_code": "Postal code",
    "race_ethnicity": "Race",
    "gender": "Gender identity",
    "self_described_gender": "Self described gender",
    "interpreter": "Need interpreter? If yes, which language?",
    "consent_to_terms": "I consent to these terms.",
    "reg_not_open": "Registrations not opened",
    "no_more_avail_spots": "The time slot you selected is no longer available. Please try again.",
    "consent_helper": "Click and drag your mouse or finger across the box below to sign",
    "phone_number_unreachable_label": "Landline?",
    "select": "Select",
    "test_surveys": "Appointment questions",
    "edit": "edit",
    "continue_to_registration": "Continue to registration",
    "accounts": {
      "already_have_an_account": "Already have an account?",
      "log_in": "Login",
      "sign_up": "Sign up",
      "sign_up_description": "Please enter your information to create your account and save time on future registrations.",
      "log_in_description": "Please enter your e-mail and password, or sign in through Google or Outlook below",
      "sign_in_with_label": "Sign in with {{provider}}",
      "password_must_match": "Passwords must match",
      "password_uppercase": "Your password must contain at least (%s) uppercase letter.",
      "password_lowercase": "Your password must contain at least (%s) lowercase letter.",
      "password_number": "Your password must contain at least (%s) number.",
      "password_special": "Your password must contain at least (%s) special characters."
    },
    "password": "Password",
    "password_confirmation": "Password confirmation",
    "consent_for": "Consent for {{name}}",
    "book_one_time_appointment": "Book a one-time appointment",
    "duplicate_users": {
      "exists": "You are already registered",
      "overlapping_email_and_phone_p1": "We sent a message to your phone number and email address.",
      "p2": "Using your message, you can change your appointment or enter test results.",
      "overlapping_phone_and_email_p3": "Please check your email or phone for details.",
      "overlapping_email_p3": "Please check your email for details.",
      "overlapping_phone_p3": "Please check your phone for details.",
      "overlapping_phone_p1": "We sent a message to your phone number.",
      "overlapping_email_p1": "We sent a message to your email address.",
      "p4": "If you think this is an error, please contact us at support@primary.health",
      "overlapping_email_and_phone_p3": "Please check your email or phone for details."
    },
    "duplicate_waitlist": {
      "exists": "You are already waitlisted",
      "overlapping_email_and_phone_p1": "We sent another confirmation to your phone number and email address",
      "p2": "Using your confirmation, you can provide additional information or remove yourself from the waitlist.",
      "overlapping_phone_and_email_p3": "Please check your email or phone for details",
      "overlapping_email_p3": "Please check your email for details",
      "overlapping_phone_p3": "Please check your phone for details",
      "overlapping_email_p1": "We sent another confirmation to your email."
    },
    "insist_guardian_consent": "I am providing consent on behalf of",
    "confirmation_section": {
      "title": "Review and confirm",
      "p1": "Please review your information before completing the registration.",
      "edit_information": "Go back and edit"
    },
    "iemodal": {
      "title": "Unsupported browser",
      "body": "Registration is currently possible on these browsers"
    },
    "show_other_locations": "Click to show other locations",
    "non_us_address": "This is an international address",
    "test_group_user_survey": "Participant Screening",
    "self_consent": "I am consenting for myself",
    "address_country": "Country",
    "appointment": "Appointment",
    "employee_id": "Employee ID",
    "appointment_time": "Appointment time",
    "appointment_location": "Location",
    "phone_or_email": "Phone number or email",
    "no_self_consent": "I decline consent for myself",
    "no_insist_guardian_consent": "I decline consent on behalf of {{full_name}}",
    "additional_consents_helpertext": "Additional consents may be requested later",
    "minimum_age_with_months": "The minimum age for participation is {{year}} years and {{month}} months old.",
    "assistive_technology": "Assistive Technology",
    "covid_vaccine_y_n": "Would you like a COVID-19 vaccination with this booking?",
    "received_covid_vaccine_y_n": "Have you received a COVID-19 vaccine before?",
    "covid_vaccinations": "Covid-19 Vaccinations",
    "select_vaccine_dose": "Please select the vaccine dose you would like to receive",
    "demographic_info": "Demographic info",
    "additional_info": "Additional info",
    "self_described_race": "Self Described Race",
    "verify_contact_information": "Verification",
    "verify_contact": {
      "we_sent_you_a_code": "Please enter the code we sent to {{contact}}",
      "confirmation_code": "Confirmation code",
      "code_resent": {
        "email": "Another code was sent to your email",
        "phone_number": "Another code was sent to your phone"
      },
      "did_not_receive_code": "Didn't get the confirmation code?",
      "verify_by": {
        "email": "Verify by email instead",
        "phone_number": "Verify by phone number instead"
      }
    },
    "preferred_method_of_communication": {
      "question": "Preferred method of communication",
      "helper_text": "We will contact you with appointment updates",
      "phone_number": "Phone (SMS messaging only and data rates may apply)",
      "landline": "We cannot support landlines at this time. Please provide an email for important communications.",
      "verification_method": "How do you want to receive your authentication code?",
      "verification_helper_text": "You will be sent a code to verify your identity before logging in."
    },
    "skip_for_now": "Skip for now",
    "default_information": {
      "title": "Okay, we'll use this address",
      "title_v2": "Okay, we'll use this contact information"
    },
    "middle_name_label": "Middle name",
    "confirm_appointment": "Confirm appointment",
    "dob": "DOB",
    "different_appointment": "Different appointment",
    "select_appointment_time": "Select appointment time",
    "decline_address": "I decline to provide an address. Please use the program's address instead.",
    "patient_information": "Patient information",
    "scan_license": "Scan driver's license",
    "how_to_contact": "How should we contact you?",
    "decline_email_or_phone": "I decline to provide an email or phone number. I authorize program administrators to receive my results.",
    "medical_screening": "Medical screening",
    "show_service_at_this_location": "Show service at this location",
    "verbal_consent_provided": "In accordance with the Americans with Disabilities Act, participant or their legally authorized decision maker provided verbal consent. Written consent shall be obtained from participant or their legally authorized decision maker within 24 hours.",
    "unexpired_written_consent_present": "Testing site has unexpired written consent on file.",
    "select_to_proceed": "Please select an option above to proceed.",
    "guardians_information": "Guardians information",
    "registrars_information": "Registrar's information",
    "optional": "optional",
    "vfc_eligibility": "Vaccine for Children (VFC) eligibility",
    "vfc_eligibility_subtext": "Your child may be able to get no-cost vaccines through CDC’s Vaccines for Children (VFC) Program at healthcare providers offices, pharmacies and health clinics that are enrolled in the VFC Program."
  },
  "gender_key": {
    "male": "Male",
    "female": "Female",
    "cisgender": "Cisgender",
    "non_binary": "Genderqueer or non-binary",
    "other": "Other",
    "prefer_to_self_describe": "Indentity not listed",
    "prefer_not_to_disclose": "Decline to answer",
    "transgender_male": "Trans male/ transman",
    "transgender_female": "Trans female/ transwoman",
    "unknown": "Unknown",
    "non_binary_only": "Non-binary",
    "intersex": "Intersex",
    "transgender": "Transgender",
    "gender_fluid": "Gender fluid",
    "not_applicable": "Not applicable"
  },
  "ethnicity": {
    "american_indian_alaska_native": "American Indian or Alaska Native",
    "american_indian_central_america": "American Indian from South or Central America",
    "asian": "Asian",
    "black": "Black or African American",
    "latinx": "Latino, Latinx or Hispanic",
    "middle_eastern_north_african": "Middle Eastern or North African",
    "native_hawaiian_pacific_islander": "Native Hawaiian or Pacific Islander",
    "white_european": "White or Caucasian",
    "unknown": "Unknown",
    "prefer_not_to_disclose": "Prefer not to disclose",
    "asian_indian": "Asian Indian",
    "filipino": "Filipino",
    "japanese": "Japanese",
    "korean": "Korean",
    "vietnamese": "Vietnamese",
    "other_asian": "Other Asian",
    "native_hawaiian": "Native Hawaiian",
    "guamanian_or_chamorro": "Guamanian or Chamorro",
    "samoan": "Samoan",
    "other_pacific_islander": "Other Pacific Islander",
    "chinese": "Chinese",
    "help": "Race refers to a set of physical characteristics used to differentiate between groups of people. Race may or may not align with nationality, or the country in which you have citizenship.",
    "subtitle": "Regulations require that we collect all of the following information.",
    "laotian": "Laotian",
    "cambodian": "Cambodian",
    "other": "Other",
    "bangladeshi": "Bangladeshi",
    "hmong": "Hmong",
    "indonesian": "Indonesian",
    "malaysian": "Malaysian",
    "pakistani": "Pakistani",
    "sri_lankan": "Sri Lankan",
    "thai": "Thai",
    "taiwanese": "Taiwanese",
    "fijian": "Fijian",
    "guamanian": "Guamanian",
    "tongan": "Tongan"
  },
  "languages": {
    "en": "English",
    "fr": "Français",
    "es": "Español",
    "so": "Soomaali",
    "hmn": "Hmong",
    "asl": "American Sign Language (ASL)",
    "kar": "Karen",
    "am": "Amharic",
    "ru": "русский",
    "om": "Oromo"
  },
  "symptoms": {
    "fever": "Fever or chills",
    "cough": "Cough",
    "muscle_aches": "Muscle aches",
    "severe_fatigue": "Severe fatigue (more than usual)",
    "trouble_breathing": "Trouble breathing",
    "diarrhea": "Diarrhea",
    "loss_of_smell": "Loss of smell",
    "loss_of_taste": "Loss of taste",
    "shortness_of_breath": "Shortness of breath or difficulty breathing",
    "headache": "Headache",
    "sore_throat": "Sore throat",
    "congestion": "Congestion or runny nose",
    "nausea": "Nausea or vomiting",
    "close_contact": "Close contact with an infected person*",
    "helper_text": {
      "close_contact": "*Have you had close contact (within 6 feet for at least 15 minutes) with someone who has confirmed COVID-19?"
    },
    "suspected_exposure": "Suspected exposure",
    "none": "No Symptoms"
  },
  "instructions": {
    "title": "Your enrollment is complete. Please review the information and next steps below carefully.",
    "p1": "This page contains information about your appointment as well as your appointment barcode.",
    "appointment_scheduled": "Your appointment is scheduled",
    "verify_info": {
      "title": "Verify your information",
      "p1": "It is important to validate your contact information so you can access your patient portal quickly.",
      "p2": "A confirmation has been sent to your email. Please click the link provided.",
      "p3": "If you need to change your contact information, please press the help button."
    },
    "verified": "Verified",
    "not_verified": "Not verified",
    "resend_text": "Haven't received the link?",
    "resend": "Resend",
    "skip": "Skip validation & show confirmation",
    "name": "Name",
    "appointment_details": "Appointment",
    "date_and_time": "Date and time",
    "add_to_calendar": "Add to calendar",
    "instructions": "General instructions",
    "successfully_flash": "Successfully created appointment!",
    "success_flash_email": "Successfully verified email",
    "success_flash_phone": "Successfully verified phone number",
    "mail_order": "You will receive your test-kit in the mail within the week.",
    "at_home": "A medical provider will come to your home around this time",
    "at_home_instructions": "At home instructions",
    "mail_order_instructions": "Mail order instructions",
    "request_additional_appointment": "Book follow-up appointment",
    "book_additional_appointment": "Book another appointment",
    "confirmation_code": "Confirmation code",
    "completed": "Completed",
    "appointment_barcode": "Appointment barcode",
    "dependent_links": "Household appointment links",
    "on_demand_title": "Your enrollment is complete. Please review the information and next steps below carefully.",
    "save_link": "Save this link",
    "verify_phone": "Verify your phone number",
    "verify_phone_description": "It is important to validate your contact information so you can access your records quickly. A verification text message has been sent to your number.",
    "verify_email_description": "It is important to validate your contact information so you can access your records quickly. A verification email has been sent to your email address.",
    "information": "Registration information",
    "follow_up_appointment": "Follow up appointment",
    "get_directions": "Directions",
    "cancel": "Cancel appointment",
    "reschedule_appointment": "Reschedule appointment",
    "reschedule_linked_appointments": "Reschedule Appointments",
    "no_slots": "No more times available",
    "check_results": "Check patient portal",
    "follow_up_modal_header": "Please schedule your 2nd dose",
    "are_you_sure_you_want_to_cancel": "Are you sure you want to cancel this appointment?",
    "please_choose_cancellation_reason": "Please choose a cancellation reason below",
    "additional_details": "Please add any additional details below",
    "errors": {
      "missing_cancellation_reason": "Please choose a cancelation reason"
    },
    "verify_email": "Verify your email",
    "redcap_url": "Click to complete your survey",
    "verify_contact_information": "Verify your contact information",
    "please_also": "Please also",
    "new_title": "Confirmation for {{name}}",
    "contact_method": "Contact method",
    "next_steps": {
      "title": "Next steps",
      "p1": "You have a test kit to activate. When you’re ready to take your test, follow the link sent to your email to return to this page and activate your kit below.",
      "administered": "We will contact you when your kit reaches the lab and when your results are ready. Results typically take 3-5 days after they reach the lab. You can view results from your [Medical history]({{link}}) page when they are ready.",
      "resulted": "Results are available in your [Medical history]({{link}}) page.",
      "shipping_information": "Shipping information"
    },
    "save_this_page": {
      "title": "Save this page",
      "p1": "Use this page to report a self test or view results via the medical history.",
      "p2": "To access this page in the future click on the link in your confirmation email or SMS text.",
      "p3": "You can save this page by bookmarking it, adding it to the home screen or copying the link to a safe place."
    },
    "show_my_barcode": "Show my barcode",
    "my_account": "My account",
    "register_another": "Register another participant",
    "update_vaccine": "Update vaccine information",
    "medical_history": "Medical history",
    "upcoming_appointments": "Upcoming appointments",
    "reschedule": "Reschedule",
    "resend_confirmation": "Resend confirmation",
    "appointment_details_v2": "Appointment details",
    "confirm_cancellation": "Confirm cancellation",
    "confirm_cancellation_question": "Are you sure you want to cancel this appointment?",
    "select_new_appointment_time_below": "Select a new appointment time below. If you need to modify the services or appointment location, please cancel this appointment and schedule a new one.",
    "no_take_me_back": "No, take me back",
    "yes_cancel_appointment": "Yes, cancel appointment",
    "update_appointment": "Update appointment",
    "select_new_appointment_time": "Select new appointment time",
    "clinic": "Clinic",
    "services": "Services",
    "appointment_missed": "Appointment missed",
    "appointment_canceled": "Appointment canceled"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Invalid access code"
    },
    "landing": {
      "p1": "To view your results, please enter:",
      "access_code_label": "Access code",
      "submit_button": "Submit",
      "recent_results": "Your recent results"
    },
    "view_results": {
      "headline": "Test results for {{name}}",
      "reregister": "Re-register",
      "status": {
        "likely_positive": "Results indeterminate",
        "test_not_performed": "Retest needed",
        "results_ready": "Results ready",
        "processing": "Processing",
        "test_status": "Test status",
        "test_result": "Test result",
        "administered": "Administered",
        "results_pending": "Results pending",
        "test_results": "Test results"
      },
      "at": "at {{address}}"
    },
    "result_label": "Result",
    "result": {
      "flu_a": {
        "result_label": "Flu A result"
      },
      "flu_b": {
        "result_label": "Flu B result"
      },
      "covid19": {
        "result_label": "COVID-19 result"
      },
      "covid": {
        "result_label": "COVID-19 result"
      },
      "sars": {
        "result_label": "SARS result"
      },
      "control": {
        "result_label": "Control"
      },
      "rsv": {
        "result_label": "RSV result"
      },
      "result": {
        "result_label": "Result"
      },
      "hba1c": {
        "normal": "Normal",
        "warning": "Prediabetes",
        "danger": "Diabetes"
      },
      "lead_venous": {
        "danger": "Abnormal",
        "normal": "Normal"
      },
      "tc": {
        "result_label": "TC Result"
      },
      "hdl": {
        "result_label": "HDL Result"
      },
      "trg": {
        "result_label": "TRG Result"
      },
      "ldl": {
        "result_label": "LDL Result"
      },
      "non_hdl": {
        "result_label": "Non-HDL Result"
      },
      "tc_hdl_ratio": {
        "result_label": "TC/HDL Ratio"
      },
      "glu": {
        "result_label": "GLU Result"
      },
      "alere_cholestech_ldx": {
        "danger": "At Risk",
        "warning": "Abnormal",
        "normal": "Normal"
      },
      "lead": {
        "result_label": "Lead"
      },
      "zinc": {
        "result_label": "Zinc"
      },
      "lead_standard_profile": {
        "danger": "Abnormal",
        "normal": "Normal"
      },
      "creatinine": {
        "danger": "Abnormal",
        "normal": "Normal"
      },
      "igg": {
        "result_label": "IgG result"
      },
      "igm": {
        "result_label": "IgM result"
      },
      "blood_glucose": {
        "warning": "Low",
        "normal": "Normal",
        "danger": "Diabetes",
        "prediabetes": "Prediabetes",
        "hypoglycemia": "Hypoglycemia",
        "medical_emergency": "Medical emergency",
        "inconclusive_1": "Inconclusive 1",
        "inconclusive_2": "Inconclusive 2",
        "possible_diabetes": "Possible diabetes"
      },
      "blood_glucose_fasted": {
        "normal": "Normal",
        "warning": "Elevated",
        "danger": "High",
        "hypoglycemia": "Hypoglycemia",
        "prediabetes": "Prediabetes",
        "diabetes": "Diabetes"
      },
      "total_cholesterol_fasted": {
        "normal": "Normal",
        "elevated": "Elevated",
        "high": "High",
        "low": "Low"
      },
      "total_cholesterol_unfasted": {
        "normal": "Normal",
        "elevated": "Elevated",
        "high": "High"
      },
      "a1c_now": {
        "normal": "Normal",
        "warning": "Prediabetes",
        "danger": "Diabetes"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      }
    },
    "documents": "Documents",
    "self_administered": "Self-administered test picked up at {{location}}",
    "patient": "Patient",
    "medical_history": "Medical History",
    "overview_title": "Select a test or service to view more details and any additional action items that are needed. Note that some test results may not yet be available or will only become available after speaking with a provider.",
    "insurance_information": "Fill out insurance information",
    "contact_support": "If you need assistance, or to change your contact information, please contact support.",
    "show": "Show",
    "hide": "Hide",
    "lab_report": "Lab report",
    "contact_provider": {
      "header": "You have positive results",
      "description": "Do you want to talk to a provider to discuss your results and treatment?",
      "yes_option_text": "Yes, I want to talk to a provider",
      "no_option_text": "No, I have read and understand my results, and do not want to talk to a provider",
      "confirm_phone_number_header": "Confirm your phone number",
      "confirm_phone_number_description": "Please confirm the best phone number for your consultation.",
      "confirm_button_text": "Confirm",
      "consultation_confirmed_header": "Consultation confirmed",
      "consultation_confirmed_description": "A provider will call you at {{phone_number}} within 2-3 business days.",
      "acknowledgement_option_helper_text": "Something that explains the importance of treatment and lets the user know how to schedule a call if they change their mind.",
      "acknowledgement_confirmed_header": "Acknowledgement confirmed",
      "acknowledgement_confirmed_description": "Something about the importance of getting treatment with linked resources. Reminder that the consultation is completely free and the provider can prescribe or whatever else to help them resolve the infection.",
      "acknowledgement_confirmed_change_mind_text": "If you change your mind, simply click “I’d like a consultation” below.",
      "request_consultation_button_text": "I’d like a consultation"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Your address does not match the eligibility requirements for the sites in this program."
    }
  },
  "member": {
    "medical_history": "History",
    "view_instructions": "View confirmation",
    "next_appointment": "Next appointment",
    "over_18": "I certify that I am 18 or older",
    "choose_test_configurations_title": "Please select the service(s) you'd like for this appointment",
    "member_taken_error": "User already has an account",
    "choose_test_configurations_subtitle": "Service(s) will be applied to all household members",
    "service": "Service",
    "group_or_location": "Group/Location",
    "details": "Details",
    "see_all": "See all",
    "no_history": "There is no history available at this time.",
    "medical_history_title_with_name": "{{name}}'s history",
    "no_dashboard": "There is currently nothing available",
    "product": "Product",
    "price": "Price",
    "quantity": "Quantity",
    "total_services_selected": "Total services selected",
    "total_price": "Total price"
  },
  "or": "or",
  "account": {
    "errors": {
      "must_be_13": "Must be 13 years old to register for an account"
    }
  },
  "self_administration": {
    "title": "Self-administration",
    "self_administer_action": "Self-administer",
    "skip_to_link": "Skip to Self-administration",
    "select_person": "Select a person to check out",
    "adult": "adult",
    "child": "child",
    "checked_out": "CHECKED OUT",
    "go_back": "Go back",
    "switch_to_scanner": "Switch to scanner",
    "enter_barcode": "Enter barcode",
    "scan_barcode": "Scan barcode",
    "cancel": "Cancel",
    "barcode_for": "Barcode for",
    "enter_barcode_manually": "Enter barcode manually",
    "instructions": "Scan or enter the barcode located on your test tube below.",
    "regex_prefix": "Barcode must consist of",
    "all_completed": "Completed: All household members have completed this step",
    "errors": {
      "no_appointment": "Problem locating appointment",
      "barcode_in_use": "This barcode already been used previously. If you didn’t previously self-administer with this kit please speak with staff at the site that gave you the kit and ask for a new kit. Otherwise, click the help button below.",
      "test_error_general": "Problem creating test",
      "different_barcodes": "The barcodes you entered do not match."
    },
    "confirm_barcode_input": "Enter barcode again to confirm",
    "click_to_self_test": "Click here to self-test"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Waitlist",
      "unavailable": "No appointments available"
    },
    "labels": {
      "address": "Address",
      "next_appointment": "Earliest available appointment",
      "services": "Services available"
    }
  },
  "waiting_room": {
    "header": "You are in the waiting room; please wait while we connect you with the scheduling system.",
    "p1": "Please be patient. We will end this pandemic together.",
    "signature": "Sincerely",
    "p2": "We know the COVID-19 experience can be frustrating. Please wait here while we work as fast as we can to get you into the site to book your appointment."
  },
  "users": {
    "send_appointment_confirmation_message": "Hello {{full_name}}. This is a reminder for your appointment at {{name}}. Your appointment is on {{date}} at {{time}} at {{address}}.",
    "send_registration_link": "Hello, {{full_name}}. Follow this link to register for your {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "First dose",
    "previous_appointment": "Past appointment",
    "booked_appointment": "Your appointment",
    "second_dose": "Second dose",
    "choose_second_location": "Choose a second appointment location"
  },
  "cancellation_types": {
    "cant_get_to_location": "I have no way to get to this location",
    "timing_conflict": "I had a timing conflict",
    "became_ill_with_covid19": "I became ill with COVID-19",
    "received_service_elsewhere": "I received this service elsewhere",
    "other": "Other",
    "duplicate_appointment": "This was a duplicate appointment"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Type name instead",
      "type_name": "Type signature instead"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Your access code is",
      "please_confirm_your_email": "Please confirm your email by clicking the button below",
      "hello": "Hello",
      "click_to_reschedule": "Click to show instructions/reschedule",
      "click_to_reschedule_on_demand": "Click to show instructions"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Your appointment at {{time}} at {{location}} has been canceled. If this is a mistake, please email support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Name of participant",
    "date": "Date",
    "signature": "Signature of participant",
    "and_or": "AND/OR",
    "guardian_name": "Name of parent/guardian",
    "guardian_signature": "Signature of parent/guardian"
  },
  "employer_testing": {
    "hello_name": "Hello {{name}}",
    "results": {
      "negative": "Negative",
      "positive": "Positive",
      "did_not_result": "Invalid",
      "unknown": "Unknown",
      "invalid": "Invalid",
      "pending": "Pending"
    },
    "code_reader": {
      "scan_your_code": "Scan the code on your test card",
      "find_in_top_right_hand_corner": "The scan code is unique to your test and can be found on the top right-hand corner of your test",
      "center_code": "Center the code here.",
      "already_used_error": "This test barcode has already been used. Please contact your test kit provider for support.",
      "click_to_scan": "Click to scan QR code",
      "scan_new_test_card": "Click here to scan a new test card.",
      "not_working": "Not working?",
      "try_again": "Click here to try again."
    },
    "continue": "Continue",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Please confirm the participant’s date of birth to continue",
      "contact_administrator": "If the displayed information is incorrect, please contact an administrator for assistance",
      "error": "The date of birth entered is incorrect — please try again or contact your site administrator to have this updated."
    },
    "result_entry": {
      "code_is_registered": "Your code is registered.",
      "take_the_test": "Take the test",
      "follow_the_instructions": "Follow the instructions that came with your test and start your 15 minute timer once you’d entered the nasal swab into the vial on the testing card.",
      "cant_find_instructions": "Can’t find the instructions?",
      "view_instructions": "View the Binax Now instructions",
      "start_timer": "START 15 MINUTE TIMER (OPTIONAL)",
      "submit_within_15": "Tip: Submit your results within 15 minutes",
      "enter_test_results": "Enter Test Results",
      "choose_a_result": "Choose the option that describes your testing card outcome",
      "submit": "Submit",
      "must_log_result_and_photo": "You must log a result and take a photo of your test in order to continue",
      "submit_within_15_v2": "Tip: Submit your results after the test has run for 15 minutes",
      "click_to_take_photo": "Click to take a photo",
      "results_may_be_invalid": "Test results may be invalid"
    },
    "summary": {
      "title": "Results summary",
      "negative_subtitle": "You don't have COVID-19",
      "negative_message": "We have sent a notification to your employer that you are cleared to come back to work onsite.",
      "positive_subtitle": "We are sorry, but you have COVID-19",
      "positive_message": "We have sent a notification to your employer that you have COVID-19.",
      "what_should_you_do": "What should you do:",
      "employer_name_resources": "Employer name resources:",
      "follow_cdc_guidelines": "Follow the CDC recommended guidelines"
    },
    "clarifying_results": {
      "title": "Clarifying Your results",
      "which_did_your_result_most_look_like": "Which did your result most look like?",
      "no_lines": "No lines seen",
      "blue_control_line_only": "Blue control line only",
      "pink_sample_line_only": "Pink sample line only",
      "blue_control_line_and_pink_sample_line": "Blue control line AND pink/purple sample line",
      "still_not_sure": "Still not sure",
      "submit_results": "Submit Results",
      "thank_you": "Thank you!",
      "clarification_received_message": "Your result clarification has been received.",
      "only_pink_line": "Only pink line",
      "no_lines_v2": "No Lines"
    },
    "return_to_confirmation": "Return to confirmation page"
  },
  "preferred_language": {
    "title": "Preferred language",
    "subtitle": "Which language do you prefer?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Initial Vaccine Series",
    "one_dose": "Book an appointment for a specific dose. Particularly, this is my",
    "title": "Dose selection",
    "first_dose": "First dose only",
    "second_dose": "Second dose only",
    "single_dose_title": "Single dose",
    "additional": "Additional dose for immunocompromised",
    "booster": "Booster dose",
    "supplemental": "Supplemental dose",
    "third_dose": "Third dose only",
    "no_vaccine": "None of the above"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "should have format {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "should have format {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "test not found"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "user exists and not allowed to updated it"
            },
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "location": {
              "unknown": "location does not exist"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "status is invalid, pick from the following acceptable values: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "insufficient privileges to set the role"
            },
            "user_id": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "invalid JSON"
            },
            "user_id": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "email": {
              "duplicated": "is already used"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "and address are already being used in another existing Location"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Hispanic or Latino/a",
    "not_hispanic": "Not Hispanic or Latino/a",
    "hispanic_expanded": {
      "other": "Another Hispanic, Latino/a or Spanish origin",
      "mexican": "Mexican, Mexican American, Chicano/a",
      "puerto_rican": "Puerto Rican",
      "cuban": "Cuban"
    },
    "subtitle": "Regulations require that we collect the following information.",
    "help": "Ethnicity refers to a set of common traits such as language, cultural customs, religion, and other characteristics used to differentiate groups of people. It may or may not align with a racial identity as well.",
    "unknown": "Unknown",
    "title": "Ethnicity"
  },
  "sex_at_birth": {
    "question": "Sex",
    "female": "Female",
    "male": "Male",
    "help": "Sex refers to what is on your birth certificate.",
    "non_binary": "Non-binary",
    "subtitle": "Regulations require that we collect all of the following information.",
    "decline": "Decline to answer",
    "unknown": "Unknown"
  },
  "gender": {
    "help": "Gender identity refers to how you personally identify. It may or may not align with the sex you were assigned at birth.",
    "subtitle": "If you would like to add information about your gender identity, please do so."
  },
  "sexual_orientation": {
    "title": "Sexual orientation",
    "subtitle": "If you would like to add information about your sexual orientation, please do so.",
    "gay": "Gay, lesbian, or homosexual",
    "heterosexual": "Heterosexual or straight",
    "bisexual": "Bisexual",
    "questioning": "Questioning/unsure/don't know",
    "prefer_not_to_disclose": "Choose not to disclose",
    "unknown": "Unknown",
    "orientation_not_listed": "Orientation not listed",
    "not_applicable": "Not applicable",
    "pansexual": "Pansexual",
    "queer": "Queer"
  },
  "pronouns": {
    "title": "Preferred pronouns",
    "he": "He/him",
    "she": "She/her",
    "they": "They/them",
    "choose_not_to_disclose": "Choose not to disclose",
    "prefer_to_self_describe": "Prefer to self-describe"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Driver license or state ID number",
    "driver_license": "Driver license",
    "use_ssn_instead": "Use SSN instead",
    "social_security_number": "Social security number",
    "state": "State",
    "upload_front_of_driver_license": "Upload the front of your driver's license or state ID number",
    "choose_file": "Choose file",
    "no_file_chosen": "No file chosen",
    "no_identification": "I don't have identification",
    "insurance_card_has_a_back": "My insurance card has a back",
    "upload_front_of_insurance_card": "Upload the front of your insurance card.",
    "front_of_insurance_card_uploaded": "The front of your insurance card is uploaded.",
    "insurance_card": "Insurance card",
    "insurance_card_back": "Insurance card back",
    "back_of_insurance_card_uploaded": "The back of your insurance card is uploaded."
  },
  "quidel": {
    "certify_text": "I certify that I am only logging the results for this test once.",
    "entering_results": "Entering your results",
    "review_instructions_1": "Carefully review the instructions",
    "review_instructions_2": "You can review the instructions that came with your test, read a step-by-step guide, or watch an instructional video below:",
    "read_instructions": "Read the instructions",
    "watch_video": "Watch the video",
    "timer": "Begin the timer after stirring the swab in the tube. The timer will stop after 1 minute (for you to remove the swab from the tube). Please note: You must resume the timer to begin the 10 minutes for your test to result.",
    "start_timer": "Start the timer (optional)",
    "restart_timer": "Stop and restart",
    "resume": "Resume",
    "one_minute_countdown": "1 minute has passed! Remove your swab from the tube and resume the timer.",
    "take_photo": "Take a photo of your test strip",
    "photo_warning": "Providing a photo is necessary to continue.",
    "enter_results": "Enter test results",
    "choose_result": "Choose the option that best reflects your test strip.",
    "positive": "Positive",
    "negative": "Negative",
    "unknown": "Unknown",
    "clarify": "Clarifying your results",
    "strip_question": "Which did your test strip look most like?",
    "no_lines": "No lines",
    "pink_line": "Only pink line",
    "not_sure": "Still not sure"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Result photo",
    "retake_photo": "Retake Photo",
    "capture_photo": "Capture Photo",
    "confirm_information_correct": "I confirm that the information above is correct.",
    "submit": "Submit",
    "title": "Enter results for {{name}}",
    "subtitle": "You do not need to download or use the iHealth COVID-19 app.",
    "instruction_title": "View iHealth instructions",
    "instruction_guide": "Read instructions",
    "video_guide": "Watch video",
    "add_photo": "Add photo",
    "confirm_result": "Confirm result",
    "result_warning": "Select result in order to submit",
    "confirm_warning": "Confirm result in order to submit",
    "administered_at_label": "When did you take this test?",
    "instruction_subtitle": "View the instructions that came with your test or review the instructions here."
  },
  "public_test_groups": {
    "title": "COVID-19 At-Home Test Kit Activation",
    "appointment_recoveries_title": "Returning?",
    "appointment_recoveries_button_text": "Find my link",
    "search_test_group_title": "First time?",
    "search_test_group_button_text": "Register now",
    "title_2": "Simple registration and reporting",
    "step_one": "Account registration",
    "step_two": "Collect saliva sample to ship back",
    "step_three": "See results online",
    "title_3": "Register to activate your kit",
    "population_title": "registration",
    "population_button": "Activate",
    "faq_subtitle": "Learn more",
    "faq_title": "Frequently asked questions",
    "faq_1": "How do I return my test kit?",
    "faq_1_footer": "View Saliva Direct Funnel instructions",
    "faq_2": "I am registered already but I forgot my account.",
    "faq_3": "How do I activate my kit?",
    "faq_2_subtitle": "As a returning user, you can confirm your report by using the '**Find my link**' button above.",
    "faq_3_subtitle": "Please go through the Primary.Health account process using the options below:",
    "footer_text_1": "This product has not been FDA cleared or approved, but has been authorized for emergency use by FDA under an EUA;",
    "footer_text_2": "This product has been authorized only for the collection and maintenance of saliva specimens as an aid in the detection of nucleic acid from SARS-CoV-2, not for any other viruses or pathogens;",
    "footer_text_3": "The emergency use of this product is only authorized for the duration of the declaration that circumstances exist justifying the authorization of emergency use of medical devices under Section 564(b)(1) of the Federal Food, Drug and Cos- metic Act, 21 U.S.C. § 360bbb-3(b)(1), unless the declaration is terminated or authorization is revoked sooner.",
    "description": "For your convenience, you can activate your test kit using the buttons below:",
    "subtitle": "First time on Primary?",
    "subtitle_1": "About your COVID-19 test kits",
    "faq_1_1": "Place the closed sample tube into the biospecimen bag",
    "faq_1_2": "Place the biospecimen bag into the SalivaDirect-labeled box it came in. Place the SalivaDirect-labeled box into the FedEx UN 3373 Pak.",
    "faq_1_3": "Remove the adhesive liner of the FedEx UN 3373 Pak and press down firmly to seal Pak tightly.",
    "faq_1_4": "Please drop off your sample on the same day you collect it and before the last pickup time. Do not deliver your sample to a drop box on the weekend.",
    "faq_1_5": "Visit the FedEx website at **[fedex.com/labreturns](https://www.fedex.com/labreturns)** to view drop box locations and pick up schedules.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "Report a result",
    "report_result_subtitle": "If your organization offers self-testing, and you are ready to report a result, please click the button below.",
    "need_help": "Need help?",
    "assistance": "If you need assistance, or to change your contact information, please contact the Support Center.",
    "contact_support": "Contact support",
    "save_link": "Save this link to report results later.",
    "send_via_email": "Send link via email",
    "send_via_text": "Send link via SMS text",
    "copy_link": "Copy link",
    "resend_email": "Resend email verification",
    "phone_verified": "Phone verified",
    "add_vaccination_status": "Add/Update COVID-19 Vaccination Status",
    "terms_of_service": "Terms of service",
    "verify_contact_information": "Verify your contact information so that you can receive results and communications. If you need to change your contact information, please contact our Support Center.",
    "resend_text": "Resend SMS text verification",
    "loading": "Loading",
    "add_to_account": "Add to account",
    "hi": "Hi",
    "email_verified": "Email verified",
    "go_to_my_account": "Go to my account",
    "activate_kit": "Activate a test kit",
    "report_custom_result": "Report a result: {{test}}",
    "activate_custom_test": "Activate a test: {{test}}",
    "update_vaccination_status": "Update vaccination status",
    "order_a_test_kit": "Order a test kit"
  },
  "enter_information": {
    "contact_info": "Enter your contact information",
    "label": "Phone or email",
    "invalid": "This is not a valid email or phone number.",
    "send": "We’ll send a code to your phone or email",
    "find_link": "Registered? Find your link",
    "not_registered": "Not Registered?",
    "search_by_keyword": "Search by name, keyword, city, zip, or organization code!",
    "legal": "By entering your phone number or email, you agree to Primary Health’s **[Terms of Service](https://primary.health/terms-of-service/)** and **[Privacy Policy](https://primary.health/privacy-policy/)**.",
    "subtitle": "We will send you a text or email to help you find your records or link to report a test result!",
    "search": "Search",
    "register_here": "Register here"
  },
  "contact_support": {
    "invalid": "Invalid information",
    "invalid_explanation": "You recently requested a sign-on link to Primary.Health. However,",
    "never_registered": "Never registered before? Please reach out to your testing provider for a registration link, or find your site below.",
    "get_help": "You can get help matching your record to your contact information with",
    "get_help_calling": "or by calling",
    "invalid_explanation_bold": "the information you provided was invalid.",
    "get_help_label": "Get Help",
    "mobile": {
      "invalid_explanation": "No users were found that could be added to your account.",
      "get_help": "If you have any additional questions, please contact",
      "reason": "Not all Primary.Health users are eligible for our new Accounts feature. Once you are eligible, you will receive an invitation from Primary.Health or from your group administrator."
    },
    "zen_name": "Contact name",
    "ticket_type": "What is this regarding",
    "appointment_access_code": "Confirmation Code (if known)",
    "zen_desc": "Message",
    "contact_us": "Contact us",
    "need_assistance": "If you need assistance, or to change your contact information, please contact support."
  },
  "show_appointments": {
    "welcome_not_logged_in": "Finding a site link",
    "welcome_logged_in": "Select your Group",
    "welcome_subtitle_1_logged_in": "To finish adding a member, select the user and group. Members are assigned to one account.",
    "welcome_subtitle_1_not_logged_in": "Select who needs to locate their site or add a new member. Member not found?",
    "welcome_subtitle_2_logged_in": "If you are not seeing your member listed above, you can",
    "welcome_subtitle_3_logged_in": "try a different contact method",
    "welcome_subtitle_4_logged_in": "or contact us at",
    "added_to_account": "Added to account",
    "error": "This participant may already have an account",
    "welcome_subtitle_2_not_logged_in": "Update search.",
    "welcome": "Find your profile",
    "welcome_subtitle_1": "Showing results below for",
    "welcome_subtitle_2": "Not seeing the name below?",
    "welcome_subtitle_3": "Update your search"
  },
  "enter_code": {
    "code": "What’s the code?",
    "enter_code": "Enter the code sent to",
    "incorrect_code": "You have entered an incorrect code.",
    "verify": "Verify account",
    "verified": "Verified",
    "verify_account_information": "Verify your account"
  },
  "general_self_checkout": {
    "choose_test": "What test did you take?",
    "photo_required": "Result photo required",
    "find_your_test": "Find your test"
  },
  "login": {
    "create_account": "Create an account",
    "create_account_help": "Creating an account is not the same as registering for testing or vaccinations. Once you've registered",
    "create_account_help_schedule": "Schedule appointments",
    "create_account_help_record": "Record at-home tests",
    "create_account_help_match": "Manage vaccination & testing records.",
    "continue": "Continue as guest",
    "already": "Already have an account? Click",
    "login": "to login.",
    "or_sign_in": "Or sign in with:",
    "no_account": "Don't have an account? Click",
    "signup": "to signup.",
    "here": "here",
    "email_address": "Email address:",
    "password": "Password:",
    "complete_registration": "Complete Registration",
    "last_covid_test": "Last COVID-19 test result",
    "no_record": "No record",
    "viewing_information": "Viewing {{first_name}}'s information",
    "download": "Download",
    "not_registered": "It looks like you’re not registered to any groups",
    "please_follow_link": "Please follow the invite link from your email to schedule and access your information with Primary.Health.",
    "log_results": "Log my test results",
    "book_appointment": "Book appointment",
    "no_new_appointments": "Currently not accepting new appointments",
    "upload_vaccinations": "Upload vaccinations",
    "vaccination": "vaccination",
    "new_appointment": "New appointment",
    "log_otc_results": "Log results for an OTC test",
    "no_household_members": "You have no household members yet.",
    "no_upcoming": "No upcoming appointment",
    "update_vaccination": "Update vaccination",
    "upload_vaccination": "Upload vaccination",
    "title": "Welcome to Primary.Health",
    "sub_title": "Before registering, please create an account.",
    "p1": "Creating an account lets you:",
    "p2": "Schedule future appointments",
    "p3": "Record COVID-19 at-home tests",
    "p4": "Manage dependents vaccination & testing records",
    "p5": "Simple login process from my.primary.health",
    "no_vaccination_record": "No vaccination added yet",
    "vaccination_title_card": "Covid-19 vaccination status",
    "account_and_settings": "Account & settings",
    "delete_account": "Delete account",
    "language": "Language",
    "language_current": "Current language: {{language}}",
    "log_out": "Log out",
    "delete_account_title": "Are you sure you want to delete your account?",
    "new_encounter": "{{first_name}}'s new encounter",
    "new_encounter_subtitle": "Which group is this new encounter for?",
    "no_programs": "It looks like you're not enrolled in any programs",
    "edit_members": "Edit members",
    "edit_members_subtitle": "Select which dashboard you would like to go to or add another member to your account",
    "add_members": "Add member",
    "delete_member": "Delete {{full_name}}'s user account?",
    "delete_member_subtitle": "Removing the user will delete all the informations associated with the account.",
    "select_member": "Member selection",
    "edit_appointment": "Edit appointment",
    "route": {
      "me": "Dashboard",
      "history": "History",
      "programs": "Add a test",
      "contact_us": "Help",
      "choose_member": "Member",
      "settings": "Settings",
      "help": "Help"
    },
    "user_dashboard": "{{first_name}}'s dashboard",
    "view_full_results": "View in results portal",
    "required": "Required if you are using the Primary App.",
    "no_email": "I don't have an email address"
  },
  "vaccination_status": {
    "not_vaccinated": "Not vaccinated",
    "fully_vaccinated": "Fully vaccinated",
    "partially_vaccinated": "Partially vaccinated",
    "vaccination_records": "Vaccination records",
    "title": "What is your COVID-19 vaccination status?",
    "definition_title": "Vaccination status definitions",
    "definition_cdc": "CDC vaccination status definition",
    "definition_osha": "OSHA vaccination status definition",
    "definition": {
      "fully_vaccinated": "2 weeks after either a final dose in 2 dose series of Pfizer-BioNTech or Moderna, or 1 dose of Johnson & Johnson's Janssen vaccine.",
      "partially_vaccinated": "Received only 1 of 2 doses in a primary series, or less than 2 weeks after receiving final dose in 2 dose series or 1 dose of Johnson & Johnson's Janssen.",
      "not_vaccinated": "No COVID-19 vaccine administered.",
      "fully_vaccinated_with_one_booster": "Received a booster dose of Pfizer-BioNTech or Moderna vaccines after full series or 1 dose of Johnson & Johnson's Janssen vaccine.",
      "fully_vaccinated_with_two_boosters": "Received a second booster dose of Pfizer-BioNTech or Moderna vaccines after full series or dose of Johnson & Johnson's Janssen vaccine and booster dose."
    },
    "label": {
      "fully_vaccinated": "Fully vaccinated",
      "partially_vaccinated": "Partially vaccinated",
      "not_vaccinated": "Not vaccinated",
      "fully_vaccinated_with_one_booster": "Fully vaccinated + booster",
      "fully_vaccinated_with_two_boosters": "Fully vaccinated + 2 boosters",
      "prefer_not_to_answer": "Prefer not to answer"
    },
    "progress_bar_title": "COVID-19 vaccination history",
    "upload_record_card": "Upload your COVID-19 vaccination record card",
    "show_example": "Show example",
    "hide_example": "Hide example",
    "take_photo": "Click to take a photo",
    "or": "OR",
    "upload_image_or_pdf_instead": "Upload image/PDF instead",
    "select_doses_received": "Select all the COVID-19 vaccination doses you have received.",
    "first_dose": "First dose",
    "second_dose": "Second dose",
    "first_booster_dose": "First booster dose",
    "second_booster_dose": "Second booster dose",
    "additional_dose": "Additional dose",
    "first_dose_manufacturer": "Who was the COVID-19 manufacturer for your first dose?",
    "first_dose_date": "What was the date of your first dose?",
    "second_dose_manufacturer": "Who was the COVID-19 manufacturer for your second dose?",
    "second_dose_date": "What was the date of your second dose?",
    "first_booster_dose_manufacturer": "Who was the COVID-19 manufacturer for your first booster dose?",
    "first_booster_dose_date": "What was the date of your first booster dose?",
    "second_booster_dose_manufacturer": "Who was the COVID-19 manufacturer for your second booster dose?",
    "second_booster_dose_date": "What was the date of your second booster dose?",
    "additional_dose_manufacturer": "Who was the COVID-19 manufacturer for your additional dose?",
    "additional_dose_date": "What was the date of your additional dose?",
    "completed_one": "Completed",
    "completed_two": "Thank you for updating your vaccination status!",
    "progress_bar_complete": "Complete",
    "upload_image": "Upload image",
    "retake_photo": "Retake photo",
    "other": "Other",
    "remove_first_dose": "Remove first dose history",
    "remove_second_dose": "Remove second dose history",
    "remove_first_booster_dose": "Remove first booster dose history",
    "remove_second_booster_dose": "Remove second booster dose history",
    "remove_additional_dose": "Remove additional dose history",
    "exemption": "Do you have an exemption?",
    "exempt": "I am exempt",
    "not_exempt": "I am not exempt",
    "enter_exemption": "Enter exemption",
    "upload_exemption_documentation": "Upload documentation for your exemption",
    "remove_dose": "Remove dose",
    "continue": "Continue",
    "enter_credentials": "Please enter your credentials",
    "incorrect_credentials": "Incorrect credentials, please try again.",
    "add_photo": "Add photo"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Hello {{name}}, We noticed you have not finished scheduling your {{group_name}} appointment with {{org_name}}. To keep your appointment, you'll need to complete the payment step. Please click on this link to complete the process {{complete_url}}. If you would like to cancel your appointment, please click on this link {{cancel_url}}. Thank you"
    }
  },
  "otc": {
    "record_results_for_abbott": "Test & record your results for the BinaxNOW test",
    "record_results": "Test & record your results",
    "activate_a_kit": "Activate a kit",
    "activate_a_kit_for_test": "Activate a kit for the {{test}}",
    "todays_test_process": "Today's test process:",
    "date_of_birth_question": "What is {{first_name}}'s date of birth?",
    "confirm_dob_to_continue": "Please confirm the participant's date of birth to continue:",
    "incorrect_dob": "Incorrect date of birth",
    "file_too_large": "Please make sure the size of the uploaded file is smaller than {{size}}",
    "enter_valid_date_in_last_x_days": "Enter a valid date in last {{n}} days",
    "barcode_format_not_valid": "The barcode format is not valid",
    "complete_button": "Complete",
    "next_button": "Next",
    "back_button": "Back",
    "report_a_test": "Report a test:",
    "x_%_completed": "{{n}}% complete",
    "answer_survey": "Answer survey questions",
    "steps": {
      "answer_survey": "Answer survey",
      "review_instructions": "Review instructions",
      "scan_code": "Scan code",
      "enter_time": "Enter time",
      "enter_result": "Enter result",
      "take_photo": "Take photo",
      "confirm_result": "Confirm result",
      "selest_test_kit": "Choose test type",
      "enter_date": "Enter date",
      "enter_date_and_time": "Enter date and time"
    },
    "review_instructions": "Review the test instructions",
    "follow_instructions_or_below": "Follow the instructions that came with your test or review the instructions below.",
    "watch_video": "Watch video",
    "view_instructions": "View instructions",
    "follow_instructions": "Follow the instructions that came with your test.",
    "scan_code": "Scan test QR code",
    "qr_code_description": "The QR code is unique to your test and can be found on the top-right hand corner of your test",
    "show_example": "Show example",
    "hide_example": "Hide example",
    "barcode_scanned": "Barcode successfully scanned",
    "enter_barcode": "Enter barcode",
    "scan_qr_code": "Scan QR code",
    "which_test_did_you_take": "Which test did you take?",
    "when_did_you_take_test": "When did you take this test?",
    "what_day_did_you_take_test": "What day did you take this test?",
    "what_time_did_you_take_test": "What time did you take this test?",
    "time": {
      "today": "Today",
      "yesterday": "Yesterday",
      "two_days_ago": "2 days ago"
    },
    "enter_current_time": "Enter current time",
    "enter_result": "Enter test result",
    "choose_result_option": "Choose the option that describes your testing card outcome:",
    "clarify_results": "Clarify your results",
    "take_result_photo": "Take a photo of results",
    "take_result_photo_description": "Capture a photo of the completed results card to record your results.",
    "my_result": "My result",
    "switch_camera": "Switch camera",
    "confirm_result": "Confirm result",
    "name": "Name:",
    "date": "Date:",
    "result": "Result:",
    "results": "Results",
    "test_submitted": "Thank you, {{first_name}}! Your {{test}} has been successfully submitted.",
    "dob": "Date of birth:",
    "administered": "Administered:",
    "or": "OR",
    "upload": "Upload",
    "change_uploaded_file": "Change uploaded file",
    "take_photo": "Take a photo",
    "confirm_information": "Confirm information",
    "barcode": "Barcode:",
    "scan_test_barcode": "Scan test barcode",
    "barcode_description": "The barcode is unique to your test and can be found on your test",
    "enter_date_in_last_30_days": "Enter a date in the last 30 days and the approximate time the test was taken",
    "add_image": "Add image",
    "change_image": "Change image",
    "skip_barcode_reader": "Unable to scan the code? Click Next to report a test result anyway",
    "enter_date_and_time": "Enter date and time of test",
    "enter_date": "Enter date of test",
    "did_you_take_test_today": "Did you take the test today?",
    "record_results_for_generic": "Test & record your results for the {{name}} test",
    "choose_result_option_custom": "Choose the option that describes your {{test_name}} outcome:"
  },
  "yes": "Yes",
  "no": "No",
  "event_token": {
    "title": "Please enter the code below into the vending machine in order to dispense your test kit",
    "loading_text": "Loading, please check back in a minute"
  },
  "appointment_recovery": {
    "complete_title": "You're all set!",
    "subtitle_1": "If you're using an iPhone, please tap **Done** in the top left hand corner of this screen to return to your dashboard.",
    "subtitle_2": "If you're using an Android, tap the **X** in the top left hand corner of this screen.",
    "subtitle_0": "Please exit the browser to continue."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Consented relationship",
  "finish_registration": {
    "title": "Complete your registration",
    "subtitle": "Please complete the following information before your appointment:",
    "button": "Finish registration"
  },
  "arab_ethnicity": {
    "arab": "Arab",
    "non_arab": "Non-Arab",
    "prefer_not_to_answer": "Prefer Not To Answer",
    "question": "Arab Ethnicity",
    "subtitle": "Regulations require that we collect the following information.",
    "help": "Ethnicity refers to a set of common traits such as language, cultural customs, religion, and other characteristics used to differentiate groups of people. It may or may not align with a racial identity as well."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Depending on your device, click the link below to download the Primary Health Pass."
    }
  },
  "stepper": {
    "next": "Next",
    "back": "Back",
    "complete": "Complete"
  },
  "registration_direcory": {
    "keyword_search_label": "Keyword, city, zip code, organization name",
    "keyword_search_label_mobile": "City, zip code, organization name",
    "zero_results": {
      "title": "We didn’t find any providers with that search term.",
      "sub_title": "Try searching for your provider by:",
      "p1": "Zip code",
      "p2": "City or town name",
      "p3": "County name",
      "p4": "Provider or organization name"
    }
  },
  "registration_flyer": {
    "open_camera": "Open the camera app on your device",
    "point_to_qr_code": "Point to the QR code & tap the link",
    "complete_registration": "Complete Registration",
    "need_help": "Need help? Contact us at"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Vaccine information attestation",
    "select_checkbox": "Please review the following and select the appropriate checkbox below before proceeding.",
    "opportunity_to_read": "I have or have been given the opportunity to read, or had read to me, the Vaccine Information Statement(s) (“VIS”) or Emergency Use Authorization (“EUA”) fact sheet provided for the vaccine(s) to be administered:",
    "vaccine_info_sheet": "vaccine information sheet",
    "vaccine_fact_sheet": "vaccine fact sheet",
    "vaccine_info_statement": "vaccine information statement"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "Polio",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "Varicella",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "this kit includes tests for chlamydia and gonorrhea.",
    "B": "this kit includes tests for chlamydia and gonorrhea.",
    "C": "this kit includes tests for syphilis.",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "this kit includes tests for HIV and syphilis.",
    "box_type": "Box {{type}}",
    "administered_text": "We will contact you when your kit reaches the lab and when your results are ready.",
    "activate_kit": "Activate my test kit",
    "register_another_test": "Register another test",
    "credentials": {
      "title": "Let's get started",
      "label": "Contact",
      "p1": "We'll start with some info about you.",
      "location_step": {
        "p1": "Who provided your test kit?",
        "load": "Load more"
      }
    },
    "checklist": {
      "not_urinated": "You have not urinated in the last hour.",
      "bathroom": "You have access to a bathroom for urine collection.",
      "anal_swab": "You have access to a private place to perform an anal swab.",
      "blood_extraction": "You have access to a clean environment for blood extraction.",
      "washed_hands": "You have washed your hands with soap and warm water for 30 seconds.",
      "title": "Are you ready to begin sample collection?",
      "no_alcohol": "Do not drink alcohol on the day of collection.",
      "menstruation": "Do not collect vaginal swab specimen during menstruation or within 24 hours of intercourse."
    },
    "confirm": {
      "title": "Confirm your information",
      "p1": "Is this information correct?"
    },
    "display_name": {
      "A": "Chlamydia & Gonorrhea (Single Site)",
      "B": "Chlamydia & Gonorrhea (3 Site)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia & Gonorrhea",
        "B": "Chlamydia & Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "Colorectal Cancer Screening (FIT)",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea"
    },
    "rescan": "Rescan",
    "scanned": "You've scanned Box {{type}}",
    "not_yet": "Not Yet",
    "success_registration": "Registration success!",
    "no_box": "Don't have a box?",
    "faq_link": "Visit FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "You're one step closer to a healthier you.",
      "p1": "Scan or enter the **kit bar code** on the side of your box."
    },
    "appointments": {
      "title": "Select the patient & provider",
      "p1": "We found more than one profile associated with your contact information. Select the correct profile:",
      "new": "New patient or provider"
    },
    "verify": {
      "title": "Confirm identity",
      "p1": "We found your patient record."
    },
    "section_1": "Confirm kit contents",
    "section_2": "Test collection",
    "section_3": "Packaging",
    "section_4": "Shipping",
    "box_a": "Box A",
    "box_b": "Box B",
    "box_c": "Box C",
    "kit_flow": {
      "button_1": "Continue to packaging",
      "button_2": "I'm ready",
      "button_3": "My kit is complete and packed",
      "button_4": "I'm finished",
      "button_5": "Next test",
      "button_6": "Skip instructions"
    },
    "contents": {
      "header": "Great! Let's make sure your kit is complete",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Blood collection card",
      "blood_collection_card_subtitle": "(1 or 2 depending on test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Single use lancets",
      "up_to_other": "(Up to 4 depending on test kit)",
      "adhesive_bandages": "Adhesive bandages",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Biodegradable collection paper",
      "bottle_and_probe": "Sampling bottle and probe",
      "prepaid_envelope": "Pre-paid shipping envelope",
      "biohazard_subtitle": "(with absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Biohazard bags",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 sterile gauze pad"
    },
    "packaging": {
      "title": "Packaging checklist",
      "subtitle": "Before you ship your kit, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "The specimen collection tube is sealed in its biohazard bag",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_b": {
      "step_1": "You have packed all test kit contents, even if you skipped the test(s)",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "Each specimen collection tube is sealed in its own biohazard bag (only **one** specimen per bag)",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_c": {
      "step_1": "You have packed all test kit contents, including used lancets.",
      "step_2": "Your **date-of-birth** is written on the blood collection card using MM/DD/YYYY format",
      "step_3": "The **collection date** is written on the blood collection card using MM/DD/YYYY format",
      "step_4": "The blood collection card and all used lancets are placed in the biohazard bag",
      "step_5": "The biohazard bag is placed into the box",
      "step_6": "The box is placed into the pre-paid shipping envelope",
      "step_7": "The return shipping envelope is completely sealed"
    },
    "success": {
      "header_1": "Great!",
      "text_1": "You're ready to collect your first sample.",
      "header_2": "You did it!",
      "text_2": "**Next up:** package your kit to send back.",
      "header_3": "Great job!",
      "text_3": "You’re one step closer to better health.",
      "header_4": "Nice job!",
      "text_4": "Just one more and you’re done!",
      "text_5": "You're ready to collect your first sample"
    },
    "instructions": {
      "title": "Select a test to view instructions:",
      "subtitle": "Test",
      "button": "Instructions",
      "pdf": "PDF instructions"
    },
    "instructions_box_a": {
      "title": "Urine collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
      "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
      "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
      "step_4_sublabel": "Do not fill tube past the maximum fill line or specimen will be rejected.",
      "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
      "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Urine collection",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
        "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
        "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
        "step_4_subtitle": "Do not fill tube past the maximum fill line or specimen will be rejected.",
        "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab into your mouth and rub the swab tip against the back of your throat for 10 seconds.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a blue <span style=\"color: #001F70\">**\"ORAL\"**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab 3 - 5 cm (1 - 2”) into the anal canal. Gently turn the swab for 5 - 10 seconds to collect any potential organisms.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a green <span style=\"color: #00C08C\">**“ANAL”**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      }
    },
    "instructions_box_c": {
      "title": "Blood collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your name, date of birth, and the date of collection in the designated fields. Use MM/DD/YYYY format.",
      "step_2": "Open blood card flap to expose the circles on the blood collection paper. Do not touch the blood collection paper.",
      "step_3": "Wash hands with warm water for at least 30 seconds, then shake hands vigorously for 15 seconds to encourage blood flow to your fingers.",
      "step_4": "Clean fingertip with alcohol pad. It is best to use the middle or ring finger of your non-dominant hand.",
      "step_5": "Take the lancet and twist off the cap. Press the small tip firmly into the tip of your finger, until the needle ejects with a click. Keeping your hand below your heart during collection, massage finger from base to tip to encourage blood flow.",
      "step_5_subtitle": "Lancets are single use. All lancets need to be returned with your sample to the laboratory for disposal.",
      "step_6": "Starting at the center, apply 3-6 drops to fill the circle and soak through the collection paper. Do not touch the paper with your finger as this will restrict blood flow. Once circle is full, move on to next circle. It is okay for blood to extend beyond lines, but do not let blood spots spread into each other.",
      "step_6_subtitle": "Do not add additional blood to a circle once completed or dry. The “layering” of blood will invalidate the collection.",
      "step_7": "Without closing the blood card, lay it on a flat surface and allow the blood collection paper to air dry at room temperature, for at least 30 minutes. Do not heat, blow dry, or expose the blood collection paper to direct sunlight. Heat will damage the specimen.",
      "step_7_subtitle": "Check the back side of blood collection paper. Blood should saturate all the way through and fill each circle of the collection paper.",
      "step_8": "When blood collection paper is dry, close blood card by tucking flap. Place the blood card and lancets into biohazard bag with the desiccant pack. Ensure biohazard bag is properly sealed."
    },
    "test_tips": {
      "title": "Tips for Proper Blood Collection",
      "subtitle": "For best results:",
      "step_1": "Be sure you are not dehydrated while performing collection. Hydration promotes blood flow.",
      "step_2": "Do not perform collection immediately after smoking.",
      "step_3": "Washing and warming your hands under warm water will help promote blood flow in your hands.",
      "step_4": "Shake hands vigorously towards the floor to encourage blood flow to your fingers.",
      "step_5": "Keep collection device and hands below your heart during collection for best blood flow.",
      "step_6": "You may need more than one finger prick. Repeat these tips between each finger prick."
    },
    "shipping": {
      "header": "Your kit is ready to ship!",
      "text": "**Congrats!** You finished your sexual health screening."
    },
    "pick_up": "Pick up your recommended test kit from the site staff and register below",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "View results",
    "recommended_kit_title": "Recommended Test Kits",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection",
      "subtitle": "Collection tubes may contain a liquid preservative. <span class=\"text-danger\">**Do not empty liquid preservative from the collection tubes if present.**</span>",
      "step_1": "Hold the swab between the faint score line (if present) and the cotton-tipped portion of the swab in one hand and separate the labia (folds of skin around the vaginal opening).",
      "step_2": "Insert the swab 5cm (2in) into the vaginal opening. Gently turn the swab for 30 seconds while rubbing the swab against the walls of the vagina.",
      "step_3": "Carefully withdraw the swab and place into the collection tube marked with a <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Break the swab by bending against the collection tube.",
      "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_5": "Write your date of birth in MM/DD/YYYY format onto the tube in the designated area (DOB).",
      "step_6": "Write the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area (Coll. Date).",
      "step_7": "Place specimen into the empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "packaging_box_h": {
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "Your **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_6": "The biohazard bag is placed into the box"
    },
    "instructions_box_cc": {
      "title": "Stool collection",
      "step_1": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area. Open green cap by twisting and lifting.",
      "step_2": "Place supplied collection paper into toilet bowl on top of water.",
      "step_3": "Deposit stool sample on top of collection paper.",
      "step_4": "Collect sample from stool before paper sinks and stool sample touches water.",
      "step_5": "Scrape the surface of the fecal sample with the sample probe.",
      "step_6": "Cover the grooved portion of the sample probe completely with stool sample.",
      "step_7": "Close sampling bottle by inserting sample probe and snapping green cap on tightly. Do not reopen.",
      "step_8": "Flush. Collection paper is biodegradable and will not harm septic systems.",
      "step_9": "Wrap sampling bottle in absorbent pad and insert in to  the biohazard bag."
    },
    "contact_support": {
      "title": "Let's fix this",
      "text_1": "We are sorry to hear that something is wrong!",
      "text_2": "Please contact us to let us know what’s wrong and we will help to replace your kit."
    },
    "contact_support_success": {
      "title": "We received your message",
      "text_1": "Thanks for contacting us.",
      "text_2": "A member of our support team will be in touch soon.",
      "button_text": "Return home"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for colorectal cancer.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for hemoglobin.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D."
  },
  "copy_link": "Copy page link",
  "copied_link": "Copied link!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Protection against the common flu viruses",
      "vaccine_covid": "Protection against COVID-19 viruses",
      "tdap": "Tdap vaccine can prevent tetanus",
      "polio": "Protection against the polio virus. Required for children before starting school.",
      "varicella": "Vaccine that protects against chickenpox",
      "shingles": "Vaccine that protects against shingles. Minimum age is 18.",
      "human_papillomavirus": "Vaccine that protects against HPV. Recommended for 11 year old children.",
      "meningococcal": "Vaccine protects against four types of meningococcal bacteria."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Find a clinic",
    "select_pin": "Select a pin to see details",
    "miles_shorten": "{{miles}} mi",
    "next_available": "Next available",
    "appointment_type": "Appointment type",
    "individual_appointment": "Individual appointment",
    "family_appointment": "Family appointment",
    "vaccines": "Vaccines",
    "what_kind_appointment": "What kind of appointment do you want to book?",
    "select_family_or_individual": "Select family appointment if you are scheduling for multiple family members at once.",
    "vaccines_selected": "Vaccines selected",
    "where_care": "Where do you want to receive care?",
    "select_vaccines_for_individual": "Select vaccines for your appointment (optional).",
    "select_vaccines_for_family": "Select vaccines for your family appointment (optional).",
    "schedule_services": "Schedule services",
    "add_family": "Add family members and select services below.",
    "family_member": "Family member {{number}}",
    "morning": "Morning",
    "afternoon": "Afternoon",
    "slot_available": "{{number}} available",
    "within_x_miles": "Within {{miles}} miles of",
    "any_distance": "Any distance from",
    "partial_results": "Partial results",
    "partial_matches": "The following clinics offer some but not all of the vaccines you're looking for",
    "no_matches": "That search didn’t find any matches. Try changing your filters for better results.",
    "no_clinics_found": "There aren't any clinics that match your search.",
    "broaden_filters": "Try broadening your filters for better results.",
    "unavailable_vaccines": "Unavailable vaccines:",
    "available_vaccines": "Available vaccines:",
    "select_date": "Select a date",
    "available_appointments": "AVAILABLE APPOINTMENTS",
    "appointment_scheduling_info": "You’ll be able to assign appointment times to specific family members in the next step. You can schedule appointments on different days if you choose.",
    "hold_selected_appointments": "We will hold your selected appointments for 15 minutes.",
    "appointments_selected": "APPOINTMENTS SELECTED",
    "no_appointments_selected": "No appointment selected",
    "vaccines_needed": "Vaccines needed",
    "select_x_appointments": "Select {{number}} appointments.",
    "more": "More",
    "less": "Less",
    "register_next_family_member": "Register next family member",
    "successfully_registered_x_of_y_family_members": "You have successfully registered {{x}} of {{y}} family members",
    "next_family_member": "Next family member",
    "appointments_abbreviated": "appts",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "Let's verify your email!",
    "hi_full_name": "Hi {{full_name}},",
    "verify_email_button": "Verify email",
    "please_verify_email": "Please use the button below to verify your email."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "Hepatitis A",
    "hepatitis_b": "Hepatitis B",
    "hepatitis_a_b": "Hepatitis A & B",
    "hib": "Hib",
    "mmr": "Measles, Mumps, Rubella",
    "meningococcal": "Meningococcal",
    "mpox": "Mpox",
    "pneumococcal": "Pneumococcal",
    "polio": "Polio",
    "rsv": "Respiratory syncytial virus",
    "rotovirus": "Rotavirus",
    "zoster": "Zoster (Shingles)",
    "tetanus_diptheria": "Tetanus & Diphtheria",
    "tdap": "Tdap",
    "typhoid": "Typhoid",
    "varicella": "Varicella (Chickenpox)",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (Adult)",
    "covid-19_adult_description": "For people 12 years or older.",
    "covid-19_children": "COVID-19 (Child)",
    "covid-19_children_description": "For children aged 4 - 11 years old.",
    "covid-19_infants": "COVID-19 (Infant)",
    "covid-19_infants_description": "For children 6 months - 3 years old.",
    "influenza": "Influenza",
    "mmrv": "Measles, Mumps, Rubella and Varicella"
  },
  "deep_archived": {
    "admin": "For data security purposes, the data in the group {{test_group_name}} ({{slug}}) is archived and is no longer viewable or changeable from this page. Please reach out to your account manager or support@primary.health if you need assistance.",
    "participant": "This page is no longer available. Please reach out to your contacts from the health campaign: {{test_group_name}}. If you are having trouble viewing an old record, please contact support@primary.health for assistance."
  }
}