import React from 'react';
import { Badge, Button, Col, Row, Spinner } from 'react-bootstrap';

import MultipleMatchResolver from './MultipleMatchResolver';

const badgeVariant = (reference) => {
  switch(reference.status) {
    case "ok":
      return "bg-success";
    case "no_records":
      return "bg-warning text-dark"
    default:
      return "bg-danger";
  }
}

const ImmunizationCheck = ({
  checkNow,
  error,
  latestReference,
  loading,
  testGroupId,
  user
}) => {
  return (
    <>
      {latestReference && (
        <>
          {latestReference && latestReference.status != "ok" && (
            <Badge className={badgeVariant(latestReference)}>{latestReference.status.replace("_", " ")}</Badge>
          )}
          {latestReference.status == "multiple_matches" && (
            <MultipleMatchResolver testGroupId={testGroupId} user={user} onMatchSelected={checkNow} />
          )}
        </>
      )}
      <Row className="my-3">
        <Col xs={"auto"}>
          <div
            className="d-flex px-3 py-2"
            style={{
              backgroundColor: "#E9F1FB",
              borderRadius: "12px",
            }}
          >
            <div className="my-auto body-large-16-medium">Check for updates in vaccine history</div>
            {loading
              ? <><Spinner animation="border" size="sm" className="mx-3" role="status"/><strong>Checking</strong></>
              : <Button variant="outline-primary" className="ms-3" onClick={checkNow} size="sm">Update</Button>
            }
          </div>
        </Col>
        {error &&
          <Col xs={"auto"} className="my-auto">
            <div className="text-warning">An error occurred. Please try again later</div>
          </Col>
        }
      </Row>
    </>
  );
};

export default ImmunizationCheck;
