import React from 'react';
import { isCovidVaccine, vaccineName, manufacturerName, doseValidity } from '../../common/utils/vaccine';
import { Card } from 'react-bootstrap';

const sortByDate = (histories) => {
  return histories.sort((a, b) => {
    return new Date(b.vaccinated_on) - new Date(a.vaccinated_on);
  })
}

const rowHighlightClass = ({vaccine, vaccinated_on, notes}, highlightRelevant) => {
  return (
    highlightRelevant && (
      isCovidVaccine(vaccine) ||
      (notes && notes.length)
    )
  )
  ? 'table-warning'
  : null;
};

const cellHighlightStyle = (highlight) => {
  return highlight ? { backgroundColor: 'var(--danger-red)', color: 'white' } : null;
};

const ImmunizationTable = ({histories, highlightRelevant = true}) => {
  if (histories.length === 0) {
    return <p>No records available</p>;
  }

  return (
    <Card className="p-3">
      <section className="fabrx-tables-light table-responsive">
        <table className="table table-lg mt-3">
          <thead>
            <tr>
              <th>Status</th>
              <th>Vaccine</th>
              <th>Manufacturer</th>
              <th>Date</th>
              <th>Notes</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
            {sortByDate(histories).map( history => {
              const {id, vaccine, manufacturer, vaccinated_on, notes, source, valid_dose} = history;
              return (
                <tr key={id} className={rowHighlightClass(history, highlightRelevant)}>
                  <td style={cellHighlightStyle(!valid_dose)}>{ doseValidity(valid_dose) }</td>
                  <td title={vaccine}>{ vaccineName(vaccine) }</td>
                  <td title={manufacturer}>{ manufacturerName(manufacturer) }</td>
                  <td>{ vaccinated_on }</td>
                  <td>{ notes }</td>
                  <td>{ source.toUpperCase() }</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </Card>
  );
};

export default ImmunizationTable;
