export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Bil",
    "day": "Maalin",
    "year": "Sanad",
    "january": "Janaayo",
    "february": "Feebraayo",
    "march": "Maarao",
    "april": "Abriil",
    "may": "Maayo",
    "june": "Juun",
    "july": "Julaay",
    "august": "Ogosto",
    "september": "Sebtembar",
    "october": "Oktoobar",
    "november": "Nofeembar",
    "december": "Diseembar"
  },
  "user": {
    "send_appointment_confirmation_message": "Balantaada waxa loo xaqiijiyay {{name}}.",
    "landline_appointment_reminder_message": "Salaan, {{full_name}}. Tani waa xusuusinta ballantaada ee {{name}}. Ballantaadu waa marka ay taariikhdu tahay {{date}} markay tahay {{time}} ee halka {{address}}.",
    "send_at_home_visit_confirmation_message": "Balantaada waxa la xaqiijiyay {{date}} kadib {{time}}.",
    "send_mail_order_confirmation_message": "Dalabkaaga boostada waa la xaqiijiyay",
    "send_waitlist_message": "Waxaa lagugu daray liiska sugitaanka {{name}}. Fadlan ha imaan ilaa aan xaqiijinno waqtiga ballanta.",
    "verify_contact": {
      "phone": "Fadlan xaqiiji lambarka taleefankaaga adigoo gujinaya: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Ka sii wad diiwaangelinta ballanta halkan: {{url}}",
    "on_demand_confirmation_message": "Waxaad si guul leh isu diiwaangelisay {{name}}.",
    "appointment_reminder": "Ballankaaga ka hor ee wakhtiga {{start_time}}, fadlan guji xiriiriyaha si aad u dhammaystirto wax kasta oo ficil ah oo aadan dhamaystirin kadibna eeg xaqiijintaada. Fadlan ha imaanin wax ka badan 15 daqiiqo ka hor ballantaada.",
    "appointment_reminder_base": "{{text}} HALKAN GUJI: {{link}}",
    "test_result_notifier": {
      "text_message": "Xariirka bukaan socodka {{first_name_with_last_initial}} waa la cusboonaysiiyay. Tag {{url}} oo isticmaal koodka: {{access_code}}",
      "email_subject": "Cusboonaysiinta cusub ee boggaaga bukaanka",
      "email_intro": "Ma nabad baa {{first_name_with_last_initial}}",
      "email_p1": "Farriin cusub ama cusbooneysiin bukaan ayaa diyaar kuu ah.",
      "email_p2": "Guji badhanka hoose ama isticmaal xidhiidhka si aad u aragto boggaaga bukaanka oo aad isticmaasho koodka",
      "email_p3": "Ka eeg bogga bukaanka:'",
      "email_button": "Daawo portal"
    },
    "landline_appointment_reminder_message_without_time": "Salaan, {{full_name}}. Tani waa xusuusinta ballankaaga {{name}}. Ballankaagu waxaa waaye {{date}} at {{address}}.",
    "appointment_reminder_on_demand": "Kahor taariikhdaada balanta ee {{date}} fadlan guji xiriiriyaha si aad u dhamaystirto wax kasta oo ficil ah oo aadan dhamaystirin kadibna eeg xaqiijintaada.",
    "new_test_result_notifier": {
      "email_intro": "Boggaga bukaanka waa la cusboonaysiiyay",
      "email_text_1": "Booqo boggaaga Taariikhda Caafimaadka ee hoose si aad u aragto diiwaanada cusub ee bukaanka ama warbixinada shaybaadhka.",
      "email_button": "Taariikhda Caafimaadka",
      "email_text_2": "Waxaad sidoo kale nuqul ka samayn kartaa oo aad ku dhejin kartaa URL-kaan daalacaadaada:"
    },
    "mailers": {
      "email_contact_us": "Su'aal miyaad qabtaa? Nala soo xiriir"
    }
  },
  "errors": {
    "generic": {
      "message": "Wax ayaa khaldamay, fadlan mar kale ku celi"
    },
    "messages": {
      "blank": "Ma noqon karto mid banaan"
    },
    "incorrect_credentials": "Aqoonsiyadaada way khaldanaayeen, fadlan isku day markale.",
    "error_activating_test_kit": "Waxaa jiray cilad ku dhacday howlgelinta qalabka baaritaanka."
  },
  "type": "Qor",
  "payment": {
    "continue_label": "Sii wad lacag bixinta",
    "policy_text": "Haddii aad baajiso ama aadan imaanin ballantaada, waxaan kuu soo celin doonaa 80% lacagtaada, 7 maalmood kadib taariikhda ballantaada. Waxaad arki kartaa dhamaan [Terms of Service]({{link}})",
    "card_number": "Lambarka Kaarka",
    "expiry_date": "Taariikhda dhicitaanka",
    "pay_button": "Mushahar",
    "no_credit_card": "Waxaan ku bixin doonaa goobta",
    "cash_payment_helper": "Fadlan xaqiiqso inaad keento **{{payment}}** wakhtiga ballankaaga",
    "invoice": "Qaansheeg",
    "pay_by_card": "Kaarka ku bixi",
    "cost": "Qiimaha",
    "total": "Wadarta guud",
    "pay_cash_at_appointment": "Waxaan ku bixin doonaa lacag caddaan ah wakhtiga ballantayda {appointment}",
    "view_invoice": "Eeg/Daabac Qaansheegta",
    "refund_policy": "Haddii aad baajiso ballantaada, waxaanu kuu soo celin doonaa {{amount}} lacag-bixintaada, 7 maalmood kadib taariikhda ballantaada. Waxaad arki kartaa [Shuruudaha Adeegga]({{link}}) oo buuxa",
    "refund_window_passed": "Ballantaadu uma qalanto lacag soo celinta maadaama wakhtigii ballanku dhaafay. Fadlan si toos ah ula xiriir isku-duwayaasha goobta wixii go'aamo ah.",
    "amount_paid": "Qadarka La Bixiyay",
    "balance": "Haraaga"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Soo gali dalabka oggolaanshaha ama saxiix sanduuqa saxiixa ee hoose",
    "stop": "Oggolaansho ayaa loo Baahanyahay si loo dhamaystiro Diiwaangelinta",
    "parent_or_guardian_required_html": "Si loo dhammaystiro diiwaangelintaada, waalidkaaga, mas'uulkaaga, ama shakhsi lagu wareejiyay masuuliyadaada waa in uu bixiyaa oggolaanshaha foomka hoose oo ay ku saxiixaan magacooda. Adigu <b>CANNOT</b> Oggolaanshaha adeeggan keligaa.",
    "send_parent_or_guardian": "Waxaad u soo diri kartaa waalidkaa ama mas'uulkaaga xiriirkan ama ha saxiixaan boggan hoose.",
    "consent_guardian_name_signature": "Magaca Koobaad",
    "consent_for_minor": "Saxiix oggolaanshaha adiga oo wakiil ka ah qof aan qaangaarin",
    "guardian_signature": "Saxiixa waalidka, mas'uulka, ama go'aan -qaate caafimaad oo lagu wareejiyay masuuliyada",
    "after_you_schedule": "Ka -qaybgalayaasha ka yar da'da {{age}} waa inay haystaan ​​oggolaansho waalidku ama wakiilku u saxeexay iyaga. Fadlan sii wad ilaa dhammaadka diiwaangelinta si aad ugu dirto mas'uulkaaga ama aad ugu saxiixdo qofka kugu tiirsan.",
    "consent_guardian_last_name_signature": "Magaca Dambe",
    "consent_first_and_last_name": "Magaca Hore & Dambe",
    "phone_number": "Nambarka masuulka mise maamulaha ",
    "email": "iimaylka masuulka mise maamulaha",
    "consent_registrar_with_guardian_name_signature": "Magaca koowaad ee masuulka",
    "consent_registrar_with_guardian_last_name_signature": "Magaca dambe ee masuulka",
    "consent_registrar_first_name_signature": "Magaca koowaad ee diiwaan-hayaha",
    "consent_registrar_last_name_signature": "Magaca dambe ee diiwaan-hayaha",
    "consent_on_behalf": "Waxaan u saxiixayaa anoo matalaya {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "Waxaa af ahaan i wakiishay {{first_name}} {{last_name}} inaan u saxiixo anoo ayaga matalaya.",
    "consent_on_behalf_registrar_with_guardian": "Waxa af ahaan i wakiishay masuulka {{first_name}} {{last_name}} in aan u saxiixo anoo ayaga matalaya.",
    "registrar_phone": "Taleefan lambarka diiwaan-hayaha",
    "registrar_email": "Iimaylka diiwaan-hayaha",
    "consented_by_parent_guardian": "Waalidka / Mas'uulka",
    "consented_by_decision_maker": "Go'aan-qaadaha caafimaadka ee idman",
    "consented_by_guardian_verbal": "Diiwaan-hayaha uu wehliyo Mas'uulka (oggolaanshaha afka ah ee la helay)",
    "consented_by_registrar": "Diiwaan-hayaha (oggolaanshaha afka ah ee la helay)",
    "full_name": "Magaca oo dhamaystiran",
    "print": "Print"
  },
  "registration": {
    "contact_information": "Magaca iyo Ciwaanka",
    "contact_information_additional": "Macluumaadka lagula Xiriiraayo Waalidka ama Mas'uulka ee wada Xiriirka Ballanta & Natiijooyinka",
    "personal_information": "Macluumaadka Tirakoobka",
    "address_placeholder": "Fadlan gali cinwaanka gurigaaga",
    "first_name_label": "Magaca Koobaad",
    "last_name_label": "Magaca Dambe",
    "errors": {
      "state": "Gobolku waa inuu lahaadaa 2 lambar ansax ah oo ah lambar sireedka gobolka",
      "phone_number_invalid": "Lambarka taleefanka ma aha mid sax ah",
      "required": "Lambarkan waa loo baahan yahay",
      "email_domain": "Galitaankaagu ma aha sax; Fadlan laba jeer-hubi. Diiwaangelintu waxay ku kooban tahay ka qaybgalayaasha ay soo xuleen ururka maalgalinaayo. Fadlan isku day inaad isticmaasho imaylka shaqadaada ama emaylka bulshada. Haddii aad dareento inaad u qalanto oo uu jiro khalad, fadlan la xiriir caawiye.",
      "age_requirement": "Taariikhda dhalashada ma aysan buuxin shuruudaha da'da",
      "signature_required": "Saxiix ayaa loo baahan yahay",
      "regex_invalid": "Lambarkani wuxuu u muuqdaa mid aan sax ahayn.",
      "date_invalid": "Taariikhdan ma aha mid jirto",
      "invalid_entry": "Fadlan ha sii wadin diiwaangelinta. Raac tilmaamaha shaashadda ku yaal ama la xiriir hagahaada.",
      "city": "Waa inay noqotaa magaalo sax ah",
      "survey_unanswered": "Fadlan hubi in dhammaan su'aalaha loo baahan yahay ee ku calaamadsan * aad ka jawaabtay.",
      "postal_code": "Waa inay noqdaa 5 lambar oo lambar boosto oo sax ah",
      "field_required": "{{field}} waa inuu lahaadaa lambar",
      "option_required": "Waa inaad doorataa mid ka mid ah xulashooyinka",
      "year_length_invalid": "Geli taariikhda dhalashada oo leh afar lambar oo sanad (yyyy)",
      "invalid_day": "Maalintu waa in ay u dhaxayso 1-31",
      "reached_max_chars": "Waxaad gaartay tirada ugu badan ee lambarada",
      "chars_max": "Lambarada ugu badanaan",
      "minimum_length": "Gelinta waa inay ahaataa ugu yaraan {{length}} xarafood."
    },
    "insurance_status": {
      "question": "Ma leedahay caymis caafimaad?",
      "have_health_insurance": "Haa, waxaan haystaa caymis caafimaad",
      "do_not_have_health_insurance": "Maya, ma haysto caymis caafimaad"
    },
    "insurance_policy_holder": {
      "question": "Waayo qofka sharciga caymiska haysto?",
      "i_am": "Aniga",
      "my_spouse": "Xaaskayga ama lammaanahayga",
      "my_parents": "Waaliddiintayda",
      "someone_else": "Qof kale",
      "policy_first_name": "Magaca Koowaad ee sharciga caymiska haystaha",
      "policy_last_name": "Magaca Dambe ee sharciga caymiska haystaha",
      "policy_dob": "Taariikhda Dhalashada ee qofka sharciga caymiska haysto",
      "name": "Policy holder's name"
    },
    "insurance_information": {
      "title": "Macluumaadka Caymiska",
      "company_name": "Magaca Shirkadda Caymiska",
      "id_number": "Lambarka Aqoonsiga Xubinta",
      "group_number": "Lambarka Kooxda Xubinta",
      "secondary_insurance_label": "Waxaan haystaa caymis labaad",
      "take_photo": "Please take a photo of your insurance card. Taking a photo will auto fill some of the information.",
      "front_of_card": "Front of card",
      "card_information": "Insurance card information",
      "back_of_card": "Back of card"
    },
    "employment_information": {
      "label": "Shaqada",
      "address_1": "Cinwaanka Shirkadda",
      "address_2": "Tusaale: Suite 200",
      "phone_number": "Lambarka Taleefanka Shirkadda",
      "company": "Magaca Shirkadda",
      "postal_code": "Kodhka Boostada ee Loo shaqeeyaha",
      "employed": "Shaqeeya",
      "sole_prorietor": "Ganacsiga halka shakhsi leeyahay",
      "not_employed": "Aan la shaqaalaysiin",
      "reporting_supervisor": "Kormeeraha warbixinta",
      "reporting_department": "Kormeeraha waaxda",
      "supervisor_name": "Magaca kormeeraha",
      "supervisor_email": "Iimaylka kormeeraha"
    },
    "location_availability": "Imtixaanka waxaa hadda mudnaanta la siinayaa xubnaha bulshada ee ku nool deegaankan",
    "custom_survey": "Weydiimaha",
    "confirmation": "Dib u eegid",
    "waitlisted": "Liiska sugitaanka",
    "schedule_your_appointment": "Ballanka Jadwalka",
    "information": "Macluumaad",
    "consent": "Ogolaansho",
    "location": "Goobta",
    "symptoms": "Astaamaha",
    "address_required": "Fadlan geli cinwaankaaga",
    "consent_required": "Fadlan oggolow si aad u sii wadato",
    "required_field": "Waxay tilmaamaysaa inay tahay meel aad u baahantahay inaad buuxiso",
    "phone_number": "Lambarka Taleefanka gacanta",
    "email": "Iimayl",
    "date_of_birth": "Taariikhda Dhalashada",
    "minimum_age": "Da'da ugu yar ee ka qaybqaadashadu waa {{year}} sano.",
    "no_minimum_age": "Ma jirto da'da ugu yar ee baaritaanka",
    "continue_button": "Sii wad",
    "email_required": "Waxaad galinayso waa inuu noqdaa iimayl sax ah.",
    "done": "La dhameeyay",
    "signature": "Saxiixa",
    "clear_button": "Cad/waadixin",
    "back_button": "xaga dambe",
    "choose_location": "Dooro Goobta",
    "no_slots": "Ma jiraan boosas dheeri ah oo laga heli karo {{location}}",
    "choose_appointment": "Ka dooro wakhtiga ballanta {{location}}",
    "appointment_required": "Fadlan dooro waqtiga ballanta",
    "phone_number_required": "Lambarka taleefanka ma ah mid sax ah",
    "phone_number_label": "Fadlan geli lambar heli kara farriimaha qoraalka si natiijooyinka baaritaanka aad si dhakhso ah ugu hesho",
    "symptoms_experiencing": "Fadlan guji calaamadaha aad hadda la kulmaysid Haddii aadan lahayn astaamo, fadlan sii wad.",
    "household": "Xubnaha Qoyska",
    "household_p1": "Ikhtiyaar ahaan, u samee ballan xubnaha qoyska ee iyaguna u baahan in la baaro.",
    "add_dependent": "Ku dar Xubinta Qoyska",
    "remove_dependent": "Ka saar ku tiirsane",
    "dependent_consents": "Oggolaanshaha",
    "submit": "Dhammaystir",
    "add_waitlist": "Ku dar liiska sugitaanka",
    "address": "Cinwaanka Guriga",
    "address_1": "Khadka Cinwaanka 1aad",
    "address_2": "Apt/Hal nooc #",
    "address_city": "Magaalada",
    "address_state": "Gobolka",
    "postal_code": "Koodhka Boostada",
    "race_ethnicity": "Jinsiyada",
    "gender": "Aqoonsiga Jinsiga",
    "self_described_gender": "Jinsiga oo si shakhsi ah loo qeexo",
    "interpreter": "Ma u baahan tahay Turjumaan? Hadday haa tahay, luqaddee?",
    "consent_to_terms": "Waan oggolahay shuruudahaan.",
    "reg_not_open": "Diiwaangelinta wali lama furin",
    "no_more_avail_spots": "Wakhtiga aad dooratay hadda lama heli karo Fadlan isku day mar kale.",
    "consent_helper": "Guji oo kujiid mowskaada ama fartaada sanduuqa hoose si aad u saxiixdo",
    "phone_number_unreachable_label": "Taleefoonka guriga",
    "select": "Dooro",
    "test_surveys": "Su'aalaha Ballanta",
    "edit": "Tafatirid",
    "continue_to_registration": "Sii wad Diiwaangelinta",
    "accounts": {
      "already_have_an_account": "Mar hore ayaad lahayd koonto?",
      "log_in": "Soo Gal",
      "sign_up": "Isdiwaangali",
      "sign_up_description": "Fadlan geli macluumaadkaaga si aad u abuurto koontadaada oo waqti u keydi diiwaangelinta mustaqbalka.",
      "log_in_description": "Fadlan geli e-mailkaaga iyo lambarkaada sirta ah, ama ku gal adoo adeegsanayo Google ama Outlook dhanka hoose",
      "sign_in_with_label": "Ku gal adoo adeegsanayo {{provider}}",
      "password_must_match": "Lambarada sirta waa inay is leeyihiin",
      "password_uppercase": "Lambarkaada sirta waa inuu ku jiraa ugu yaraan (%s) oo xaraf weyn ah.",
      "password_lowercase": "Lambarkaada sirta waa inuu ku jiraa ugu yaraan (%s) oo xaraf yar ah.",
      "password_number": "Lambarkaada sirta waa inuu ku jiraa ugu yaraan (%s) lambar.",
      "password_special": "Lambarkaada sirta waa inuu ku jiraa ugu yaraan (%s) xarfaha gaarka ah."
    },
    "password": "Lambarka Sirta",
    "password_confirmation": "Xaqiijinta Lambarka Sirta",
    "consent_for": "Oggolaanshaha {{name}}",
    "book_one_time_appointment": "Ballan Qabso hal mar ah",
    "duplicate_users": {
      "exists": "Hore ayaad u diwaangashanayd",
      "overlapping_email_and_phone_p1": "Waxaan farriin u dirnay lambarka taleefankaaga iyo cinwaanka emaylkaada.",
      "p2": "Adigoo adeegsanaya farriintaada, waad beddeli kartaa ballantaada ama waad geli kartaa natiijooyinka baaritaanka.",
      "overlapping_phone_and_email_p3": "Fadlan ka hubi emailkaaga ama taleefankaaga wixii faahfaahin ah.",
      "overlapping_email_p3": "Fadlan ka hubi iimaylkaaga wixii faahfaahin ah.",
      "overlapping_phone_p3": "Fadlan ka hubi taleefankaaga wixii faahfaahin ah.",
      "overlapping_phone_p1": "Waxaan farriin u dirnay lambarka taleefankaaga.",
      "overlapping_email_p1": "Waxaan u dirnay farriin cinwaanka emailkaaga.",
      "p4": "Haddii aad u malaynayso inay tani tahay khalad, fadlan nagala soo xiriir support@primary.health",
      "overlapping_email_and_phone_p3": "Fadlan hubi emailkaaga ama taleefankaaga wixii faahfaahin ah."
    },
    "duplicate_waitlist": {
      "exists": "Waxaa mar horaba lagugu daray liiska sugitaanka",
      "overlapping_email_and_phone_p1": "Waxaan u dirnay xaqiijin kale lambarka taleefankaaga iyo cinwaanka emaylkaaga",
      "p2": "Adigoo adeegsanaya xaqiijintaada, waxaad bixin kartaa macluumaad dheeraad ah ama waad iska saari kartaa liiska sugitaanka.",
      "overlapping_phone_and_email_p3": "Fadlan ka hubi emailkaaga ama taleefankaaga wixii faahfaahin ah",
      "overlapping_email_p3": "Fadlan ka hubi iimaylkaaga wixii faahfaahin ah",
      "overlapping_phone_p3": "Fadlan ka hubi taleefankaaga wixii faahfaahin ah",
      "overlapping_email_p1": "Waxaan u dirnay xaqiijin kale emaylkaaga."
    },
    "insist_guardian_consent": "Waxaan bixinayaa oggolaansho anigoo matalaya",
    "confirmation_section": {
      "title": "Dib u eeg oo xaqiiji",
      "p1": "Fadlan dib u eeg macluumaadkaaga ka hor intaadan dhammaystirin diiwaangelinta.",
      "edit_information": "Dib u noqo oo wax ka beddel"
    },
    "iemodal": {
      "title": "Browser aan la taageerin",
      "body": "Diiwaangelintu waxay hadda suurtogal ka tahay barowsarkan"
    },
    "show_other_locations": "Guji si aad u tusto goobo kale",
    "non_us_address": "Kani waa cinwaan caalami ah",
    "test_group_user_survey": "Baaritaanka Ka -qaybgalaha",
    "self_consent": "Aniga ayaa oggolaanshaha isa siinaya",
    "address_country": "Dalka",
    "appointment": "Ballanta",
    "employee_id": "Aqoonsiga shaqaalaha",
    "appointment_time": "Waqtiga ballanta\r\n",
    "appointment_location": "Goobta",
    "phone_or_email": "Lambarka taleefanka ama iimaylka",
    "no_self_consent": "Aniga qudhaydu waan diiday oggolaanshaha",
    "no_insist_guardian_consent": "Aniga qudhaydu waan diiday oggolaanshaha {{full_name}}",
    "additional_consents_helpertext": "maamulid ",
    "minimum_age_with_months": "Da'da ugu yar ee ka qaybqaadashadu waa {{year}} sano iyo {{month}} bilood.",
    "assistive_technology": "qalabka iyo aaladaha caawiya dadka baahiya gaaka qabo",
    "covid_vaccine_y_n": "Ma jeceshahay tallaalka COVID-19 in laguu qabto balanta tallalka",
    "received_covid_vaccine_y_n": "Horay ma u qaadatay tallaalka COVID-19-ka",
    "covid_vaccinations": "Talalka Covid-19-ka",
    "select_vaccine_dose": "Fadlan door qiyaasta tallaalka ee aad rabto in lagu siiyo",
    "demographic_info": "Macluumaadka daraasada laga sameeyay dadka",
    "additional_info": "xog dheeraad ah",
    "self_described_race": "Jinsiyada Is-Sharaxay",
    "verify_contact_information": "Xaqiijinta",
    "verify_contact": {
      "we_sent_you_a_code": "Fadlan geli koodka aan ku soo dirnay {{contact}}",
      "confirmation_code": "Koodka xaqiijinta",
      "code_resent": {
        "email": "Kood kale ayaa loo soo diray iimaylkaaga",
        "phone_number": "Kood kale ayaa lagu soo diray taleefankaaga"
      },
      "did_not_receive_code": "Ma heshay lambarka xaqiijinta?",
      "verify_by": {
        "email": "Ku xaqiiji iimaylka",
        "phone_number": "Ku xaqiiji lambarka taleefankaaga"
      }
    },
    "preferred_method_of_communication": {
      "question": "Habka isgaarsiinta la doorbidayo",
      "helper_text": "Waxaan kuula soo xiriiri doonaa wixii ka soo kordha ballanta",
      "phone_number": "Taleefanka (Kaliya fariinta SMS-ka waxaana laga yaabaa in kharashaad interneet lagugu soo dalaco)",
      "landline": "Ma taageeri karno telefoonnada guryaha wakhtigan. Fadlan bixi iimayl si aan wixi muhiim ah kaala soo xiriirno.",
      "verification_method": "Sidee rabtaa inaad u hesho baaq sireedkaaga xaqiijinta?",
      "verification_helper_text": "Waxaa lagu soo diri doonaa baaq sireed si aad u xaqiijiso aqoonsigaaga ka hor ina aadan gelin."
    },
    "skip_for_now": "Hadda ka gudub",
    "default_information": {
      "title": "Haye, waxaan isticmaali doonaa ciwaankaan",
      "title_v2": "Hagaag, waxaan adeegsan doonnaa macluumaadka xiriirkaan"
    },
    "middle_name_label": "Magaca dhexe",
    "confirm_appointment": "Xaqiiji balanta",
    "dob": "Taariikhda Dhalashada",
    "different_appointment": "Balan ka duwan",
    "select_appointment_time": "Dooro wakhtiga balanta",
    "decline_address": "Waan diiday inaan bixiyo cinwaan Fadlan isticmaal cinwaanka barnaamijka.",
    "patient_information": "Macluumaadka bukaanka",
    "scan_license": "Sawir shatiga darawalnimada",
    "how_to_contact": "Sideen kuula soo xiriirnaa?",
    "decline_email_or_phone": "Waxaan diiday in aan bixiyo iimayl ama lambar taleefan. Waxaan u fasaxay maamulayaasha barnaamijka inay helaan natiijooyinkayga.",
    "medical_screening": "Baaritaanka caafimaadka",
    "show_service_at_this_location": "Show service at this location",
    "verbal_consent_provided": "In accordance with the Americans with Disabilities Act, participant or their legally authorized decision maker provided verbal consent. Written consent shall be obtained from participant or their legally authorized decision maker within 24 hours.",
    "unexpired_written_consent_present": "Testing site has unexpired written consent on file.",
    "select_to_proceed": "Please select an option above to proceed.",
    "guardians_information": "Guardians information",
    "registrars_information": "Registrar's information",
    "optional": "ikhtiyaari",
    "vfc_eligibility": "Vaccine for Children (VFC) eligibility",
    "vfc_eligibility_subtext": "Your child may be able to get no-cost vaccines through CDC’s Vaccines for Children (VFC) Program at healthcare providers offices, pharmacies and health clinics that are enrolled in the VFC Program."
  },
  "gender_key": {
    "male": "Lab",
    "female": "Dheddig",
    "cisgender": "Hal dareele",
    "non_binary": "Koox isku raacday jinsiyad shakhsiyadeed ama aan ahayn laba jinsiile",
    "other": "Mid kale",
    "prefer_to_self_describe": "Jinsiga lama qorin",
    "prefer_not_to_disclose": "Diid inaad jawaab bixiso",
    "transgender_male": "Nimow naag/ Nin dhaqanka haweenka leh",
    "transgender_female": "Naag sida raga u dhaqanto/ Naag dhaqan rag leh",
    "unknown": "Lama garanayo",
    "non_binary_only": "Aan ahayn lab ama dhedig",
    "intersex": "Labeeb",
    "transgender": "Nimow naag",
    "gender_fluid": "Jinsi dareere ah",
    "not_applicable": "Aan khusayn"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Hindida Mareykanka ama U dhashay Alaska",
    "american_indian_central_america": "Hindida Ameerikaanka ah ee ka timid Koonfurta ama Bartamaha Ameerika",
    "asian": "Aasiya",
    "black": "Madow ama Afrikaan Ameerikaan ah",
    "latinx": "Latino, Latinx ama Hisbaanik",
    "middle_eastern_north_african": "Bariga Dhexe ama Waqooyiga Afrika",
    "native_hawaiian_pacific_islander": "U dhashay Hawaii ama Jasiiradaha Baasifigga",
    "white_european": "Caddaan ama Caucasian",
    "unknown": "Lama karanayo",
    "prefer_not_to_disclose": "Doorbiday Inaadan Shaacin",
    "asian_indian": "Hindida Aasiya",
    "filipino": "Filibiin",
    "japanese": "Jabaaniis",
    "korean": "Kuuriyaan",
    "vietnamese": "Fiyatnaamiis",
    "other_asian": "Aasiyaan kale",
    "native_hawaiian": "U dhashay Hawaiian",
    "guamanian_or_chamorro": "Guamanian ama Chamorro",
    "samoan": "Samoan",
    "other_pacific_islander": "Jasiiradaha kale ee Baasifigga",
    "chinese": "Shiinaha",
    "help": "Jinsiyaddu waxay tilmaamaysaa astaamo jireed oo loo isticmaalo in lagu kala saaro kooxaha dadka. Jinsiyaddu waxay la jaanqaadi kartaa ama lama jaanqaadi karto dhalashada, ama waddanka aad ka haysato dhalashada.",
    "subtitle": "Nidaamyada waxay u baahan yihiin inaan ururinno dhammaan macluumaadka soo socda.",
    "laotian": "Laatiin",
    "cambodian": "Kamboodiya",
    "other": "Mid kale",
    "bangladeshi": "Bangladheshi",
    "hmong": "Hmong",
    "indonesian": "Indonaysian",
    "malaysian": "Malasyaan",
    "pakistani": "Bakistaani",
    "sri_lankan": "Sirilaankan",
    "thai": "Teylaandi",
    "taiwanese": "Taywaani",
    "fijian": "Fijiyaan",
    "guamanian": "Guwaamiyaan",
    "tongan": "Tongaan"
  },
  "languages": {
    "en": "Ingiriis",
    "fr": "Faransiis",
    "es": "Español",
    "so": "Soomaali",
    "hmn": "Hmong",
    "asl": "Luqadda Dadka astaamaha qabo ee Ameerika (ASL)",
    "kar": "karen",
    "am": "Amxaaro",
    "ru": "Русский",
    "om": "Oromo"
  },
  "symptoms": {
    "fever": "Qandho ama qarqaryo",
    "cough": "Qufac",
    "muscle_aches": "Murqo xanuun",
    "severe_fatigue": "Daal aad u daran (in ka badan sidii caadiga ahayd)",
    "trouble_breathing": "Neefsashada oo dhib ku ah",
    "diarrhea": "Shuban",
    "loss_of_smell": "Urka oo luma",
    "loss_of_taste": "Dhadhanka oo luma",
    "shortness_of_breath": "Neefta oo ku qabata ama neefsashada oo dhib kugu noqota",
    "headache": "Madax xanuun",
    "sore_throat": "Cune xanuun",
    "congestion": "Ciriiri ama sanka oo dareera",
    "nausea": "Lalabbo ama matag",
    "close_contact": "Inaan xiriir dhow la yeelato qof cudurka qaba*",
    "helper_text": {
      "close_contact": "*Miyaad xiriir dhow la yeelatay qof xanuunka qaba (within 6 feet for at least 15 minutes) oo qof lagu xaqiijiyay xanuunka COVID-19?"
    },
    "suspected_exposure": "Soo -gaadhitaan la tuhunsan yahay",
    "none": "Aan astaamo la hayn"
  },
  "instructions": {
    "title": "Waad diiwaangashan tahay laakiin ka hor intaadan tegin ...",
    "p1": "Boggan waxaa ku jira macluumaad ku saabsan ballantaada iyo sidoo kale lambarkaaga ballanta.",
    "appointment_scheduled": "Ballankaaga waa la qorsheeyay",
    "verify_info": {
      "title": "Xaqiiji Macluumaadkaaga",
      "p1": "Waxaa muhiim ah in la ansixiyo macluumaadkaaga lagaala soo xiriirayo si aad si dhaqso ah ugu gasho boggaaga bukaanka.",
      "p2": "Xaqiijin ayaa loo diray emaylkaaga. Fadlan guji xiriiriyaha lagula wadaagay.",
      "p3": "Haddii aad u baahan tahay inaad beddesho macluumaadkaaga lagaala soo xiriirayo, fadlan riix badhanka caawinta."
    },
    "verified": "La xaqiijiyay",
    "not_verified": "Aan La Xaqiijin",
    "resend_text": "Ma heshay xiriiriyaha?",
    "resend": "Markale dib u dir",
    "skip": "Ka bood Ansixinta & tusi Xaqiijinta",
    "name": "Magaca",
    "appointment_details": "Ballanta",
    "date_and_time": "Taariikhda iyo Waqtiga",
    "add_to_calendar": "Ku dar Kalanderka",
    "instructions": "Tilmaamaha Guud",
    "successfully_flash": "Si guul leh ayaa loo sameeyay ballanta!",
    "success_flash_email": "Si guul leh ayaa loo xaqiijiyey iimaylka",
    "success_flash_phone": "Si guul leh loo xaqiijiyay lambarka taleefanka",
    "mail_order": "Waxaad xirmadaada imtixaanka ku heli doontaa boostada toddobaadka gudihiisa.",
    "at_home": "Daryeel bixiye caafimaad ayaa imaan doona gurigaaga waqtigaan",
    "at_home_instructions": "Tilmaamaha Guriga",
    "mail_order_instructions": "Tilmaamaha Dalabka Boostada",
    "request_additional_appointment": "Ballanta la socoshada buugga",
    "book_additional_appointment": "Ballan Kale samayso",
    "confirmation_code": "Koodhka Xaqiijinta",
    "completed": "La dhammaystiray",
    "appointment_barcode": "Barcodka Ballanta",
    "dependent_links": "Xidhiiriyaasha Ballanta Qoyska",
    "on_demand_title": "Waad diiwaangashan tahay laakiin ka hor intaadan tegin ...",
    "save_link": "Kaydi xiriiriyahan",
    "verify_phone": "Xaqiiji lambarka taleefankaaga",
    "verify_phone_description": "Waxaa muhiim ah in la xaqiijiyo macluumaadkaaga lagula soo xiriirayo si aad si dhaqso ah ugu hesho diiwaanadaada. Farriin qoraal oo xaqiijin ah ayaa loo diray lambarkaaga.",
    "verify_email_description": "Waxaa muhiim ah in la xaqiijiyo macluumaadkaaga lagula soo xiriirayo si aad si dhaqso ah ugu hesho diiwaanadaada. Iimayl xaqiijin ayaa loo diray cinwaankaaga iimaylkaaga.",
    "information": "Macluumaadka Diiwaangelinta",
    "follow_up_appointment": "Dabagalida balanta",
    "get_directions": "Hel Jihooyinka",
    "cancel": "Wakhti kale kadhig Ballanta",
    "reschedule_appointment": "Wakhti kale ka dhig balamaha",
    "reschedule_linked_appointments": "Wakhti kale ka dhig balamaha",
    "no_slots": "Waqti kale lama heli karo",
    "check_results": "Hubi boga Bukaanka",
    "follow_up_modal_header": "Fadlan Jadwal u samee Qadarkaaga 2aad",
    "are_you_sure_you_want_to_cancel": "Ma hubtaa inaad rabto inaad baajiso ballantan?",
    "please_choose_cancellation_reason": "Fadlan dhanka hoose ka dooro sababta joojinta balanta",
    "additional_details": "Fadlan ku dar wixii faahfaahin dheeraad ah xagga hoose",
    "errors": {
      "missing_cancellation_reason": "Fadlan dooro sababta baajinta"
    },
    "verify_email": "Xaqiiji iimaylkaaga",
    "redcap_url": "Guji si aad u dhamaaystirto xog uruurintaada",
    "verify_contact_information": "Xaqiiji Macluumaadkaaga lagula soo xiriirayo Xiriirka",
    "please_also": "Fadlan sidoo kale",
    "new_title": "Xaqiijinta {{name}}",
    "contact_method": "Habka xiriirka",
    "next_steps": {
      "title": "Tilaabada xiigta",
      "p1": "Waxaad haysataa xirmo baaritaan oo ay tahay inaad ka shaqaysiiso. Markaad diyaar u tahay inaad baaritaanka iska qaado, raac lifaaqa lagu soo diray iimaylkaaga si aad ugu noqoto boggan oo aad uga shaqaysiiso xirmada hoose.",
      "administered": "Waan kula soo xiriiri doonaa marka xirmadaada soo gaaro shaybaarka oona natiijooyinkaaga diyaar yihiin. Sida caadiga ah natiijooyinka waxa ay qaataan 3-5 maalmood kadib marka ay soo gaaraan shaybaarka. Waxaad ka fiirin kartaa natiijooyinkaaga boggaaga [Taariikhda Caafimaadka]({{link}}) markay diyaar yihiin.",
      "resulted": "Natiijooyinkaaga waxaa laga heli karaa boggaaga [Taariikhda Caafimaadka]({{link}}).",
      "shipping_information": "Macluumaadka dhoofinta"
    },
    "save_this_page": {
      "title": "Kaydi boggaan",
      "p1": "Isticmaal boggan si aad uga warbixiso is baaritaankaaga ama aad natiijooyinka uga aragto taariikhda caafimaadka.",
      "p2": "Si aad u gasho boggaan mustaqbalka guji lifaaqa ku jira iimaylkaaga xaqiijinta ama qoraalka SMS-ka.",
      "p3": "Waxaad ku kaydin kartaa boggan adiga oo ku calaamadaynaya buug-sameeyaha, ku daraya shaashadda guriga ama nuqlaya lifaaqa meel nabdoon."
    },
    "show_my_barcode": "Itusi barcode-kayga",
    "my_account": "Koontadayda",
    "register_another": "Diiwaangeli ka qaybgale kale",
    "update_vaccine": "Cusboonaysii macluumaadka talaalka",
    "medical_history": "Taariikhda caafimaadka",
    "upcoming_appointments": "Upcoming appointments",
    "reschedule": "Reschedule",
    "resend_confirmation": "Resend confirmation",
    "appointment_details_v2": "Appointment details",
    "confirm_cancellation": "Confirm cancellation",
    "confirm_cancellation_question": "Are you sure you want to cancel this appointment?",
    "select_new_appointment_time_below": "Select a new appointment time below. If you need to modify the services or appointment location, please cancel this appointment and schedule a new one.",
    "no_take_me_back": "No, take me back",
    "yes_cancel_appointment": "Yes, cancel appointment",
    "update_appointment": "Update appointment",
    "select_new_appointment_time": "Select new appointment time",
    "clinic": "Clinic",
    "services": "Services",
    "appointment_missed": "Appointment missed",
    "appointment_canceled": "Appointment canceled"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Kodhka galitaanka waa khalad"
    },
    "landing": {
      "p1": "Si aad u aragto natiijooyinkaaga, fadlan gal:",
      "access_code_label": "Kodhka gelitaanka",
      "submit_button": "Gudbi",
      "recent_results": "Natiijooyinkaagii ugu dambeeyay"
    },
    "view_results": {
      "headline": "Natiijooyinka Baaritaanka {{name}}",
      "reregister": "Dib isu diiwaangeli",
      "status": {
        "likely_positive": "Natiijooyin aan Qeexnayn",
        "test_not_performed": "Dib u baarid ayaa loo Baahan Yahay",
        "results_ready": "Natiijooyinka waa Diyaar",
        "processing": "Shaqada way socotaa",
        "test_status": "Xaaladda Baaritaanka",
        "test_result": "Natiijada Baaritaanka",
        "administered": "Maamulay",
        "results_pending": "Natiijooyinka la sugayo",
        "test_results": "Natiijooyinka baaritaanka"
      },
      "at": "{{address}}"
    },
    "result_label": "Natiijo",
    "result": {
      "flu_a": {
        "result_label": " Natiijada Hargab A"
      },
      "flu_b": {
        "result_label": "Natiijada hargabka B"
      },
      "covid19": {
        "result_label": "Natiijada COVID-19"
      },
      "covid": {
        "result_label": "Natiijada COVID-19"
      },
      "sars": {
        "result_label": "Natiijada SARS"
      },
      "control": {
        "result_label": "Xakamee"
      },
      "rsv": {
        "result_label": "Natiijada RSV"
      },
      "result": {
        "result_label": "Natiijo"
      },
      "hba1c": {
        "normal": "Waa caadi",
        "warning": "Heerka sokorta oo xoogaa kacsan",
        "danger": "Sokor qaba"
      },
      "lead_venous": {
        "danger": "Caadi maahan",
        "normal": "Waa caadi"
      },
      "tc": {
        "result_label": "Natijada Dhiiga Guud"
      },
      "hdl": {
        "result_label": "Natiijada Kolestaroolka Fiican"
      },
      "trg": {
        "result_label": "Natiijada Qadarka Subaga Ku Jira Dhiigaaga"
      },
      "ldl": {
        "result_label": "Natiijada Kolestaroolka Xun"
      },
      "non_hdl": {
        "result_label": "Natiijooyinka aan ahayn Kolestaroolka Fiican"
      },
      "tc_hdl_ratio": {
        "result_label": "Saamiga Dhiiga Guud/Kolestaroolka Fiican"
      },
      "glu": {
        "result_label": "Natiijada Kulukooska"
      },
      "alere_cholestech_ldx": {
        "danger": "Khatar ku jira",
        "warning": "Caadi maahan",
        "normal": "Waa caadi"
      },
      "lead": {
        "result_label": "Lead"
      },
      "zinc": {
        "result_label": "Zinc"
      },
      "lead_standard_profile": {
        "danger": "Caadi maahan",
        "normal": "Waa caadi"
      },
      "creatinine": {
        "danger": "Aan caadi ahayn",
        "normal": "Caadi"
      },
      "igg": {
        "result_label": "IgG result"
      },
      "igm": {
        "result_label": "IgM result"
      },
      "blood_glucose_fasted": {
        "normal": "Normaal",
        "warning": "Sare",
        "danger": "Sare",
        "hypoglycemia": "Hypoglycemia",
        "prediabetes": "Ka hor sonkorow",
        "diabetes": "Sonkorow"
      },
      "total_cholesterol_fasted": {
        "normal": "Normaal",
        "elevated": "Sare",
        "high": "Sare",
        "low": "Dhex"
      },
      "total_cholesterol_unfasted": {
        "normal": "Normaal",
        "elevated": "Sare",
        "high": "Sare"
      },
      "a1c_now": {
        "normal": "Normaal",
        "warning": "Dhexdaqaat",
        "danger": "Dhexdaaq"
      },
      "blood_glucose": {
        "warning": "Hooseeya",
        "normal": "Caadi",
        "prediabetes": "Kahor-sonkorow",
        "danger": "Sonkorow",
        "hypoglycemia": "Hypoglycemia",
        "medical_emergency": "Degdeg caafimaad",
        "inconclusive_1": "Aan caddayn 1",
        "inconclusive_2": "Aan caddayn 2",
        "possible_diabetes": "Suurtagal sonkorow"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      }
    },
    "documents": "Dukumentiyada",
    "self_administered": "Baaritaanka uu qofku isku sameenayo ayaa laga soo qaaday {{location}}",
    "patient": "Bukaanka",
    "medical_history": "Taariikhda caafimaadka",
    "overview_title": "Xullo baaritaan ama adeeg si aad u aragto faahfaahin dheeri ah iyo shay kasta oo dheeri ah oo loo baahan yahay. Ogow in qaar ka mid ah natiijooyinka baaritaanka laga yaabo inaan weli la helin karin ama ay diyaar noqon doonaan oo kaliya ka dib markaad la hadasho bixiye.",
    "insurance_information": "Buuxi macluumaadka caymiska",
    "contact_support": "Haddii aad u baahan tahay caawinaad, ama aad u baahan tahay inaad bedesho macluumaadka xiriirka, fadlan la xiriir taageerada.",
    "show": "Muuji",
    "hide": "Qari",
    "lab_report": "Warbixinta shaybaarka",
    "contact_provider": {
      "header": "Waxaad haysataa natiijooyin togan",
      "description": "Ma rabtaa inaad la hadasho dhakhtar si aad ugala hadasho natiijooyinkaaga iyo daawayntaada?",
      "yes_option_text": "Haa, waxaan rabaa inaan la hadlo dhakhtar",
      "no_option_text": "Maya, waan akhriyay waana fahmay natiijooyinkayga, mana rabo inaan la hadlo dhakhtar",
      "confirm_phone_number_header": "Xaqiiji lambarka taleefankaaga",
      "confirm_phone_number_description": "Fadlan xaqiiji lambarka taleefanka ugu fiican ee la-tashigaaga.",
      "confirm_button_text": "Xaqiiji",
      "consultation_confirmed_header": "La-tashiga waa la xaqiijiyay",
      "consultation_confirmed_description": "Dhakhtar ayaa kaala soo hadli doono {{phone_number}} 2-3 maalmood gudahood oo maalmo shaqo ah.",
      "acknowledgement_option_helper_text": "Wax sharxaya muhiimada daawaynta oo u ogolaanaysa isticmaalaha inuu ogaado sida balan wicitaan loo samaysto haddii ay isbedesho niyadooda.",
      "acknowledgement_confirmed_header": "Oggolaanshaha waa la xaqiijiyay",
      "acknowledgement_confirmed_description": "Wax ku saabsan muhiimadda ay leedahay in lagu daweeyo oo la ay socoto illo. Xusuusin in la-talinta ay gebi ahaanba bilaash tahay khatarkana uu u qori karo ama wax kasta oo kale oo ka caawinaya inay xalliyaan infegshanka.",
      "acknowledgement_confirmed_change_mind_text": "Haddii niyadaada isbedesho,  si fudud u guji xagga hoose \"Waxaan jeclaan lahaa la-talin\".",
      "request_consultation_button_text": "Waxaan jeclaan lahaa la-talin"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Cinwaankaagu ma waafaqsana shuruudaha u qalmitaanka goobaha barnaamijkan."
    }
  },
  "member": {
    "medical_history": "Taariikhda",
    "view_instructions": "Eeg Xaqiijinta",
    "next_appointment": "Ballanka Xiga",
    "over_18": "Waxaan caddaynayaa inaan ahay 18 jir ama aan ka weynahay",
    "choose_test_configurations_title": "Fadlan dooro adeegga (yada) aad u jeclaan lahayd balantan",
    "member_taken_error": "Isticmaalaha wuxuu horay u lahaa koonto",
    "choose_test_configurations_subtitle": "Adeegga (yada) ayaa lagu dabaqi doonaa dhammaan xubnaha qoyska",
    "service": "Adeegga",
    "group_or_location": "Kooxda/Goobta",
    "details": "Faahfaahin",
    "see_all": "Dhammaan arag",
    "no_history": "Ma jirto wax taariikh ah oo la heli karo waqtigan.",
    "medical_history_title_with_name": "{{name}} taariikhda",
    "no_dashboard": "Haddeer ma jiro wax la heli karo",
    "product": "Alaabta",
    "price": "Qiimo",
    "quantity": "Tirada",
    "total_services_selected": "Wadarta adeegyada la doortay",
    "total_price": "Wadarta qiimaha"
  },
  "or": "Ama",
  "account": {
    "errors": {
      "must_be_13": "Waa inuu jiraa 13 sano si loogu diiwaangeliyo koonto"
    }
  },
  "self_administration": {
    "title": "Maamul shakhsiyadeed",
    "self_administer_action": "Maamulid shakhsiyadeed",
    "skip_to_link": "U bood Maamulka shakhsiyadeed",
    "select_person": "Dooro qof sameeya baaritaanka",
    "adult": "Qaangaar ah",
    "child": "Ilmo",
    "checked_out": "BAARITAAN",
    "go_back": "Gadaal u laabo\r\n",
    "switch_to_scanner": "U beddel Iskaanka\r\n",
    "enter_barcode": "Geli Barcodka",
    "scan_barcode": "Iskaan Barcodka",
    "cancel": "Jooji",
    "barcode_for": "Barcodka loogu talagalay",
    "enter_barcode_manually": "Gacanta ku geli barcodka\r\n",
    "instructions": "Iskan garee ama geli barcodka ku yaal tuubada tijaabada ee hoose.\r\n",
    "regex_prefix": "Barcodka waa inuu ka kooban yahay",
    "all_completed": "La dhamaystiray: Dhammaan xubnaha qoysku way dhammaystireen qaybtan",
    "errors": {
      "no_appointment": "Dhibaatada helista ballanta",
      "barcode_in_use": "Barcode-kan hore ayaa loo isticmaalay. Haddi aanad marki hore iskaa u maamulin xidhmadan fadlan la hadal shaqaalaha goobta ku siisay xidhmada oo waydiiso xidhmo cusub. Haddii kale, dhagsii badhanka caawinta ee hoose.",
      "test_error_general": "Dhibaatada ay leedahay abuurista baaritaanka",
      "different_barcodes": "Barcode-ka aad gelisay isma laha"
    },
    "confirm_barcode_input": "Geli barcodka mar kale si aad u xaqiijiso",
    "click_to_self_test": "Halkan Guji Si Aad Isku Tijaabiso"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Liiska sugitaanka",
      "unavailable": "Wax balan ah hada lama heli karo"
    },
    "labels": {
      "address": "Cinwaanka",
      "next_appointment": "Ballanka xiga ee la heli karo",
      "services": "Adeegyada la heli karo"
    }
  },
  "waiting_room": {
    "header": "Waxaad joogtaa qolka sugitaanka; Fadlan sug inta aan kugu xiriirinayo nidaamka jadwalka.",
    "p1": "Fadlan samir lahow. Waxaan si wadajir ah u soo afjari doonnaa masiibadan.",
    "signature": "Si daacad ah",
    "p2": "Waxaan ognahay in waayo-aragnimada COVID-19 ay noqon karto mid niyad jab leh. Fadlan halkaan ku sug inta aan ka shaqeyneyno sida ugu dhaqsaha badan ee aan awoodno si aan kuugu hagno goobta si aad ballan u qabsato."
  },
  "users": {
    "send_appointment_confirmation_message": "Salaan {{full_name}}. Tani waa xusuusinta ballankaaga {{name}}. Ballankaadu waa{{date}} markay tahay {{time}} ee {{address}}.",
    "send_registration_link": "Salaan, {{full_name}}. Raac xiriiriyahan si aad isku diiwaangeliso {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "Qiyaasta Koowaad",
    "previous_appointment": "Ballankii Hore",
    "booked_appointment": "Ballankaaga",
    "second_dose": "Qiyaasta Labaad",
    "choose_second_location": "Dooro Goob Ballan oo Labaad"
  },
  "cancellation_types": {
    "cant_get_to_location": "Waxaa i haystay khilaaf wakhtiga ah",
    "timing_conflict": "Waxaan la xanuunsaday COVID-19",
    "became_ill_with_covid19": "Adeegan meel kale ayaan ka helay",
    "received_service_elsewhere": "Mid kale",
    "other": "Badalkeeda qor magaca",
    "duplicate_appointment": "Tani waxay ahayd ballan isku mid ah"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Qor Magaca",
      "type_name": "Beddelkeeda ku qor saxiixa"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Lambarkaaga gelitaanka waa",
      "please_confirm_your_email": "Fadlan xaqiiji iimaylkaaga adiga oo gujinaya badhanka hoose",
      "hello": "Salaan",
      "click_to_reschedule": "Guji si aad u tusto Tilmaamaha/Dib u jadwalaynta",
      "click_to_reschedule_on_demand": "Guji si aad u tusto Tilmaamaha"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Ballantaadii ahayd {{time}} ee {{location}} waa la joojiyay. Haddii tani tahay khalad, fadlan iimayl u dir support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Magaca Ka -qaybgalaha",
    "date": "Taariikhda",
    "signature": "Saxiixa Ka -qaybgalaha",
    "and_or": "IYO/AMA",
    "guardian_name": "Magaca Waalidka/Masuulka",
    "guardian_signature": "Saxiixa Waalidka/Masuulka"
  },
  "employer_testing": {
    "hello_name": "Salaan {{name}}",
    "results": {
      "negative": "Laga helay",
      "positive": "Laga waayay",
      "did_not_result": "Lama garanaayo",
      "unknown": "Lama garanayo",
      "invalid": "Aan sax ahayn",
      "pending": "La sugayo"
    },
    "code_reader": {
      "scan_your_code": "Iskaan garee koodhka kuyaalo kaarkaada baaritaanka",
      "find_in_top_right_hand_corner": "Lambarka iskaanka ayaa u gaar ah baaritaankaaga waxaana laga heli karaa geeska midig ee sare ee baaritaankaaga",
      "center_code": "Ku toosi koodhka halkaan.",
      "already_used_error": "Barcodeka baaritaanka mar hore ayaa la isticmaalay. Fadlan la xiriir bixiyaha xirmada baaritaankaaga si aad u hesho taageero.",
      "click_to_scan": "Guji si aad u iskaan garayso QR kodhka",
      "scan_new_test_card": "Guji halkan si aad u iskaan garayso kaarka baaritaanka ee cusub.",
      "not_working": "Ma shaqeynayo?",
      "try_again": "Guji halkan si aad mar kale isugu daydo"
    },
    "continue": "Sii wad",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Fadlan xaqiiji taariikhda dhalashada ee ka qaybgalaha si aad usii wado",
      "contact_administrator": "Haddii macluumaadka la soo baxay uusan sax ahayn, fadlan la xiriir maamule si uu kuu caawiyo",
      "error": "Taariikhda dhalashada ee la galay sax ma ahan - fadlan isku day mar kale ama la xiriir maamulaha goobtaada si loo cusbooneysiiyo tan."
    },
    "result_entry": {
      "code_is_registered": "Koodhkaagu wuu diiwaangashan yahay.",
      "take_the_test": "Iska qaad baaritaanka",
      "follow_the_instructions": "Raac tilmaamaha la socda baaritaankaaga oo bilow wakhtigaada 15 -ka daqiiqo markaad galiso suufka sanka ku jira kaarka baaritaanka.",
      "cant_find_instructions": "Ma heli kartid tilmaamaha?",
      "view_instructions": "Eeg tilmaamaha Binax Hada",
      "start_timer": "BILAW WAKHTI 15 DAQIIQA AH  (OPTIONAL)",
      "submit_within_15": "Talo: Ku soo gudbi natiijooyinkaaga 15 daqiiqo gudahood",
      "enter_test_results": "Geli Natiijooyinka baaritaanka",
      "choose_a_result": "Dooro ikhtiyaarka qeexaya natiijada kaarkaaga baaritaanka",
      "submit": "Gudbi",
      "must_log_result_and_photo": "Waa inaad qortaa natiijada oo aad sawir ka qaadataa baaritaankaaga si aad u sii wadato",
      "submit_within_15_v2": "Talo: Soo gudbi natiijooyinkaaga ka dib marka baaritaanku socdo 15 daqiiqo",
      "click_to_take_photo": "Guji si aad sawir uga qaado",
      "results_may_be_invalid": "Natiijooyinka baaritaanka waxay noqon karaan kuwo aan sax ahayn"
    },
    "summary": {
      "title": "Soo -koobidda Natiijooyinka",
      "negative_subtitle": "ma qabtid COVID-19",
      "negative_message": "Waxaan u dirnay ogeysiis loo -shaqeeyahaaga in laguu fasaxay inaad ku soo noqoto goobta shaqada.",
      "positive_subtitle": "Waan ka xunnahay, laakiin waxaa lagaa helay COVID-19",
      "positive_message": "Waxaan u dirnay ogeysiis loo-shaqeeyahaaga oo ah inaad qabtid COVID-19.",
      "what_should_you_do": "Maxaad samayn doontaa:",
      "employer_name_resources": "Khayraadka Magaca Loo -shaqeeyaha:",
      "follow_cdc_guidelines": "Raac Tilmaamaha la talinta ee CDC"
    },
    "clarifying_results": {
      "title": "Cadaynta Natiijooyinkaaga",
      "which_did_your_result_most_look_like": "Midkee natiijadaada inta badan u ekayd?",
      "no_lines": "Wax layn ah lama arag",
      "blue_control_line_only": "Khadka koontaroolka buluuga ah oo kaliya",
      "pink_sample_line_only": "Khadka sambalka casaanka ah oo kaliya",
      "blue_control_line_and_pink_sample_line": "Khadka koontaroolka buluugga ah iyo khadka sambalka casaanka/buluug-guduudka",
      "still_not_sure": "Wali Ma Hubi",
      "submit_results": "Soo Gudbi Natiijooyinka",
      "thank_you": "Mahadsanid!",
      "clarification_received_message": "Caddaynta natiijadaada waa la helay.",
      "only_pink_line": "Kaliya xariiqinta casaan ah",
      "no_lines_v2": "Xariiqin Malaha"
    },
    "return_to_confirmation": "Ku noqo bogga xaqiijinta"
  },
  "preferred_language": {
    "title": "Luqadda aad doorbidayso",
    "subtitle": "Luuqdee ayaad door bidaysaa?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Taxanaha Tallaalkii Hore",
    "one_dose": "Ballan ka qabso qiyaas gaar ahaaneed. Gaar ahaan, tani waa taydii",
    "title": "Xulashada Qiyaasta",
    "first_dose": "Qiyaasta koowaad",
    "second_dose": "Qiyaasta labaad",
    "single_dose_title": "Hal qiyaas",
    "additional": "Dheeraad ah",
    "booster": "Kobciye",
    "supplemental": "Dheeraad ah",
    "third_dose": "Keliya kuuraha/qiyaasta seddexaad",
    "no_vaccine": "Ma'aha midna kuwa sare"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "Waa inuu lahaadaa qaab {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "Waa inuu lahaadaa qaab {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "Baaritaanka lama helin"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "Isticmaalaha wuu jiraa loomana oggola inuu cusboonaysiiyo"
            },
            "date_of_birth": {
              "invalid_date": "Waa inuu lahaadaa qaab {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} ma jiraan, ka qaado qiimayaasha la aqbali karo ee soo socda: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} ma jiraan, ka qaado qiimayaasha la aqbali karo ee soo socda: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "Jinsiga ma jiro, ka qaado qiimayaasha la aqbali karo ee soo socda: {{genders}}"
            },
            "phone_number": {
              "invalid": "Waa lambar aan sax ahayn"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} ma jiro, ka qaado qiimayaasha la aqbali karo ee soo socda: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "Ma la buuxin karo haddii jinsigu uusan 'doorbideynin_inuu_ si shakhsi _ah u qeexo'"
            },
            "sex": {
              "unknown_sex": "Jinsiga ma jiro, ka qaado qiimayaasha la aqbali karo ee soo socda: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "Jihaynta_ jinsiga ma jiro, ka qaado qiimayaasha la aqbali karo ee soo socda: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "Xaaladdu ma aha mid sax ah, ka qaad qiimayaasha la aqbali karo ee soo socda: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} ma jirto, ka qaad qiimayaasha la aqbali karo ee soo socda: {{races}}",
                "other": "{{unknown_races}} ma jiraan, ka qaado qiimayaasha la aqbali karo ee soo socda: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "Mudnaanta aan ku filnayn si loo hagaajiyo doorka"
            },
            "user_id": {
              "unknown_user": "Isticmaalaha lama helin"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON aan sax ahayn"
            },
            "user_id": {
              "unknown_user": "Isticmaalaha lama helin"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "Goobta balanta lama helin"
            },
            "date_of_birth": {
              "invalid_date": "Waa inuu lahaadaa qaabka {{date_format}}"
            },
            "email": {
              "duplicated": "Hore ayaa loo adeegsaday"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} ma jiraan, ka qaado qiimayaasha la aqbali karo ee soo socda: {{ethnicities}}ha}\r\n",
                "other": "{{unknown_ethnicities}} ma jiraan, ka qaado qiimayaasha la aqbali karo ee soo socda: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "Jinsiga ma aha mid jiro, ka qaado qiimayaasha la aqbali karo ee soo socda: {{genders}}"
            },
            "phone_number": {
              "invalid": "Waa lambar aan sax ahayn"
            },
            "population": {
              "unknown_population": "{{unknown_population}} ma jiro, ka qaado qiimayaasha la aqbali karo ee soo socda: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "Ma la buuxin karo haddii jinsigu uusan 'doorbideynin_inuu_ si shakhsi _ah u qeexo'"
            },
            "sex": {
              "unknown_sex": "Jinsiga ma jiro, ka qaado qiimayaasha la aqbali karo ee soo socda: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "Jihaynta_Jinsiga ma jiro, ka qaado qiimayaasha la aqbali karo ee soo socda: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} ma jirto, ka qaado qiimayaasha la aqbali karo ee soo socda: {{races}}",
                "other": "{{unknown_races}} ma jiro, ka qaado qiimayaasha la aqbali karo ee soo socda: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "Iyo cinwaanka ayaa mar horeba lagu isticmaalay Goob kale oo jirta"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Isbaanish ama Laatiino/a",
    "not_hispanic": "Ma aha Hisbaanik ama Laatiino/a",
    "hispanic_expanded": {
      "other": "Isbaanish kale, Laatiino/a ama Isbaanish asal ahaan",
      "mexican": "Meksiko, Meksikaan Ameerika, Chicano/a",
      "puerto_rican": "U dhashay Puerto Rica",
      "cuban": "Kubaan"
    },
    "subtitle": "Bulshooyinka waxay nooga baahan yihiin inaan ururinno macluumaadka soo socda",
    "help": "Isirka waxay tilmaamaysaa astaamo isku mid ah sida luqadda, caadooyinka dhaqanka, diinta, iyo astaamaha kale ee loo adeegsado in lagu kala saaro kooxaha dadka. Waxaa laga yaabaa inayna la jaanqaadi karin aqoonsiga jinsiyadda sidoo kale.",
    "unknown": "Lama yaqaan",
    "title": "Isirka"
  },
  "sex_at_birth": {
    "question": "Jinsi",
    "female": "Dheddig",
    "male": "Lab",
    "help": "Jinsiga waxaa loola jeedaa waxa ku qoran shahaadada dhalashada.",
    "non_binary": "Aan ahayn binary",
    "subtitle": "Xeerarku waxay u baahan yihiin inaan ururinno dhammaan macluumaadka soo socda.",
    "decline": "Diid si aad ugu jawaabto",
    "unknown": "Lama garanaayo"
  },
  "gender": {
    "help": "Aqoonsiga Jinsiga waxaa loola jeedaa sida adiga shakhsi ahaan aad u aqoonsato. Waxaa laga yaabaa ama aan la jaanqaadi karin jinsiga laguu qoondeeyey markii aad dhalatay.",
    "subtitle": "Haddii aad jeclaan lahayd inaad ku darto macluumaad ku saabsan aqoonsigaaga jinsiga fadlan sidaas samee."
  },
  "sexual_orientation": {
    "title": "Jihaynta galmada",
    "subtitle": "Haddii aad jeclaan lahayd inaad ku darto macluumaad ku saabsan jihadaada galmada, fadlan sidaas samee.",
    "gay": "Khaniis, khaniisad, ama Qof khaniis ah",
    "heterosexual": "Qof dareenkiisu toosanyahay",
    "bisexual": "Labada dareenle",
    "questioning": "Su'aalayo/aan hubin/ma garanayo",
    "prefer_not_to_disclose": "Dooro inaadan sheegin",
    "unknown": "Lama garanaayo",
    "orientation_not_listed": "Hanuuninta liistada kuma jirto",
    "not_applicable": "Aan khusayn",
    "pansexual": "Panseksueel",
    "queer": "Kweer"
  },
  "pronouns": {
    "title": "Magacyada La Doorbiday",
    "he": "Isaga/isaga",
    "she": "Iyada/iyada",
    "they": "Iyaga/iyaga",
    "choose_not_to_disclose": "Dooro inaadan sheegin",
    "prefer_to_self_describe": "Doorbida inaaad si shakhsi ah u sharaxdid"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Shatiga Darawalnimada ama Lambarka Aqoonsiga Gobolka",
    "driver_license": "Shatiga Darawalnimada",
    "use_ssn_instead": "Isticmaal SSN beddelkeeda",
    "social_security_number": "Lambarka Amniga ee Bulshada",
    "state": "Gobolka",
    "upload_front_of_driver_license": "Soo geli dhanka hore ee shatiga darawalnimada ama lambarka aqoonsiga gobolka",
    "choose_file": "Dooro faylka",
    "no_file_chosen": "Ma jiro fayl la doortay",
    "no_identification": "Aqoonsi ma haysto",
    "insurance_card_has_a_back": "Kaarkayga caymisku wuxuu leeyahay dhabar",
    "upload_front_of_insurance_card": "Soo geli dhanka hore ee kaadhkaaga caymiska.",
    "front_of_insurance_card_uploaded": "Xagga hore ee kaadhkaaga caymiska waa la soo galiyay.",
    "insurance_card": "Kaarka caymiska",
    "insurance_card_back": "Kaarka caymiska ee gadaale",
    "back_of_insurance_card_uploaded": "Dhabarka dambe ee kaadhkaaga caymiska waa la soo galiya"
  },
  "quidel": {
    "certify_text": "Waxaan caddaynayaa in aan mar keliya galayo natiijooyinka baaritaankan.",
    "entering_results": "Gelinta Natiijooyinkaaga",
    "review_instructions_1": "Si taxaddar leh u eeg tilmaamaha",
    "review_instructions_2": "Waxaad dib u eegi kartaa tilmaamaha la yimid baaritaankaaga, akhri tilmaamaha tallaabo-tallaabo, ama daawo muuqaalka wax-barashada ee hoose:",
    "read_instructions": "Akhri Hordhaca",
    "watch_video": "Daawo Muuqaalka",
    "timer": "Bilow wakhtiga saacadda ka dib markaad ku walaaqdo suufka tuubada. Wakhtiga saacaddu waxay joogsan doontaa 1 daqiiqo ka dib (si aad suufka uga saarto tuubada). Fadlan ogow: Waa inaad dib u bilowdaa wakkhtiga saacada si aad u bilowdo 10ka daqiiqo ee natiijada baaritaankaaga.",
    "start_timer": "Bilow wakhtiga saacadda (Ikhtiyaar)",
    "restart_timer": "Jooji oo dib u bilow",
    "resume": "Dib u bilow",
    "one_minute_countdown": "1 daqiiqo ayaa ka soo wareegtay! Ka saar suufka tuubada oo dib u bilow wakhtiga saacadda.",
    "take_photo": "Sawir ka qaad Faliiq Baaritaankaada",
    "photo_warning": "Bixinta sawirku waa lagama maarmaan si aad u sii wado.",
    "enter_results": "Geli Natiijooyinka Baaritaanka",
    "choose_result": "Dooro ikhtiyaarka sida ugu fiican uga tarjumaya falliiqa baaritaankaada. Caawinaad ma u baahan tahay? Guji halkan si aad u aragto tusaalooyin baaritaano ah.",
    "positive": "Laga helay",
    "negative": "Laga waayay",
    "unknown": "Lama garanaayo",
    "clarify": "Cadaynta Natiijooyinkaaga",
    "strip_question": "Waa kuwee falliiqa baaritaankaagu aad ugu ekaa?",
    "no_lines": "Leeman ma jiro",
    "pink_line": "Leenka casaanka ah kaliya",
    "not_sure": "Wali ma hubo"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Natiijada sawirka",
    "retake_photo": "Dib u qaad Sawirka",
    "capture_photo": "Qaad Sawir",
    "confirm_information_correct": "Waxaan xaqiijinayaa in xogta kore ay sax tahay.",
    "submit": "Gudbi",
    "title": "Geli natiijooyinka loogu talagalay {{name}}",
    "subtitle": "Uma baahnid inaad soo dejiso ama isticmaasho barnaamijka ee iHealth COVID-19.",
    "instruction_title": "Eeg tilmaamaha iHealth",
    "instruction_guide": "Akhri tilmaamaha",
    "video_guide": "Daawo muqaalka",
    "add_photo": "Ku dar sawir",
    "confirm_result": "Xaqiiji natiijada",
    "result_warning": "Dooro natiijada si aad u gudbiso",
    "confirm_warning": "Xaqiiji natiijada si aad u gudbiso",
    "administered_at_label": "Goormeed samaynaysaa baaritaanka?",
    "instruction_subtitle": "Fiiri tilmaamaha la socda baaritaankaaga ama dib uga eeg tilmaamaha halkan."
  },
  "public_test_groups": {
    "title": "Qalabka baaritaanka COVID-19",
    "appointment_recoveries_title": "Soo noqoshada?",
    "appointment_recoveries_button_text": "Raadi xiriiriyahayga",
    "search_test_group_title": "Markii ugu horeysay?",
    "search_test_group_button_text": "Hadda isdiiwaangeli",
    "title_2": "Diiwaangelin fudud iyo soo gudbinta warbixinta",
    "step_one": "Diiwaangelinta akoonka",
    "step_two": "Qaado baadhitaanka saliva sample si aad dib ugu soo celiso",
    "step_three": "Ka eeg natiijooyinka onlaynka",
    "title_3": "Is diwaangeli si aad u dhaqan galiso qalabkaaga",
    "population_title": "diiwaangelinta",
    "population_button": "Dhaqan galin",
    "faq_subtitle": "Wax dheeri ah baro",
    "faq_title": "Su'aalaha inta badan la is waydiiyo",
    "faq_1": "Sideen u soo celiyaa qalabkayga baaritaanka?",
    "faq_1_footer": "Eeg tilmaamaha iskuxiran ee dublada baaritaanka Saliva Direct",
    "faq_2": "Horeba waan isu diiwangeliyay laakiin waan ilaaway akoonkayga.",
    "faq_3": "Sideen u dhaqan galiyaa qalabkayga?",
    "faq_2_subtitle": "Maadaama aad tahay isticmaale soo laabanaya, waxaad xaqiijin kartaa warbixintaada adigoo isticmaalaya badhanka '**Raadi xiriiriyahayga**' ee kore.'",
    "faq_3_subtitle": "Fadlan kor mar nidaamka akoonka Primary.Health adigoo isticmaalaya doorashooyinka hoose:",
    "footer_text_1": "Alaabadan FDA ma aysan fasixin ama maysan ansixinin, laakiin waxay FDA u oggolaatay isticmaalka degdega ah ayadoo raacaysa xeerka EUA;'",
    "footer_text_2": "Alaabtan waxa loo oggolaaday oo keliya ururinta iyo dayactirka shaybaarrada saliva si ay u caawiso ogaanshaha aashitada nuclei-ka ee SARS-CoV-2, looguma talagelin fayrasyo ​​kale ama cudur-sida kale;'",
    "footer_text_3": "Isticmaalka degdega ah ee alaabtan waxa kaliya oo la ogolyahay inta lagu jiro cadeynta in duruufuhu jiraan iyagoo cadaynaya oggolaanshaha isticmaalka degdega ah ee qalabka caafimaadka iyadoo la eegayo Qeybta qodobka 564(b)(1) ee Sharciga Cunnada, Dawooyinka iyo is Quruxinta ee Federalka, 21 USC § 360bbb-3(b)(1), hadii aan cadeynta la joojin ama ogolaanshaha si dhaqsi ah loola noqon.",
    "description": "Si ay kuugu habboonaato, waxaad dhaqangelin kartaa qalabkaaga baaritaanka adigoo isticmaalaya badhanka hoose:",
    "subtitle": "Markii ugu horeysay ee Bilowga?",
    "subtitle_1": "Wax ku saabsan qalabkaada baaritaanka COVID-19",
    "faq_1_1": "Geli tuubada sanbalka ah ee xidhan bacda shaybaarka",
    "faq_1_2": "Geli bacda sheybaarka sanduuqa ku calaamadsan SalivaDirect ee ay ku imatay. Sanduuqa ku calaamadsan SalivaDirect waxaad dhex galisaa FedEx UN 3373 Pak.",
    "faq_1_3": "Ka bixi leeka koolada ee FedEx UN 3373 Pak oo si adag hoos ugu riix si aad si adag ugu xidho Pak.",
    "faq_1_4": "Fadlan keen sambalkaada shaybaarkaaga isla maalinta aad qaadatay iyo ka hor qaaditaankii ugu dambeeyay. Ha ku keenin sambalkaada sanduuqa keenista sabtida.",
    "faq_1_5": "Ka booqo websaydka FedEx halkan **[fedex.com/labreturns](https://www.fedex.com/labreturns)** si aad u aragto goobaha sanduuqa wax lagu rido aadna u soo qadato  jadwalka.",
    "demo": {
      "title": "COVID-19 guriga-guriga tijaabi macluumaad dheeri ah iyo tilmaamo",
      "online_instructions": "Tilmaamaha khadka tooska ah",
      "download_instructions": "Soo deji xaashida tilmaamaha",
      "begin": "bilaabid",
      "instructions": {
        "welcome_title": "Ku soo dhawoow guriga barta madasha COVID-19",
        "welcome_text_1": "Kahor intaadan bilaabin baaritaanka, dhaq gacmahaaga ama isticmaal gacmo nadiifiyaha.",
        "welcome_text_2": "Hubi in gacmahaagu engegan yihiin ka hor inta aanad bilaabin.",
        "continue": "Sii wad",
        "back": "Dib u noqo",
        "step_one_title": "Tallaabada 1aad: Hubi qalabkaaga baarista",
        "step_one_text_1": "Hel walxaha xirmada:",
        "step_one_text_2": "Waxaa lagu talinayaa in gacan-gashiyo (lama bixin) sidoo kale la isticmaalo inta lagu jiro baaritaanka.",
        "step_two_title": "Tallaabada 2aad: Ku rid Dhuunta Dhuun-hayaha",
        "step_two_text_1": "Ka saar furka hal **DHUUN** oo geli **DHUUN-HAYAHA**.",
        "step_two_text_2": "**OGOW:** Isticmaalka galoofyada ayaa lagula talinayaa.",
        "step_five_title": "Tallaabada 5: Ka saar dhacaan qaadaha dhuunta",
        "step_five_text_1": "**HAL DAQIIQO** ka dib, ka saar dhacaan **Qaadaha DHUUNTA** adiga oo madaxa dhacaan qaadaha ku xoqaya gidaarka gudaha ee dhuunta si aad uga miirto dareeraha ugu badan ee suurtogalka ah.",
        "step_five_text_2": "Ku tuur dhacaan qaadaha qashinka.",
        "step_six_title": "Tallaabada 6: Fur aalada xariijimaha baarista",
        "step_six_text_1": "Si taxadir leh u fur bacda **AALADA XARIIJINTA BAARISTA** adigoo bacda kala jaraya oo u hay **AALADA XARIIJINTA BAARISTA** sida lagu tilmaamay.",
        "step_seven_title": "Tallaabada 7: Gali aalada xariijimaha baarista dhuunta dhexdeeda",
        "step_seven_text_1": "Gali **AALADA XARIIJIMAHA BAARISTA DHUUNTA** dhexdeeda iyadoo fallaadhihii hoos u jeedaan. Uga tag aalada xariijinta **DHUUNTA** dhexdeeda ilaa **TOBAN DAQIIQO** oo **DHAMAYSTIRAN** - ha qaban hana ka bixin.",
        "step_eight_title": "Tallaabada 8: Ka saar aalada xariijimaha baarista dhuunta dhexdeeda",
        "step_eight_text_1": "Marka la gaaro **TOBANEN DAQIIQO**, ka saar **AALADA XARIIJIMAHA BAARISTA DHUUNTA**. Marka xigta, u geli **AALADA XARIIJIMAHA BAARISTA** sida lagu sharaxay Tallaabooyinka 10- 12. Hubi in **AALADA XARIIJIMAHA BAARISTA** uu ku yaal meel siman oo iftiin wanaagsan leh.",
        "step_eight_text_2": "OGOW: Baaristu waxaa loogu talagalay in lagu akhriyo 10 daqiiqo. Haddii baaritaanka la akhriyo ka hor ama la akhriyo in ka badan 5 daqiiqo ka dib wakhtiga akhrinta ee la tilmaamay, natiijadu waxay noqon kartaa mid khaldan (natiijo taban oo beenta ah, natiijotogan oo been ah, ama aan ansax ahayn) waana in baaritaanka lagu celiyaa.",
        "step_nine_title": "Tallaabada 9: Hubi Natiijooyinkaaga",
        "step_nine_text_1": "Waxaa jira saddex nooc oo natiijooyin suurtagal ah.",
        "step_nine_text_2": "Hubi Natiijo Inaad qabtid xanuunka",
        "step_nine_text_3": "Hubi Natiijo Inaadan qabin xanuunka",
        "step_nine_text_4": "Hubi Natiijo Aan sax ahayn",
        "step_ten_title": "Tallaabada 10: Hubi natiijo inaad qabtid xanuunka COVID-19",
        "step_ten_text_1": "U dhig **AALADA XARIIJINTA BAARISTA** sida Aalada xariijimaha ee hoose oo isbarbar dhig tusaalooyinka natiijada baarista ee la muujiyey.",
        "step_three_title": "Tallaabada 3aad: Dhacaan kasoo qaad dulalka sanka",
        "step_three_text_1": "Ka saar **DHACAAN-QAADAHA** duubkiisa, ka taxaddar inaadan taaban madaxa **DHACAAN QAADAHA**. madaxa madaxa **DHACAAN-QAADAHA**.",
        "step_three_text_2": "Si tartiib ah u geli **DHACAAN QAADAHA** ½ ilaa ¾ inji daloolka sanka, iyadoo ku xiran xajmiga sanka qofka. Si adag ugu xoq **DHACAAN QAADAHA** adigoo ugu dhaqdhaqaajinaya si wareeg ah agagaarka gidaarka gudaha ee **DULEELKA SANKA MID KASTA** ugu yaraan 4 jeer.",
        "step_three_text_3": "Hubso inaad ku xoqdo LABADA dalool ee sanka isla hal DHACAAN QAADE.",
        "step_three_text_5": "**OGOW:** Ku guuldareysiga in si sax ah loo qaado dhacaanka waxay keeni kartaa natiijooyin xun oo been ah.",
        "step_four_title": "Tallaabada 4aad: Geli dhacaan qaadaha dhuunta",
        "step_four_text_1": "Isla markiiba **DHACAAN QAADAHA** geli dareeraha ku jira gudaha **DHUUNTA**, oo hubi in uu taabanayo xagga hoose. Ku walaaq 3-4 jeer.",
        "step_four_text_2": "Ku dhaaf dhacaan qaadaha milka **HAL DAQIIQO**.",
        "step_four_text_3": "**OGOW:** Haddii dhacaan qaaduhu uu ku jiro milka in ka badan 10-daqiiqo waa inaan la isticmaalin.",
        "step_three_text_4": "**OGOW:** Haddii aad dadka kale dhacaan sankooda aad ka qaadayso, fadlan xidho maaskaro wejiga. Markaad carruurta ka qaadayso dhacaan, waxaa laga yaabaa inaadan u baahnayn inaad suufka geliso meel fog oo sanka ah. Carruurta aadka u yaryar, waxa laga yaabaa inaad u baahato qof kale si uu madaxa ilmaha u dejiyo markaad dhacaan ka qaadayso sanka."
      },
      "choose_language": "Dooro luqadaada"
    }
  },
  "self_resulting": {
    "report_result": "Ka warbixi natiijada",
    "report_result_subtitle": "Haddii ururkaagu bixiyo baaritaan shakhsi ahaanedd, oo aad diyaar u tahay inaad ka warbixiso natiijada, fadlan guji badhanka hoose.",
    "need_help": "Ma u baahan tahay caawimaad?",
    "assistance": "Haddii aad u baahan tahay caawimaad, ama aad rabto in aad beddesho macluumaadkaaga xidhiidhka, fadlan la xidhiidh Xarunta caawimaada.",
    "contact_support": "Taageerada la xidhiidh",
    "save_link": "Keydi xidhiidhkan si aad hadhow uga warbixiso natiijooyinka",
    "send_via_email": "Ku dir xiriirinta iimaylka",
    "send_via_text": "Ku dir xiriirinta qoraalka SMS",
    "copy_link": "Koobi garee xiriirinta",
    "resend_email": "Dib u soo dir xaqiijinta iimaylka",
    "phone_verified": "Telefoonku waa la xaqiijiyay",
    "add_vaccination_status": "Kudar/Cusbooneysii Heerka Talaalka COVID-19",
    "terms_of_service": "Shuruudaha adeegga",
    "verify_contact_information": "Xaqiiji macluumaadkaaga xiriirka si aad u hesho natiijooyinka iyo isgaarsiinta. Haddii aad u baahan tahay inaad beddesho macluumaadkaaga xidhiidhka, fadlan la xidhiidh Xaruntayada Caawimaada.",
    "resend_text": "Dib u soo dir xaqiijinta qoraalka SMS",
    "loading": "Galayaa",
    "add_to_account": "Kudar Koontada",
    "hi": "haye sidee tahay",
    "email_verified": "Iimaylka waa la xaqiijiyay",
    "go_to_my_account": "Tag akoonkayga",
    "activate_kit": "Shaqaysiinta weydiimaha imtixaan",
    "report_custom_result": "Ka warbixi natiijada: {{test}}",
    "activate_custom_test": "Ka-shaqeysii/bilaaw baaritaan: {{test}}",
    "update_vaccination_status": "Cusbooneysii xaalada tallaalka",
    "order_a_test_kit": "Dalbo qalab baaritaan"
  },
  "enter_information": {
    "contact_info": "Geli macluumaadkaaga xiriirka",
    "label": "Telefoon ama iimayl",
    "invalid": "Kani maaha iimayl ama lambar taleefoon oo sax ah.",
    "send": "Waxaan kuugu soo diri doonaa koodhka taleefankaaga ama iimaylkaaga",
    "legal": "Markaad geliso lambarka taleefankaaga ama iimaylka, waxaad ogolaatay **[Terms of Service](https://primary.health/terms-of-service/)** iyo **[Privacy Policy](https://primary.health/privacy-policy/)**.",
    "subtitle": "We will send you a text or email to help you find your records or link to report a test result!",
    "find_link": "diiwaan gashan? Hel xiriiriyahaaga",
    "not_registered": "Aan diwaan gashanayn? Raadi goobtaada",
    "search_by_keyword": "Ku raadi magaca, erayga muhiimka ah, magaalada, lambarka boostada, ama baaq sireedka ururka!",
    "search": "Raadi",
    "register_here": "Register here"
  },
  "contact_support": {
    "invalid": "Xog aan sax ahayn",
    "invalid_explanation": "Waxaad dhawaan codsatey xiriiriyaha gelitaanka Primary.Health. Si kastaba ha ahaatee,",
    "never_registered": "Aan waligii is diiwaan gelin? Fadlan la xidhiidh bixiyaha baaritaankaada isku xirka diiwaan gelinta, ama ka hel goobtaada hoose.",
    "get_help": "Waxaad heli kartaa caawimo markaad isku aadiso diiwaankaaga iyo macluumaadkaaga xidhiidhka",
    "get_help_calling": "ama adigoo wacaya",
    "invalid_explanation_bold": "macluumaadka aad bixisay waxa uu ahaa mid aan sax ahayn.",
    "get_help_label": "Hel caawin",
    "mobile": {
      "invalid_explanation": "Lama helin isticmaaleyaal lagu dari karo akoonkaaga",
      "get_help": "Haddii aad qabto su'aalo dheeraad ah, fadlan la xiriir",
      "reason": "Dhammaan isticmaalayaasha Primary.Health uma qalmaan muuqalka/sifada cusub ee akoonadayada. Markaad u qalanto, waxaad ka heli doontaa casumaad Primary.Health ama maamulaha kooxdaada."
    },
    "zen_name": "Magaca qofka lala xiriirayo",
    "ticket_type": "Maxay tani ku saabsan tahay",
    "appointment_access_code": "Koodhka Xaqiijinta (haddii la yaqaano)",
    "zen_desc": "Fariinta",
    "contact_us": "Nala soo xiriir",
    "need_assistance": "Haddii aad u baahan tahay caawinaad, ama aad u baahan tahay inaad bedesho macluumaadka xiriirka, fadlan la xiriir taageerada."
  },
  "show_appointments": {
    "welcome": "Find your profile",
    "welcome_subtitle_1": "Showing results below for",
    "added_to_account": "Lagu daray akoonka",
    "error": "Ka qaybqaatahan waxa laga yaabaa inuu hore u lahaa akoon",
    "welcome_subtitle_2": "Not seeing the name below?",
    "welcome_not_logged_in": "Finding a site link",
    "welcome_logged_in": "Select your Group",
    "welcome_subtitle_1_logged_in": "To finish adding a member, select the user and group. Members are assigned to one account.",
    "welcome_subtitle_2_logged_in": "If you are not seeing your member listed above, you can",
    "welcome_subtitle_1_not_logged_in": "Select who needs to locate their site or add a new member. Member not found?",
    "welcome_subtitle_3_logged_in": "try a different contact method",
    "welcome_subtitle_4_logged_in": "or contact us at",
    "welcome_subtitle_2_not_logged_in": "Update search.",
    "welcome_subtitle_3": "Update your search"
  },
  "enter_code": {
    "code": "Waa maxay koodka?",
    "enter_code": "Geli koodka laguu soo diray",
    "incorrect_code": "Waxaad galisay kood khaldan",
    "verify": "Xaqiiji akoontiga",
    "verified": "Waa la xaqiijiyay",
    "verify_account_information": "Verify your account"
  },
  "general_self_checkout": {
    "choose_test": "Baaritaan noocee ah baad qaaday?",
    "photo_required": "Sawirka natiijada ayaa loo baahan yahay",
    "find_your_test": "Hel baaritaankaaga"
  },
  "login": {
    "create_account": "Samee Akoon",
    "create_account_help": "Samaysashada koonto la mid ma aha isu diiwaangelinta baaritaanka ama tallaalada. Markaad is diiwaan geliso",
    "create_account_help_schedule": "Inaad jadwal u samaysato balamaha",
    "create_account_help_record": "Diiwaangelio baaritaanada guriga",
    "create_account_help_match": "Maarayso tallaalka & diiwaannada baaritaanka.",
    "continue": "Ku sii wad marti ahaan",
    "already": "Horeba akoon u lahayd? Guji",
    "login": "si aad u gasho",
    "or_sign_in": "Ama kusoo gal :",
    "no_account": "Hadaadan lahayn akoon? Guji",
    "signup": "Si aad isu diwaangeliso.",
    "here": "halkan",
    "email_address": "Cinwaanka emailka:",
    "password": "Lambar sireedka:",
    "complete_registration": "Dhamaystir Diiwaangelinta",
    "last_covid_test": "Natiijadii baaritaanka COVID-19 ee ugu dambeysay",
    "no_record": "Ma jiro wax la duubay",
    "viewing_information": "Eegida {{first_name}} macluumaadka",
    "download": "Soo deji",
    "not_registered": "Waxay u egtahay inaadan iska diiwaan gashanayn wax koox ah",
    "please_follow_link": "Fadlan raac xidhiidhka martiqaadka emaylkaaga si aad balan uga samaysato oo macluumaadkaaga uga hesho Primary.Health.",
    "log_results": "Gali natiijada baaritaankayga",
    "book_appointment": "Ballan Qabso",
    "no_new_appointments": "Hadda ma aqbalino ballamo cusub",
    "upload_vaccinations": "Soo geli tallaalka",
    "vaccination": "tallaalka",
    "new_appointment": "Ballan cusub",
    "log_otc_results": "Gal natiijooyinka baaritaanka OTC",
    "no_household_members": "Wali ma lihid xubnaha qoyska",
    "no_upcoming": "Ballan soo socoto ma jiro",
    "update_vaccination": "Cusbooneysii tallaalka",
    "upload_vaccination": "Soo geli tallaalka",
    "title": "Ku soo dhawoow Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "Samaynta akoon waxa ay kuu ogolaanaysaa:",
    "p2": "Inaad samaysato balamaha mustaqbalka",
    "p3": "Diiwaangeliso baaritaanada guriga ee COVID-19",
    "p4": "Maarayso talaalka dadka ku tiirsan iyo diiwaanada baaritaanka",
    "p5": "Habka galitaanka fudud ee my.primary.health",
    "no_vaccination_record": "Wali wax tallaal ah laguma darin",
    "vaccination_title_card": "Marxallada tallaalka Covid-19",
    "account_and_settings": "Akoonka & dejinada",
    "delete_account": "Tirtir akoonka",
    "language": "Luuqad",
    "language_current": "Luuqadda haddeer: {{language}}",
    "log_out": "Ka bax",
    "delete_account_title": "Ma hubtaa inaad doonayso inaad tirtirto akoonkaaga?",
    "new_encounter": "la kulmida cusub ee {{first_name}}",
    "new_encounter_subtitle": "Kooxdee ayeey u taalaa la kulmidan cusub?",
    "no_programs": "Waxay u egtahay inaadan iska diiwaan gelinin wax barnaamijyo ah",
    "edit_members": "Tafatir xubnaha",
    "edit_members_subtitle": "Dooro dashboodhkee aad jeceshahay inaad aado ama ku darto xubin kale akoonkaaga",
    "add_members": "Ku dar xubin",
    "delete_member": "Tirtir {{full_name}} akoonkiisa ka isticmaale ahaan?",
    "delete_member_subtitle": "Ka saarista isticmaaluhu waxay tirtiri doontaa dhammaan macluumaadka la xiriira akoonka.",
    "select_member": "Xulashada xubinka",
    "edit_appointment": "Wax ka beddel ballan qabsiga",
    "route": {
      "me": "Dashboodhka",
      "history": "Taariikhda",
      "programs": "Ku dar imtixaan",
      "contact_us": "I caawi",
      "choose_member": "Xubin",
      "settings": "Dejinada",
      "help": "I caawi"
    },
    "user_dashboard": "Dashboodhka {{first_name}}",
    "view_full_results": "Ka eeg mareega natiijooyinka",
    "required": "Loo baahan yahay haddii aad isticmaalayso App-ka Koowaad.",
    "no_email": "Ma haysto ciwaanka iimaylka"
  },
  "vaccination_status": {
    "not_vaccinated": "Aan la tallaalin",
    "fully_vaccinated": "Si buuxda loo tallaalay",
    "partially_vaccinated": "Qayb ahaan la tallaalay",
    "vaccination_records": "Diiwaanada tallaalka",
    "title": "Waa maxay heerkaaga tallaalka COVID-19?",
    "definition_title": "Qeexitaannada heerka tallaalka",
    "definition_cdc": "Qeexitaanka heerka tallaalka CDC",
    "definition_osha": "OSHA qeexida heerka tallaalka",
    "definition": {
      "fully_vaccinated": "2 usbuuc ka dib mid ka mid ah qiyaasta ugu dambeysa ee 2-da qiyaas oo taxane ah oo Pfizer-BioNTech ama Moderna, ama 1 qiyaas oo ah tallaalka Johnson & Johnson's Janssen.",
      "partially_vaccinated": "Helay kaliya 1 ka mid ah 2-da qiyaasood oo taxane ah, ama in ka yar 2 toddobaad kadib markii la helay qiyaasta ugu dambaysa ee 2 qiyaas oo taxane ah ama 1 qiyaas oo Johnson & Johnson's Janssen.",
      "not_vaccinated": "Ma jiro tallaal COVID-19 ah oo la bixiyay.",
      "fully_vaccinated_with_one_booster": "Helay qiyaasta xoojiyaha ah oo ah tallaalada Pfizer-BioNTech ama Moderna kadib taxane buuxa ama 1 qiyaas oo ah tallaalka Johnson & Johnson's Janssen.",
      "fully_vaccinated_with_two_boosters": "Helay qiyaasta labaad oo xoojin ah oo ah tallaalada Pfizer-BioNTech ama Moderna kadib taxane buuxa ama qiyaasta tallaalka Johnson & Johnson ee Janssen iyo qiyaasta xoojinta."
    },
    "label": {
      "fully_vaccinated": "La talaalay",
      "partially_vaccinated": "Qayb ahaan la tallaalay",
      "not_vaccinated": "Aan la tallaalin",
      "fully_vaccinated_with_one_booster": "Si buuxda loo tallaalay + xoojiye",
      "fully_vaccinated_with_two_boosters": "Si buuxda loo tallaalay + 2 xoojiyayaal",
      "prefer_not_to_answer": "Doorbidayso inaadan ka jawaabin"
    },
    "progress_bar_title": "Taariikhda tallaalka COVID-19",
    "upload_record_card": "Soo geli kaadhkaaga diiwaanka tallaalka  ee COVID-19",
    "show_example": "Muuji tusaale",
    "hide_example": "Qari tusaalaha",
    "take_photo": "Guji si aad sawir u qaado",
    "or": "AMA",
    "upload_image_or_pdf_instead": "Soo geli sawirka/PDF beddelkeeda",
    "select_doses_received": "Dooro dhammaan qiyaasaha tallaalka COVID-19 ee aad qaadatay.",
    "first_dose": "Qiyaasta koowaad",
    "second_dose": "Qiyaasta labaad",
    "first_booster_dose": "Qiyaasta xoojiyaha koowaad",
    "second_booster_dose": "Qiyaasta labaad ee xoojiyaha",
    "additional_dose": "Qiyaas dheeraad ah",
    "first_dose_manufacturer": "Yuu ahaa soo-saaraha COVID-19 qiyaastaadii ugu horreysay?",
    "first_dose_date": "Maxay ahayd taariikhda qiyaastaada koowaad?",
    "second_dose_manufacturer": "Yuu ahaa soo saaraha COVID-19 qiyaastaada labaad?",
    "second_dose_date": "Maxay ahayd taariikhda qiyaastaada labaad?",
    "first_booster_dose_manufacturer": "Yuu ahaa soo saaraha COVID-19 ee qiyaasta xoojintaada kowaad?",
    "first_booster_dose_date": "Maxay ahayd taariikhda qiyaasta xoojintaada koowaad?",
    "second_booster_dose_manufacturer": "Yuu ahaa soo saaraha COVID-19 ee qiyaastaada labaad ee xoojiyaha?",
    "second_booster_dose_date": "Maxay ahayd taariikhda qiyaastaada labaad ee xoojiyaha?",
    "additional_dose_manufacturer": "Yuu ahaa soo saaraha COVID-19 ee qiyaastaada dheeriga ah?",
    "additional_dose_date": "Maxay ahayd taariikhda qiyaastaada dheeraadka ah?",
    "completed_one": "Dhammaystiran",
    "completed_two": "Waad ku mahadsan tahay cusboonaysiinta heerkaaga tallaalka!",
    "progress_bar_complete": "Dhameystiran",
    "upload_image": "Geli sawirka",
    "retake_photo": "Dib u qaad sawir",
    "other": "Mid kale",
    "remove_first_dose": "Saar taariikhda qiyaasta koowaad",
    "remove_second_dose": "Saar taariikhda qiyaasta labaad",
    "remove_first_booster_dose": "Saar taariikhda qiyaasta xoojisa koowaad",
    "remove_second_booster_dose": "Saar taariikhda qiyaasta labaad ee xoojiyaha",
    "remove_additional_dose": "Saar taariikhda qiyaasta dheeraadka ah",
    "exemption": "Ma leedahay ka-dhaafitaan?",
    "exempt": "Waa la iga dhaafay",
    "not_exempt": "La igama dhaafo",
    "enter_exemption": "Geli ka dhaafid",
    "upload_exemption_documentation": "Soo geli dukumeenti ka dhaafidaada",
    "remove_dose": "Ka saar qiyaasta",
    "continue": "Sii wad",
    "enter_credentials": "Fadlan geli magacaaga isticmaalaha iyo fure sireedkaada",
    "incorrect_credentials": "Magaca isticmaalaha ama fure sireedka ayaa khaldan, fadlan isku day mar kale.",
    "add_photo": "Ku dar sawir"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "sidee tahay {{name}}, Waxaan ogaanay inaadan dhammayn jadwalkaaga {{group_name}} balanta {{org_name}}. Si aad ballankaaga u ilaaliso, waxaad u baahan doontaa inaad dhammaystirto tallaabada lacag-bixinta. Fadlan dhagsii xidhiidhkan si aad u dhamaystirto hawsha {{complete_url}}. Haddii aad jeclaan lahayd inaad baajiso ballantaada, fadlan ku dhufo xiriirkan {{cancel_url}}. Mahadsanid"
    }
  },
  "otc": {
    "record_results_for_abbott": "Tijaabi oo diiwaan geli natiijadaada baadhitanka BinaxNOW",
    "record_results": "Tijaabi oo kadibna diiwaan geli natiijooyinkaaga",
    "activate_a_kit": "daar mise ka shaqeysii qalabka",
    "activate_a_kit_for_test": "Daar qalab loogu talagalay {{test}}",
    "todays_test_process": "Habka baadhista maanta",
    "date_of_birth_question": "Waa maxay {{first_name}} taariikhda dhalashada?",
    "confirm_dob_to_continue": "Fadlan xaqiiji taariikhda dhalashada ka qaybqaataha inay sii socoto.",
    "incorrect_dob": "Taariikhda dhalashada waa khalad",
    "file_too_large": "Fadlan hubi in cabbirka faylka la soo geliyay uu ka yar yahay {{size}}",
    "enter_valid_date_in_last_x_days": "Geli taariikh ansax ah maalmood {{n}} ee la soo dhaafay",
    "barcode_format_not_valid": "Qaabka barcode hoose sax maaha",
    "complete_button": "Si dhamaystiran",
    "next_button": "Xiga",
    "back_button": "dib u noqo",
    "report_a_test": "Ka warbixi natiijada:",
    "x_%_completed": "{{n}}% dhameystiran",
    "answer_survey": "ka jaawabida xog aruurinta weydimaha",
    "steps": {
      "answer_survey": "ka jawaab su'alaha weydiimada ee xog aruurtinta",
      "review_instructions": "Dib u eegista tilmaamaha",
      "scan_code": "Sawir qaadaha code-ka",
      "enter_time": "Geli Waqtiga",
      "enter_result": "Geli natiijada",
      "take_photo": "Qaad Sawir",
      "confirm_result": "xaqiijinta natiijada",
      "selest_test_kit": "Dooro nooca tijaabada aad dooneyso",
      "enter_date": "Gali wakhtiga tariikhda",
      "enter_date_and_time": "Geli taariikhda iyo wakhtiga"
    },
    "review_instructions": "Dib u eeg tilmaamaha tijaabada",
    "follow_instructions_or_below": "Raac tilmaamaha la socda baaritaankaaga ama dib u eeg tilmaamaha hoose",
    "watch_video": "Daawo video",
    "view_instructions": "Eeg tilmaamaha",
    "follow_instructions": "Raac tilmaamaha la socda baaritaankaaga.",
    "scan_code": "Iskaan baadhka sawika hoose ee machina ka",
    "qr_code_description": "Koodhka QR waa mid gaar u ah baaritaankaaga waxaana laga heli karaa geeska sare ee midig ee imtixaankaaga",
    "show_example": "Bixi tusaale",
    "hide_example": "qari tusaalaha",
    "barcode_scanned": "sawirka-code ka si guul leh ayaa loo sawiray",
    "enter_barcode": "geli code-ka",
    "scan_qr_code": "Sawir koodka QR",
    "which_test_did_you_take": "Baadhis noocee ah ayaa qaadatey",
    "when_did_you_take_test": "Goormaad qaadatay baaristan?",
    "what_day_did_you_take_test": "Immisa malin baad gashay baadhista?",
    "what_time_did_you_take_test": "goormee ayaad baadhistaan gashay?",
    "time": {
      "today": "Maanta",
      "yesterday": "Shaley",
      "two_days_ago": "labo malin ka hor"
    },
    "enter_current_time": "Gali wakhtiga hadda la joogo",
    "enter_result": "Geli natiijada baadhista",
    "choose_result_option": "Dooro ikhtiyaarka qeexaya natiijada kaadhka imtixaanka mise tijabada",
    "clarify_results": "Caddee natiijadaada baadhista",
    "take_result_photo": "Qaad Sawir muujinayo natiijooyinka",
    "take_result_photo_description": "Sawir ka qaad kaadhka natiijada oo dhammaystiran si aad u duubto natiijooyinkaaga",
    "my_result": "Natiijadeyda",
    "switch_camera": "Dami kaamerada",
    "confirm_result": "xaqiijinta natiijada",
    "name": "Magaca",
    "date": "Waqtigamise Tarikhda",
    "result": "Natiijada",
    "results": "Natiijooyinka",
    "test_submitted": "Waad ku mahadsan tahay, {{first_name}}! {{test}}agii si guul leh ayaa loo gudbiyay",
    "dob": "waqtiga dhalshada",
    "administered": "ka Jawaab xog aruurinta",
    "or": "Hadii kale",
    "upload": "Soo rar oo soo geli",
    "change_uploaded_file": "Beddel faylka la soo geliyay",
    "take_photo": "Qaad Sawir",
    "confirm_information": "xaqiijinta xogta",
    "barcode": "sawirka-code ka ku qoran hoos",
    "scan_test_barcode": "Iskaan baadhka sawika hoose ee machina ka",
    "barcode_description": "sawirka hose hee machine ka waa mid gaar u ah imtixaankaaga waxaana laga heli karaa imtixaankaaga",
    "enter_date_in_last_30_days": "Geli taariikh 30-kii maalmood ee la soo dhaafay iyo waqtiga ku dhow ee baaritaanka la qaaday",
    "add_image": "sawir kusoo dar",
    "change_image": "beddel sawirka",
    "skip_barcode_reader": "Ma awoodin in la iskaan karo koodka? Guji Next si aad uga warbixiso natiijada imtixaanka si kastaba",
    "enter_date_and_time": "Geli taariikhda iyo wakhtiga ibaadhista",
    "enter_date": "Geli taariikhda baadhista",
    "did_you_take_test_today": "Ma isbaartay maanta?",
    "record_results_for_generic": "Baar oo diiwaangeli natiijooyinkaaga baaritaanka {{name}}",
    "choose_result_option_custom": "Dooro ikhtiyaarka qeexaya natiijadaada {{test_name}}:"
  },
  "yes": "Haa",
  "no": "Maya",
  "event_token": {
    "title": "Fadlan geli lambarka hoose ee mashiinka wax iibinta si aad u bixiso qalabkaaga tijaabada",
    "loading_text": "Soodejinaya, fadlan dib u hubi hal daqiiqo gudaheed"
  },
  "appointment_recovery": {
    "complete_title": "Wax walbo waa dhammaysay!",
    "subtitle_1": "Haddii aad isticmaalayso iPhone, fadlan ku dhufo **Done** oo ku yaal geeska bidix ee sare ee shaashaddan si aad ugu noqoto dashboodhkaaga.",
    "subtitle_2": "Haddii aad isticmaalayso Android, ku dhufo **X** ee ku yaal geeska bidix ee sare ee shaashaddan.",
    "subtitle_0": "Fadlan ka bax barowserka si aad u sii wadato."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Xiriirka la ogolyahay",
  "finish_registration": {
    "title": "Dhameystir diiwaangelintaada",
    "subtitle": "Fadlan buuxi macluumaadka soo socda ka hor ballantaada:",
    "button": "Dhamaystir diiwaangelinta"
  },
  "arab_ethnicity": {
    "arab": "Carab",
    "non_arab": "Aan Carab ahayn",
    "prefer_not_to_answer": "Doorbidaya inaanan ka jawaabin",
    "question": "Qowmiyadda Carabta",
    "subtitle": "Xeerarku waxay u baahan yihiin inaan ururino macluumaadka soo socda.",
    "help": "Qowmiyada waxaa loola jeedaa astaamo guud sida luqadda, caadooyinka dhaqanka, diinta, iyo sifooyin kale oo loo isticmaalo in lagu kala saaro kooxaha dadka. Waxaa laga yaabaa ama laan aga yaabin inay la jaanqaadi weydo aqoonsiga jinsiyadda sidoo kale."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Iyada oo ku xiran tahay aaladaada, guji lifaaqa hoose si aad usoo dejisato kaarka caafimaadka aasaasiga ah."
    }
  },
  "stepper": {
    "next": "Xiga",
    "back": "Dib u noqo",
    "complete": "Dhameystiran"
  },
  "registration_direcory": {
    "keyword_search_label": "Keyword, city, zip code, organization name",
    "keyword_search_label_mobile": "City, zip code, organization name",
    "zero_results": {
      "title": "We didn’t find any providers with that search term.",
      "sub_title": "Try searching for your provider by:",
      "p1": "Zip code",
      "p2": "City or town name",
      "p3": "County name",
      "p4": "Provider or organization name"
    }
  },
  "registration_flyer": {
    "open_camera": "Fur app-ka kamarada qalabkaaga",
    "point_to_qr_code": "Tilmaan baaq sireedka QR oo taabo xidhiidhka",
    "complete_registration": "Diiwaangelinta Dhamaystir",
    "need_help": "Ma u baahan tahay caawimaad? Nagala soo xidhiidh halkan"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Cadaynta macluumaadka tallaalka",
    "select_checkbox": "Fadlan dib u eeg kuwan soo socda oo dooro sanduuqa saxda ah ee hoose ka hor intaadan sii wadin.",
    "opportunity_to_read": "Waxaa la i siiyaa ama la i siiyay fursad aan ku akhriyo, ama la ii akhriyay, xaanshida xaqiiqda ee Bayaanada Macluumaadka Tallaalka (\"VIS\") ama Oggolaanshaha Isticmaalka Degdegga ah (\"EUA\") ee loogu talagalay in la bixiyo tallaalka:",
    "vaccine_info_sheet": "warqada macluumaadka talaalka",
    "vaccine_fact_sheet": "xaanshida xaqiiqda tallaalka",
    "vaccine_info_statement": "bayaanka macluumaadka talaalka"
  },
  "exemption": {
    "dtap_tdap": "Teetanada, Gawracatada, iyo Xiiq-dheerta Unugyada/Teetanada, Gawracatada, iyo Xiiq-dheerta",
    "mmr": "Jadeecada, Qaamo-qashiirka iyo rubella",
    "ipv_opv": "Dabaysha",
    "hib": "HIB",
    "hep_b": "Cagaarshowga B",
    "var": "Tallaalka Busbuska",
    "hep_a": "Cagaarshowga A",
    "pcv": "PCV",
    "mcv": "Qoorgooyada"
  },
  "test_strip": {
    "A": "xirmadaan waxa ku jira baaritaanada chlamydia iyo jabtada.",
    "B": "xirmadaan waxa ku jira baaritaanada chlamydia iyo jabtada.",
    "C": "xirmadaan waxa ku jira baaritaanada waraabowga.",
    "D": "xirmadan waxaa ku jira baaritaanada HIV, Waraabowga, Kalamiidiya iyo Jabtada.",
    "E": "xirmadaan waxa ku jira baaritaanada HIV iyo waraabowga.",
    "box_type": "Kartoonka {{type}}",
    "administered_text": "Waan kula soo xiriiri doonaa marka xirmadaada soo gaaro shaybaarka oona natiijooyinkaaga diyaar yihiin.",
    "activate_kit": "Ka shaqaysii xirmadayda baaritaanka",
    "register_another_test": "Diiwaangeli baaritaan kale",
    "credentials": {
      "title": "Aan bilowno",
      "label": "La xiriir",
      "p1": "Waxaan ku bilaabi doonaa xoogaa macluumaad ah oo adiga kugu saabsan.",
      "location_step": {
        "p1": "Yaa bixiyay xirmadaada baaritaanka?",
        "load": "Wax badan soo saar"
      }
    },
    "checklist": {
      "not_urinated": "Ma kaadinin saacadii lasoo dhaafay.",
      "bathroom": "Waxaad heli kartaa suuli si aad kaadi isaga soo qaado.",
      "anal_swab": "Waxaad heli kartaa meel gaar ah oo aad baaritaanka suufka dabada ugu soo samayn kartid.",
      "blood_extraction": "Waxaad heli kartaa degaan nadiif ah oo dhiig lagaaga qaado.",
      "washed_hands": "Waxaad kacmahaaga ku dhaqday saabuun iyo biyo qandac ah 30 ilbiriqsiyo.",
      "title": "Diyaar mau tahay inaan bilowno muunad qaadida?",
      "no_alcohol": "Ha cabbin aalkolo maalinta lagaa qaadaayo.",
      "menstruation": "Ha qaadin muunada suufka siilka xilliga caadada ama 24 saacadood gudahooda galmada kadib."
    },
    "confirm": {
      "title": "Xaqiiji macluumaadkaaga.",
      "p1": "Macluumaadkaan ma saxanyihiin?"
    },
    "display_name": {
      "A": "Chlamydia iyo Jabtada (Goob kali ah)",
      "B": "Chlamydia iyo Jabtada (3 Goob)",
      "C": "Waraabowga",
      "shortened": {
        "A": "Chlamydia iyo Jabtada",
        "B": "Chlamydia iyo Jabtada",
        "C": "Waraabowga"
      },
      "CC": "Baaritaanka Kansarka Mindhiciraha Waaweyn (FIT)",
      "CE": "Baaritaanka Mindhiciraha Yaryar",
      "CR": "Baaritaanka Kiritiinka",
      "D": "4 Xirmo Baaaritaano ah - HIV, Waraabowga, Kalamiidiya iyo Jabtada (hal goob)",
      "DD": "Baaritaanka Sokorta (HbA1c)",
      "E": "HIV iyo Waraabowga",
      "H": "HPV",
      "P": "Cagaarshowga C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Heerka Hormoonka Dareenka Ragga",
      "VD": "Fitamiin D",
      "VS": "Ilaalinta Fayraska",
      "AP": "Chlamydia & Gonorrhea"
    },
    "rescan": "Dib-sawir u qaadid",
    "scanned": "Waxaad sawirtay kartoonka {{type}}",
    "not_yet": "Maya Weli",
    "success_registration": "Diiwaangelinta way guulaysatay",
    "no_box": "Kartoon ma haysatid?",
    "faq_link": "Booqo Su'aalaha Soo Noqnoqda",
    "welcome": "Soo dhawoow!",
    "scan": {
      "title": "Hal tilaabo ayaad u jirtaa inaad cafimaad qabtid.",
      "p1": "Sawir ama geli **baaq sireedka xirmada** ee ku yaala dhinaca kartoonkaaga."
    },
    "appointments": {
      "title": "Xullo bukaanka iyo dhakhtarka",
      "p1": "Waxaan helnay in ka badan hal bog aqoonsi oo ku xiran macluumaadkaaga xiriirka. Dooro bogga aqoonsiga saxda ah:",
      "new": "Bukaan ama dhakhtar cusub"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "Waxaan helnay diiwaankaaga bukaanka."
    },
    "section_1": "Xaqiiji waxa ku jira xirmada",
    "section_2": "Baritaan qaadista",
    "section_3": "Baakadaynta",
    "section_4": "Dhoofinta",
    "box_a": "Kartoonka A",
    "box_b": "Kartoonka B",
    "box_c": "Kartoonka C",
    "kit_flow": {
      "button_1": "Sii wad baakadaynta",
      "button_2": "Diyaar ayaan ahay",
      "button_3": "Xirmadayda waa dhamaystiran yahay waana la baakadeeyay",
      "button_4": "Waan dhammeeyay",
      "button_5": "Baaritaanka xigga",
      "button_6": "Ka bood tilmaamaha"
    },
    "contents": {
      "header": "Si fiican! Aan hubino in xirmadaada ay dhamaystiran tahay",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Kaarka dhiig uruurinta",
      "blood_collection_card_subtitle": "(1 ama 2 ay ku xirantahay xirmada baaritaanka)",
      "alchohol_pads": "2 suuf oo alkolo leh",
      "lancets": "Cirbad halmar la isticmaalo",
      "up_to_other": "(Ilaa 4 ay ku xirantahay xirmada baaritaanka)",
      "adhesive_bandages": "Faashado dhejis ah",
      "pipette": "Tubada dhiiga lagu shubo",
      "urine_tube": "Tubada kaadida lagu uruursho",
      "urine_cup": "Koobka kaadida lagu uruursho",
      "oral_swab": "Suufka afka",
      "oral_tube": "Tubada dheecaanka afka lagu uruursho",
      "anal_swab": "Suufka baaritaanka dabada",
      "anal_tube": "Tubada baaritaanka dabada",
      "vaginal_tube": "1 tubo oo baaritaanka siilka ah",
      "vaginal_swab": "1 suuf oo baaritaanka siilka ah",
      "biodegradable_paper": "Warqad uruurin oo si sahlan loo baabi'in kara",
      "bottle_and_probe": "Dhalada iyo qalabka muunadayta",
      "prepaid_envelope": "Bashqada dhoofinta ee lacag-hormarinta",
      "biohazard_subtitle": "(oo leh suuf wax nuugaya)",
      "biohazard_bag": "1 bac oo muunad ah",
      "biohazard_bag_plural": "({{count}}) Bacaha khatarta noolaha",
      "alcohol_pads_plural": "({{count}}) suufka alkolada",
      "sterile_gauze_pad": "1 faashad nadiif ah"
    },
    "packaging": {
      "title": "Liiska hubinta baakadaha",
      "subtitle": "Kahor intaadan soo dirin xirmadaada, hubi in:"
    },
    "packaging_box_a": {
      "step_1": "Aad soo baakadaysay dhammaan waxa ku jira xirmada baaritaanka",
      "step_2": "Aad isticmaashay dhammaan agabka aruurinta ee ku jiray xirmada si aad u soo ururiso muunadaha loo baahan yahay",
      "step_3": "**Taariikhda-dhalashadaada** aad ku qortay dhammaan tubooyinka uruurinta muunada adigoo adeegsanaya qaabka MM/DD/YYYY",
      "step_4": "**Taariikhda aruurinta** aad ku qortay dhammaan tubooyinka uruurinta muunada adigoo adeegsanaya qaabka MM/DD/YYYY",
      "step_5": "Tubada uruurinta muunada ay ku sijilaysan tahay bacda khatarta noolaha",
      "step_6": "Aad dhammaan muunadaha ku riday kartoonka",
      "step_7": "Aad kartoonka ku riday baqshada dhoofinta lacag-hormarinta",
      "step_8": "Aad baqshada soo celinta gabi ahaanba sijilaysay"
    },
    "packaging_box_b": {
      "step_1": "Aad soo baakadaysay dhammaan waxa ku jira xirmada baaritaanka, xitaa haddii aad ka booday baaritaanki(nadii)",
      "step_2": "Aad isticmaashay dhammaan agabka aruurinta ee ku jiray xirmada si aad u soo ururiso muunadaha loo baahan yahay",
      "step_3": "**Taariikda-dhakashada**  aad ku qortay dhammaan tubooyinka uruurinta muunada adigoo adeegsanaya qaabka MM/DD/YYYY",
      "step_4": "**Taariikhda aruurinta** aad ku qortay dhammaan tubooyinka uruurinta muunada adigoo adeegsanaya qaabka MM/DD/YYYY",
      "step_5": "Aad tubo kasta oo uruurinta muunada ah ku sijileeyay bacaheeda khatarta noolaha (kaliya **hal** muunad bacdiiba)",
      "step_6": "Aad dhammaan muunadaha gelisay kartoonada",
      "step_7": "Aad kartoonka gelisay baqshada dhoofinta ee lacag-hormarinta",
      "step_8": "Aad baqshada soo celinta gabi ahaanba sijilaysay"
    },
    "packaging_box_c": {
      "step_1": "Aad soo baakadaysay dhammaan waxa ku jira xirmada baaritaanka, ay ku jiraan cirbadaha la isticmaalay.",
      "step_2": "**Taariikdaada-dhalashada** aad ku qortay kaarka dhiig uruurinta adigoo adeegsanaya qaabka MM/DD/YYYY",
      "step_3": "**Taariikhda aruurinta** aad ku qortay dhammaan tubooyinka dhiig uruurinta adigoo adeegsanaya qaabka MM/DD/YYYY",
      "step_4": "Aad kaarka dhiig uruurinta iyo dhammaan cirbadaha aad isticmaashay aad soo gelisay bacda khatarta noolaha",
      "step_5": "Aad bacda khatarta noolaha soo gelisay kartoonka",
      "step_6": "Aad kartoonkagelisay baqshada dhoofinta ee lacag-hormarinta",
      "step_7": "Aad baqshada soo celinta gabi ahaanba sijilaysay"
    },
    "success": {
      "header_1": "Si wacan!",
      "text_1": "Waxaad diyaar u tahay inaad qaado muunadaadi ugu horeeysay.",
      "header_2": "Waad samaysay!",
      "text_2": "**Waxa xigga** baakadeey xirmadaada si aad dib ugu soo dirto.",
      "header_3": "Shaqa fiican!",
      "text_3": "Waxaad hal tallaabo u jirtaa caafimaad wanaagsan.",
      "header_4": "Shaqa wacan!",
      "text_4": "Kaliya halmar kale kadibne waa dhammaysay!",
      "text_5": "Waxaad diyaar u tahay inaad qaado muunadaadi ugu horeeysay."
    },
    "instructions": {
      "title": "Xullo baaritaan si aad u aragtid tilmaamaha:",
      "subtitle": "Baaritaanka",
      "button": "Tilmaamaha",
      "pdf": "Tilmaamo PDF ah"
    },
    "instructions_box_a": {
      "title": "Kaadi qaadis",
      "subtitle": "Baaritaankaan waxaad u baahan tahay agabyada soo socda:",
      "step_1": "Ha kaadinin ugu yaraan 1 saac ka hor kaadi qaadista. adeegsiga kaadida ugu horeeyso subaxdi ayaa ugu fiican.",
      "step_2": "Si toos ah ugu kaadi PeeCanter ama koobka uruurinta, adigoo buuxinaya inta u dhexayso 1/3 iyo 1/2.",
      "step_3": "Kasoo shub PeeCanter ama isticmaal tubo si aad uga soo wareejiso kaadida koobka uruurinta oodna ugu soo wareejiso tubada(ooyinka) uruurinta ee ku calaamadaysan calaamada oranji <span style=\"color: #FF5000\">**\"URINE\"**</span>.",
      "step_4": "Buuxi tubada(booyinka) uruurinta ilaa dareeraha la isku dhafay uu u dhexeeyo xariiqda buuxinta ee loo qoondeeyay.",
      "step_4_sublabel": " Ha buux dhaafin tubada ilaa ugu badnaan xariiqda buuxinta ama muunada waa lasoo diidi doonaa.",
      "step_5": "Daboolka saar tubada uruurinta oo hubi inay si siman oo adag u xiran tahay.",
      "step_6": "Ku qor taariikhdaada dhalashada iyo taariikhda uruurinta muunada (taariikhda maanta) qaabka MM/DD/YYYY tubada goobta loo qoondeeyay.",
      "step_7": "Soo geli muunada bac khatarta noolaha oo maran oo ay ku jirto suufka nuugista xaqiijina inay si dhammaystiran u sijilaysan tahay."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Kaadi uruurinta",
        "subtitle": "Baaritaankaan waxaad u baahan tahay agabyada soo socda:",
        "step_1": "Ha kaadinin ugu yaraan 1 saac ka hor kaadi qaadista. adeegsiga kaadida ugu horeeyso subaxdi ayaa ugu fiican.",
        "step_2": "Si toos ah ugu kaadi PeeCanter ama koobka uruurinta, adigoo buuxinaya inta u dhexayso 1/3 iyo 1/2.",
        "step_3": "Kasoo shub PeeCanter ama isticmaal tubo si aad uga soo wareejiso kaadida koobka uruurinta oodna ugu soo wareejiso tubada(ooyinka) uruurinta ee ku calaamadaysan calaamada oranji <span style=\"color: #FF5000\">**\"URINE\"**</span>.",
        "step_4": "Buuxi tubada(booyinka) uruurinta ilaa dareeraha la isku dhafay uu u dhexeeyo xariiqda buuxinta ee loo qoondeeyay.",
        "step_4_subtitle": " Ha buux dhaafin tubada ilaa ugu badnaan xariiqda buuxinta ama muunada waa lasoo diidi doonaa.",
        "step_5": "Daboolka saar tubada uruurinta oo hubi inay si siman oo adag u xiran tahay.",
        "step_6": "Ku qor taariikhdaada dhalashada iyo taariikhda uruurinta muunada (taariikhda maanta) qaabka MM/DD/YYYY tubada goobta loo qoondeeyay.",
        "step_7": "Soo geli muunada bac khatarta noolaha oo maran oo ay ku jirto suufka nuugista xaqiijina inay si dhammaystiran u sijilaysan tahay."
      },
      "test_2": {
        "title": "Suufka afka",
        "subtitle": "Baaritaankaan waxaad u baahan tahay agabyada soo socda:",
        "step_1": "Ku qabo suufka inta u dhaxaysa xariiqda dhibcaha daciifka ah (haddii ay jirto) iyo qaybta suufka-sare ee suufka.",
        "step_2": "Geli suufka afka oo ku xoq caarada suufka xagga dambe ee cunahaaga 10 ilbiriqsi.",
        "step_3": "Si taxadar leh uga soo saar suufka oo geli tubada uruurinta ku calaamadaysan calamaada buluuga ah <span style=\"color: #001F70\">**\"AFKA\"**</span>. Ka jabi suufka xariiqda dhibcaha daciifka ah adigoo ku laabaya darbiga tubada uruurinta.",
        "step_4": "Daboolka saar tubada uruurinta oo hubi inay si siman oo adag u xiran tahay.",
        "step_5": "Ku qor taariikhdaada dhalashada iyo taariikhda uruurinta muunada (taariikhda maanta) qaabka MM/DD/YYYY tubada goobta loo qoondeeyay.",
        "step_6": "Soo geli muunada bac khatarta noolaha oo maran oo ay ku jirto suufka nuugista xaqiijina inay si dhammaystiran u sijilaysan tahay."
      },
      "test_3": {
        "title": "Suufka baaritaanka dabada",
        "subtitle": "Baaritaankaan waxaad u baahan tahay agabyada soo socda:",
        "step_1": "Ku qabo suufka inta u dhaxaysa xariiqda dhibcaha daciifka ah (haddii ay jirto) iyo qaybta suufka-sare ee suufka.",
        "step_2": "Geli suufka 3 - 5 cm (1 - 2\") gudaha dabada. Si tartiib ah u rogrog suufka 5 - 10 ilbiriqsiyo si aad u uruuriso noole kasta oo suurta gal ah.",
        "step_3": "Si taxadar leh uga soo saar suufka oo geli tubada uruurinta ku calaamadaysan calamaada cagaarka ah <span style=\"color: #001F70\">**\"DABADA\"**</span>. Ka jabi suufka xariiqda dhibcaha daciifka ah adigoo ku laabaya darbiga tubada uruurinta.",
        "step_4": "Daboolka saar tubada uruurinta oo hubi inay si siman oo adag u xiran tahay.",
        "step_5": "Ku qor taariikhdaada dhalashada iyo taariikhda uruurinta muunada (taariikhda maanta) qaabka MM/DD/YYYY tubada goobta loo qoondeeyay.",
        "step_6": "Soo geli muunada bac khatarta noolaha oo maran oo ay ku jirto suufka nuugista xaqiijina inay si dhammaystiran u sijilaysan tahay."
      }
    },
    "instructions_box_c": {
      "title": "Dhiig uruurinta",
      "subtitle": "Baaritaankaan waxaad u baahan tahay agabyada soo socda:",
      "step_1": "Ku qor magacaaga,taariikhdaada dhalashada, iyo taariikhda uruurinta goobaha loo qoondeeyay. Adeegso qaabka MM/DD/YYYY.",
      "step_2": "Fur daboolka kaarka dhiigga si aad u muujiso wareegyada ku yaal warqadda uruurinta dhiigga. Ha taaban warqadda aruurinta dhiigga.",
      "step_3": "Ku dhaq gacmahaaga biyo qandac ah ugu yaraan 30 ilbiriqsiyo, ka dibna si xoog leh u rux gacmaha ilaa 15 ilbiriqsi si aad u dhiirigeliso socodka dhiigga farahaaga.",
      "step_4": "Ku nadiifi faraha foodooda suuf alkolo leh. Way fiican tahay inaad isticmaasho farta dhexe ama farta faraantiga ee gacanta aadan badanaa adeegsan.",
      "step_5": "Qaado cirbada oo ka leexi furka. Si adag ugu cadaadi caarada yar caarada fartaada, ilaa ay cirbadda ka gujiso. Gacantaada ku hay meel ka hoosaysa wadnahaaga inta lagu jiro ururinta, ka soo daliig farta salka ilaa cirifka si aad u dhiirigeliso socodka dhiigga.",
      "step_5_subtitle": "Cirbada halmar ayaa la isticmaalaa. Dhammaan cirbadaha waxa loo baahan yahay inaad kula soo celiso muunadaada shaybaarka si loo tuuro.",
      "step_6": "Laga bilaabo bartamaha, mari 3-6 dhibcood si aad u buuxiso goobada oo aad ugu qoyso warqadda aruurinta. Farta ha ku taaban warqadda sababtoo ah tan waxay xaddidi doontaa socodka dhiigga. Marka goobada buuxsanto, u gudub goobada xigta. Waa caadi in dhiiga ku fido meelo ka baxsan xariiqyada, laakiin ha u ogolaan dhibcaha dhiiga inay isku fidaan midba midka kale.",
      "step_6_subtitle": "Haku darin dhiig dheeri ah goobo marka aad dhammaystirto ama ay qalasho. \"Lakabka\" dhiigga ayaa burin doona uruurinta.",
      "step_7": "Adiga oo aan xirin kaarka dhiiga, dul dhig meel fidsan oo u ogolow in warqada ururinta dhiiga ay hawo ku engegto heerkulka qolka ah, ugu yaraan 30 daqiiqo. Ha kuleylin, ha afuufin, ama ha dhigin warqadda aruurinta dhiigga iftiinka qorraxda si toos ah. Kulaylka wuxuu dhaawici doonaa muunada.",
      "step_7_subtitle": "Hubi dhanka dambe ee warqadda dhiig uruurinta. Dhiiga waa inuu ku milmaa dhamaan uuna buuxiyaa goobo kasta ee warqadda uruurinta ah.",
      "step_8": "Marka warqadda dhiig uruurinta engagto, xir kaarka dhiiga adigoo isku laabaya. Geli kaarka dhiiga iyo cirbadaha bacda khatarta noolaha oo leh baakada qallajiya. Hubi in bacda khatarta noolaha si habboon loo sijileeyay."
    },
    "test_tips": {
      "title": "Talooyin loogu talagalay Sida Saxda ah ee Dhiig Uruurinta",
      "subtitle": "Si loo helo natiijada ugu wanaagsan:",
      "step_1": "Hubi in aadan fuuqbaxin markaad samaynayso uruurinta. Jirkaaga oo biyo leh wuxuu kor u qaadaa socodka dhiigga.",
      "step_2": "Ha samaynin uruurin kadib isla markii aad sigaar cabtid.",
      "step_3": "Ku dhaqida iyo ku qandiirida gacmahaaga biyo kulul dhacdooda waxay kaa caawinaysaa inay kor u qaado socodka dhiiga gacmahaaga.",
      "step_4": "Si xoog leh u rux gacmahaaga adigoo dhulka u foorarinaayo si aad u dhiiri gelisid socodka dhiiga farahaaga.",
      "step_5": "Ku haay aalada uruurinta iyo gacmahaaga wadnahaaga hoostooda inta lagu jiro uruurinta si aad u hesho socodka dhiiga ugu fiican.",
      "step_6": "Waxaa laga yaabaa inaad u baahato wax ka bdan hal mar oo far mudid ah. Ku celceli talooyinkaan inta u dhexayso far mudid kasta."
    },
    "shipping": {
      "header": "Xirmadaada waxay diyaar u tahay in la diro!",
      "text": "**hambalyo** Waad dhammaysatay baaritaankaagii caafimaadka galmada."
    },
    "pick_up": "Ka soo qaado xirmadaada baaritaanka ee laguugula taliyay shaqaalaha goobta oo hoos iska diiwaan geli",
    "short_display_name": {
      "A": "Kalamiidiya iyo Jabtada",
      "B": "Kalamiidiya iyo Jabtada",
      "C": "Waraabowga",
      "CC": "Kansarka Mindhicirka Waaweyn",
      "CE": "Xanuunka Mindhicirka Yaryar",
      "CR": "Kiritiin",
      "D": "HIV, Waraabowga, Kalamiidiya iyo Jabtada",
      "DD": "Sokorta",
      "E": "HIV iyo Waraabowga",
      "H": "HPV",
      "P": "Cagaarshowga C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Hormoonka Dareenka Ragga",
      "VD": "Fitamiin D",
      "VS": "Ilaalinta Fayraska"
    },
    "test_kit": "Xirmada baaritaanka",
    "view_results": "Fiiri natiijooyinka",
    "recommended_kit_title": "Xirmooyinka Baaritaanka Lagu Taliyay",
    "instructions_box_h": {
      "title": "Uruurinta Suufka Baaritaanka Siilka",
      "subtitle": "Tuubooyinka uruurinta waxa ku jiri kara wax ilaaliya dareere ah. <span class=\"text-danger\">**Haka saarin waxa ilaaliya dareeraha tubbooyinka uruurinta haddii ay ku jiraan.**</span>",
      "step_1": "Ku qabo suufka inta u dhaxaysa xariiqda dhibcaha daciifka ah (haddii ay jirto) iyo qaybta suufka-sare ee suufka hal gacan oo kala saar faruuryaha faruuryaha (laabyada maqaarka ee ku wareegsan daloolka siilka).",
      "step_2": "Geli suufka 5cm (2inji) gudaha siilka. Si tartiib ah ugu rogrog 30 ilbiriqsiyo adigoo ku xoqaya suufka darbiyada siilka.",
      "step_3": "Si taxadar leh uga soo saar suufka oo geli tubada uruurinta ku calaamadaysan <span class=\"text-danger\">**CALAAMADA “SIILKA” GUDUUDKA**</div>. Ka jabi suufka xariiqda dhibcaha daciifka ah adigoo ku laabaya darbiga tubada uruurinta.",
      "step_4": "Daboolka saar tubada uruurinta oo hubi inay si siman oo adag u xiran tahay.",
      "step_5": "Ku qor taariikhdaada dhalashada qaabka MM/DD/YYYY tubada goobta loo qoondeeyay (DOB).",
      "step_6": "Ku qor aariikhda uruurinta muunada (taariikhda maanta) qaabka MM/DD/YYYY tubada goobta loo qoondeeyay (Taarikhda Dhalashada).",
      "step_7": "Soo geli muunada bac khatarta noolaha oo maran oo ay ku jirto suufka nuugista xaqiijina inay si dhammaystiran u sijilaysan tahay."
    },
    "packaging_box_h": {
      "step_3": "**Taariikhdaada-dhalashada** aad ku qortay tubadada uruurinta muunada adigoo adeegsanaya qaabka MM/DD/YY",
      "step_4": "**Taariikhdaada uruurinta** aad ku qortay tubada uruurinta muunada adigoo adeegsanaya qaabka MM/DD/YY",
      "step_6": "Aad bacda khatarta noolaha soo gelisay kartoonka"
    },
    "instructions_box_cc": {
      "title": "Saxara uruurinta",
      "step_1": "Ku qor taariikhdaada dhalashada iyo taariikhda uruurinta muunada (taariikhda maanta) qaabka MM/DD/YYYY tubada goobta loo qoondeeyay. Fur furka cagaaran adigoo leexinaya oo kor u qaadaya.",
      "step_2": "Ku rid warqadda uruurinta ee la keenay godka musqusha oo biyo ku jiraan.",
      "step_3": "Muunad saxaro ah dul saar warqadda uruurinta dusheeda.",
      "step_4": "Ka dul uruuri saxaro ka hor inta warqadda aysan quusin muunada saxaradane aysan taaban biyo",
      "step_5": "Ku xoq dusha sare ee saxarada adigoo isticmaalaya qalabka shaybaarka muunada.",
      "step_6": "Ku dabool qaybta jeexan ee qalabka shaybaarka gebi ahaanba muunada saxarada.",
      "step_7": "Xir dhalada muunada adiga oo gelinaya muunad qalabka shaybaarka leh oo dabool cagaaran si adag u saaraya. Dib ha u furin",
      "step_8": "Biyo raaci Waraaqaha uruurintu waa kuwa si sahlan loo baabi'in karo oo aan waxyeello u geysanayn nidaamka haanta wasakhda.",
      "step_9": "Ku duub dhalada shaybaarka suufka nuugista oo geli bacda khatarta noolaha."
    },
    "contact_support": {
      "title": "Aan hagaajino tan",
      "text_1": "Waan ka xunnahay inaad maqalno in wax khaldan yihiin!",
      "text_2": "Fadlan nala soo xiriir si aad noogu sheegto waxa khaldan waana kaa caawinaynaa inaan kuu bedelno xirmadaada."
    },
    "contact_support_success": {
      "title": "Waan helnay fariintaada",
      "text_1": "Waad ku mahadsan tahay inaad nala soo xiriirtay.",
      "text_2": "Xubin ka mid ah kooxdeena taageerada ayaa si dhakhsa ah kuula soo xiriiri doono.",
      "button_text": "Ku noqo bogga ugu weyn"
    },
    "kit_in_transit_to_patient": "Dalabkaagii wuu soo socdaa! Dalaabka badankood waxaa lagu keenaa 2-5 maalmood gudahood oo shaqo. Marka aad hesho xirmadaada, waxaan kuu soo diri doonaa macluumaad dheeraad ah oo ku saabsan sida loo howlgeliyo oo aad dib ugu soo celinayso.\n\nLifaaqa Raadraaca: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "Shaybaadhka wuu helay xirmadaada! Waxaad heli doontaa ogeysiis si aad u fiiriso marinkaaga bukaanka marka natiijada diyaar noqoto",
    "registration_confirmation": "Diiwaangelintaada hadda way dhammaatay! Booqo bogga xaqiijinta isdiiwaangelintaada si aad u howlgeliso xirmadaada baaritaanka:",
    "kit_ordered_online": "Waan helnay dalabkaaga xirmada waxaanan kuu soo diri doonaa wixi soo kordha marka la soo diro! Dalabka #: {{order_number}} Taariikhda dalbashada: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "Waan helnay dalabkaagii!",
      "p1": "Waxaan helnay dalabkaagii! Marka dalabkaaga la soo diro, waxaan kuu soo diri doonaa war bixin kale.",
      "order_number": "Dalabka #",
      "order_date": "Taariikhda dalabka"
    },
    "completed": "Waala dhamaystiray",
    "I": "xirmadaan waxaa ku jiro baaritaanada HVI.",
    "CC": "xirmadaan waxaa ku jiro baaritaanada kansarka mindhicirka waaweyn.",
    "CE": "xirmadaan waxaa ku jiro baaritaanada xanuunka midhicirka yaryar.",
    "CR": "xirmadaan waxaa ku jiro baaritaanada kiritiinka.",
    "DD": "xirmadaan waxaa ku jiro baaritaanada sokorta.",
    "H": "xirmadaan waxaa ku jiro baaritaanada HPV.",
    "P": "xirmadaan waxaa ku jiro baaritaanada cagaarshowga C.",
    "HH": "xirmadaan waxaa ku jiro baaritaanada hemoglobin.",
    "HS": "xirmadaan waxaa ku jiro baaritaanada HSV-2.",
    "TT": "xirmadaan waxaa ku jiro baaritaanada hormoonka dareenka ragga.",
    "VD": "xirmadaan waxaa ku jiro baaritaanada fitamiin D."
  },
  "copy_link": "Nuqul lifaaqa bogga",
  "copied_link": "Lifaaqa la nuqlay!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Ka difaaca cabuuqyada hergabka caadiga ah",
      "vaccine_covid": "Ka difaaca cabuuqyada COVID-19",
      "tdap": "Tallaalka Tdap wuxuu ka hortagi karaa teetanada",
      "polio": "Difaaca fayraska dabaysha. Waxaa looga baahan yahay carruurta ka hor intaysan bilaabin dugsiga.",
      "varicella": "Tallaalka difaaca busbuska",
      "shingles": "Tallaalka difaaca shingolis. Da'da ugu yar waa 18.",
      "human_papillomavirus": "Tallaalka difaaca HPV. Waxaa lagula taliyay caruurta 11 jirka ah.",
      "meningococcal": "Tallaalka wuxuu ka ilaaliyaa afar nooc oo bakteeriyada meningococcal ah."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Hel rug caafimaad",
    "select_pin": "Dooro biin si aad u aragto faahfaahinta",
    "miles_shorten": "{{miles}} mi",
    "next_available": "Tan xigto ee la heli karo",
    "appointment_type": "Nooca balanta",
    "individual_appointment": "Ballan shaqsiyeed",
    "family_appointment": "Ballan qoys",
    "vaccines": "Talaalada",
    "what_kind_appointment": "Ballan noocee ah ayaad rabtaa inaad qabsatid?",
    "select_family_or_individual": "Dooro ballanta qoyska haddii aad u qorsheynayso xubno badan oo qoyska ka mid ah hal mar.",
    "vaccines_selected": "Talaalada la xushay",
    "where_care": "Halkee rabtaa in aad daryeel ka hesho?",
    "select_vaccines_for_individual": "U xullo tallaalo balantaada (ikhtiyaari).",
    "select_vaccines_for_family": "U xullo tallaalo balanta qoyskaaga (ikhtiyaari).",
    "schedule_services": "Adeegyada jadwalka",
    "add_family": "Add family members and select services below.",
    "family_member": "Xubinta qoyska {{number}}",
    "morning": "Galinka hore",
    "afternoon": "Galinka dambe",
    "slot_available": "{{number}} ayaa la heli karaa",
    "within_x_miles": "%{miles mayl gudahood oo",
    "any_distance": "Masaafo kasta oo u jirta",
    "partial_results": "Qayb natiijooyinka ah",
    "partial_matches": "Rugagta caafimaadka soo socda waxay bixiyaan qaar laakiin maaha dhammaan tallaalada aad raadinayso",
    "no_matches": "Raadintaas ma helin wax u dhigma. Isku day inaad beddesho shaandhayntaada si aad u hesho natiijooyin ka wanaagsan.",
    "no_clinics_found": "Ma jiraan rugag caafimaad oo u dhigma raadintaada.",
    "broaden_filters": "Isku day inaad balaariso shaandhayntaada si aad u hesho natiijooyin ka wanaagsan.",
    "unavailable_vaccines": "Tallaalada aan la heli karin:",
    "available_vaccines": "Tallaalada la heli karo:",
    "select_date": "Dooro taariikh",
    "available_appointments": "BALAMAHA LA HELI KARO",
    "appointment_scheduling_info": "Waxaad awoodi doontaa inaad wakhtiyada ballanta u qabato xubno qoyska oo gaar ah tallaabada xigta. Waxaad ballan qabsan kartaa maalmo kala duwan haddii aad doorato.",
    "hold_selected_appointments": "Waxaan kuu qaban doonaa balamahaadi aad xullatay ilaa 15 daqiiqo.",
    "appointments_selected": "BALAMAHA LA XUSHAY",
    "no_appointments_selected": "Ma jirto balan la xushay",
    "vaccines_needed": "Talaalada loo baahan yahay",
    "select_x_appointments": "Xullo {{number}} balamo.",
    "more": "More",
    "less": "Less",
    "register_next_family_member": "Register next family member",
    "successfully_registered_x_of_y_family_members": "You have successfully registered {{x}} of {{y}} family members",
    "next_family_member": "Next family member",
    "appointments_abbreviated": "appts",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "Aan xaqiijino iimaylkaaga!",
    "hi_full_name": "Salaam {{full_name}},",
    "verify_email_button": "Xaqiiji iimaylka",
    "please_verify_email": "Fadlan isticmaal batoonka hoose si aad u xaqiijiso iimaylkaaga."
  },
  "services": {
    "dptap_dt": "Gawracatada, Teetanada, Xiiq-dheerta/Teetanada, Gawracatada, iyo Xiiq-dheerta/Teetanada iyo Gawracatada",
    "hepatitis_a": "Cagaarshowga A",
    "hepatitis_b": "Cagaarshowga B",
    "hepatitis_a_b": "Cagaarshowga A iyo B",
    "hib": "Hib",
    "mmr": "Jadeecada, Qaamo-qashiirta, Rubeela",
    "meningococcal": "Qoorgooyada",
    "mpox": "Furuqa",
    "pneumococcal": "Pneumococcal",
    "polio": "Dabaysha",
    "rsv": "Cabuuqa unugyada neefta",
    "rotovirus": "Rotavirus",
    "zoster": "Zoster (Shingolis)",
    "tetanus_diptheria": "Teetanada iyo gawracatada",
    "tdap": "Teetanada, Gawracatada, iyo Xiiq-dheerta",
    "typhoid": "Tiifowga",
    "varicella": "Busbuska (Busbuska)",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (Dadka waaweyn)",
    "covid-19_adult_description": "Dadka 12 jirka ah ama ka weyn.",
    "covid-19_children": "COVID-19 (Caruurta)",
    "covid-19_children_description": "Caruurta da'doodu tahay 4 - 11 sano jir.",
    "covid-19_infants": "COVID-19 (dhalaanka)",
    "covid-19_infants_description": "Caruurta 6 bilood  - 3 sano jirto.",
    "influenza": "Hergabka",
    "mmrv": "Measles, Mumps, Rubella and Varicella"
  },
  "deep_archived": {
    "admin": "For data security purposes, the data in the group {{test_group_name}} ({{slug}}) is archived and is no longer viewable or changeable from this page. Please reach out to your account manager or support@primary.health if you need assistance.",
    "participant": "This page is no longer available. Please reach out to your contacts from the health campaign: {{test_group_name}}. If you are having trouble viewing an old record, please contact support@primary.health for assistance."
  }
}