import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card } from 'react-bootstrap';
import { vaccineCode, manufacturerCode } from '../../common/utils/vaccine';
import CovidWarnings from './CovidWarnings';
import IISReferences from './IisReferences';
import ImmunizationTable from './ImmunizationTableV2';
import ImmunizationCheck from './ImmunizationCheckV2';
import CheckoutSection from './CheckoutSection';

export const requestHistoryUpdate = ({testGroupId, userId}) => {
  const url = `/test_groups/${testGroupId}/participants/${userId}/vaccine_history_updates`;
  return axios.post(url);
};

const lastCheckedLabel = reference => {
  return (reference
    ? `${format(new Date(reference.last_checked_at), 'yyyy-MMM-dd')} (${reference.source.toUpperCase()})`
    : "Never"
  );
};

const uniqueKey = ({ vaccinated_on, vaccine, manufacturer }) => {
  return [
    vaccinated_on,
    vaccineCode(vaccine),
    manufacturerCode(manufacturer),
  ].join('|');
};

export const deduplicateHistories = (histories) => {
  const missing_manufacturers = histories.filter(history => history.manufacturer === null);
  const deduplicated = histories.reduce((uniqueHistories, history) => {
    const key = uniqueKey(history);
    if (uniqueHistories[key]) {
      const uniqueHistory = uniqueHistories[key];
      if (uniqueHistory.source == 'primary') {
        uniqueHistories[key] = history; // Give preference to non-Primary record
      }
      if (uniqueHistories[key].source && !uniqueHistories[key].source.includes(history.source)) {
        uniqueHistories[key].source += `, ${history.source}`;
      }
    } else {
      const missing_manufacturer = missing_manufacturers.find(missing => key.includes(uniqueKey(missing)) && key != uniqueKey(missing));

      if (missing_manufacturer && !missing_manufacturer.source.includes(history.source)) {
        uniqueHistories[key] = missing_manufacturer;
        uniqueHistories[key].source += `, ${history.source}`;
        var matchWarning = `Warning: Reduced confidence with ${history.source} immunization match. IIS missing manufacturer.`;
        if (uniqueHistories[key].notes) {
          uniqueHistories[key].notes += matchWarning;
        } else {
          uniqueHistories[key].notes = matchWarning;
        }
      } else if (!missing_manufacturer) {
        uniqueHistories[key] = history;
      }
    }
    return uniqueHistories;
  }, {});

  return Object.values(deduplicated);
};

const ImmunizationHistory = (props) => {
  const {
    testGroupId,
    user,
    tests,
    isHipaaTrained = false,
    histories: propHistories,
    iisReferences: propIISReferences,
  } = props;

  const updateImmunizations = ({ vaccineHistories, iisReferences }) => {
    setHistories(vaccineHistories);
    setIISReferences(iisReferences);
  };

  const [histories, setHistories] = useState(propHistories);
  const [iisReferences, setIISReferences] = useState(propIISReferences);
  const deduplicatedHistories = deduplicateHistories(histories);
  const [hasWarning, setHasWarning] = useState(false);
  const [activeTab, setActiveTab] = useState('recent');

  const latestReference = new IISReferences(iisReferences).mostRecent();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const checkNow = () => {
    setError(false);
    setLoading(true);
    requestHistoryUpdate({testGroupId, userId: user.id}).
      then(response => {
        onCheck({
          vaccineHistories: response.data.vaccine_histories,
          iisReferences: response.data.iis_references,
        });
      }).
      catch(error => { setError(true); }).
      then(() => { setLoading(false); });
  }

  useEffect(() => {
    checkNow(); // check when loading
  }, []);

  return (
    <CheckoutSection hidePending={true} title={"Vaccine history"}>
      <div>
        <p>List of vaccines administered at Primary.Health</p>
        <ImmunizationCheck
          checkNow={checkNow}
          error={error}
          iisReferences={iisReferences}
          latestReference={latestReference}
          loading={loading}
          onCheck={updateImmunizations}
          setError={setError}
          setLoading={setLoading}
          testGroupId={testGroupId}
          user={user}
        />

        {!!deduplicatedHistories?.length && (
          <>
            <div className="d-flex justify-content-between my-3">
              <div className="d-flex">
                <div
                  className="px-3 py-2"
                  onClick={() => setActiveTab('recent')}
                  style={{
                    cursor: 'pointer',
                    borderBottom: activeTab === 'recent' ? '1px solid black' : 'none',
                    fontWeight: activeTab === 'recent' ? 'bold' : 'normal',
                  }}
                >
                  Recent
                </div>
                <div
                  className="px-3 py-2"
                  onClick={() => setActiveTab('history')}
                  style={{
                    cursor: 'pointer',
                    borderBottom: activeTab === 'history' ? '1px solid black' : 'none',
                    fontWeight: activeTab === 'history' ? 'bold' : 'normal',
                  }}
                >
                  History
                </div>
              </div>
              <div className="d-flex my-auto">
                  <div className="text-muted my-auto me-2">
                    Updated: {lastCheckedLabel(latestReference)}
                  </div>
                  <div>
                    <Button
                      onClick={checkNow}
                      variant="link"
                    >
                      Check for updates
                    </Button>
                  </div>
              </div>
            </div>

            <div className="my-3">
              {activeTab === 'recent' && (
                <CovidWarnings
                  histories={deduplicatedHistories}
                  tests={tests}
                  iisReferences={iisReferences}
                  setHasWarning={setHasWarning}
                  className="my-3"
                />
              )}
              {activeTab === 'history' && (
                isHipaaTrained ? (
                  <ImmunizationTable histories={deduplicatedHistories} />
                ) : (
                  <Card body bg="warning">
                    You do not have access to the patient’s full history. Please
                    contact your site lead for detailed immunization history.
                  </Card>
                )
              )}
            </div>
          </>
        )}
      </div>
    </CheckoutSection>
  );
};

export default ImmunizationHistory;
